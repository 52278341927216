<gridster [options]="gridsterConfig">
  @for (dashboardWidget of widgets; track dashboardWidget) {
    <gridster-item [item]="dashboardWidget | dashboardWidgetToGridsterItem" (itemChange)="itemChange.emit($event)">
      <lib-dashboard-chart
        [dashboardWidget]="dashboardWidget"
        [isEditing]="isEditing"
        (remove)="remove.emit(dashboardWidget)"
        (edit)="edit.emit(dashboardWidget)"
      ></lib-dashboard-chart>
    </gridster-item>
  }
</gridster>
