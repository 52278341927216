import { Entity } from '../entities';
import { PaginationFilter } from '../pagination';
import { DashboardWidget } from './widgets';

export enum DashboardStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface Dashboard extends Entity {
  status?: DashboardStatus;
  visibility?: DashboardPublicVisibility;
  widgets: DashboardWidget[];
}

export interface DashboardTemplate {
  id: string | null;
}

export interface DashboardRenameRequest {
  name: string;
}

export interface DashboardUpdatePublicVisibilityRequest {
  visibility: DashboardPublicVisibility;
}

export interface DashboardCopyRequest {
  name: string;
}

export enum DashboardPublicVisibility {
  PUBLIC = 'public',
  ORGANIZATION = 'organization',
  RESTRICTED = 'restricted',
}

export interface DashboardsFilteringOptions extends PaginationFilter {
  filters: {
    search_term?: string;
    owner?: string;
    organizational_visibility?: DashboardPublicVisibility;
    status?: DashboardStatus;
    v2?: boolean;
  };
}

export interface DashboardUpsertRequest {
  name: string;
  v2?: boolean;
  core_dashboard_id?: string;
}
