import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, Doc, SearchOptions } from '../../../../models';
import { ApiService } from '../../../common/api/api.service';
import { Observable } from 'rxjs';
import { BaseService } from '../../../common/base/base.service';
import { BaseClientDataRequestsDocumentsService } from './base-client-data-requests-documents.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicDataRequestsDocumentsService extends BaseClientDataRequestsDocumentsService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  isPublic: boolean = true;

  constructor(
    private apiService: ApiService,
    readonly baseService: BaseService,
  ) {
    super(baseService);

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequests;
  }

  public searchDataRequestDocuments(
    dataRequestId: string,
    searchOptions: SearchOptions,
  ): Observable<ApiResponse<Doc[]>> {
    const payload = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/documents`,
      payload,
    );
  }
}
