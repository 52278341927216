import * as Highcharts from 'highcharts';

export enum ChartAlignValue {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum ChartAxisAlignValue {
  high = 'high',
  low = 'low',
  middle = 'middle',
}

export enum ChartColor {
  green = '#00FF00',
  grey = '#DDDDDD',
  orange = '#F79009',
  primary = '#004FAC',
  red = '#FF0000',
  subtitle = '#101828',
  title = '#475467',
  white = '#FFFFFF',
}

export enum ChartFontSizeValue {
  common = '1.4rem',
  small = '1.2rem',
}

export enum ChartFontWeightValue {
  common = '400',
}

export enum ChartLineHeightValue {
  common = '1.6rem',
}

export enum ChartSymbol {
  circle = 'symbol',
}

export enum ChartType {
  column = 'column',
  scatter = 'scatter',
  spline = 'spline',
  pie = 'pie',
  line = 'line',
  bar = 'bar',
}

export enum ChartColumnPlotOptionStackingType {
  normal = 'normal',
  percent = 'percent',
}

export interface ChartColumnPlotOptions {
  stacking?: ChartColumnPlotOptionStackingType;
  dataLabels?: {
    enabled?: boolean;
    style?: ChartStyleConfig;
  };
}

interface ChartDataLabelsConfig {
  enabled?: boolean;
  distance?: number;
  format?: string;
  style?: {
    fontSize?: string;
  };
}

interface ChartPlotConfig {
  series?: {
    allowPointSelect?: boolean;
    borderRadius?: string | number;
    cursor?: string;
    pointStart?: number;
    pointWidth?: number;
    events?: {
      legendItemClick?: (event: Highcharts.SeriesLegendItemClickEventObject) => void;
    };
    dataLabels?: ChartDataLabelsConfig[];
  };
  column?: ChartColumnPlotOptions;
  [ChartType.scatter]?: {
    marker: {
      radius: number;
      symbol: ChartSymbol;
    } & ChartMarkerConfig;
  };
  spline?: {
    connectNulls?: boolean;
  } & ChartMarkerConfig;
}

interface ChartStyleConfig {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  transform?: string;
  whiteSpace?: string;
  textOverflow?: string;
  overflow?: string;
}

export interface ChartAxisConfig {
  tickInterval?: number;
  categories?: string[];
  labels?: { style?: ChartStyleConfig };
  title?: ChartTitleConfig<ChartAxisAlignValue>;
  stackLabels?: {
    enabled?: boolean;
    style?: ChartStyleConfig;
  };
}

interface ChartMarkerConfig {
  lineWidth?: number;
  lineColor?: string;
  fillColor?: string;
}

export interface ChartTitleConfig<T = ChartAlignValue> {
  align?: T;
  text?: string;
  style?: ChartStyleConfig;
  useHTML?: boolean;
}

export type ChartSubtitleConfig = ChartTitleConfig;

export interface ChartTooltipConfig {
  format?: string;
  pointFormat?: string;
  valueSuffix?: string;
  style?: ChartStyleConfig;
}

interface CommonChartConfig<T extends ChartType> {
  type: T;
  name?: string;
  tooltip?: ChartTooltipConfig;
}

export interface SimpleChartConfig<T extends ChartType> extends CommonChartConfig<T> {
  id?: string;
  data: (null | number | number[] | { name: string; y: number })[];
  showInLegend?: boolean;
}

export type BarChartConfig = SimpleChartConfig<ChartType.bar>;

export type LineChartConfig = SimpleChartConfig<ChartType.line>;

export interface PieChartConfig extends SimpleChartConfig<ChartType.pie> {
  size?: string;
  innerSize?: string;
}

export type ColumnChartConfig = SimpleChartConfig<ChartType.column>;

export type ScatterChartConfig = SimpleChartConfig<ChartType.scatter>;

interface ChartLegendConfig {
  itemStyle?: ChartStyleConfig;
  enabled?: boolean;
}

export interface StackedColumnChartConfig extends SimpleChartConfig<ChartType.column> {
  stack: string;
  color: Highcharts.PatternObject;
}

export interface SplineChartConfig extends SimpleChartConfig<ChartType.spline> {
  marker?: ChartMarkerConfig;
}

interface ChartNavigationButtonOptionsConfig {
  enabled: boolean;
}

interface ChartNavigationConfig {
  buttonOptions: ChartNavigationButtonOptionsConfig;
}

interface ContextButton {
  menuItems: Array<string>;
}

interface ChartButton {
  contextButton: ContextButton;
}

interface ChartButton {
  contextButton: ContextButton;
}

interface ChartGeneralConfig {
  marginLeft?: number;
}

interface ChartExportOption {
  url?: string;
  sourceWidth?: number;
  sourceHeight?: number;
  chartOptions?: ChartConfig;
  buttons?: ChartButton;
  enabled?: boolean;
}

type ChartConfigs =
  | ColumnChartConfig
  | ScatterChartConfig
  | SplineChartConfig
  | StackedColumnChartConfig
  | PieChartConfig
  | LineChartConfig
  | BarChartConfig;

export interface ChartConfig<T extends ChartConfigs = ChartConfigs> {
  colors?: Array<string>;
  series?: Array<T>;
  tooltip?: ChartTooltipConfig;
  title?: ChartTitleConfig;
  subtitle?: ChartSubtitleConfig;
  xAxis?: ChartAxisConfig;
  yAxis?: ChartAxisConfig;
  plotOptions?: ChartPlotConfig;
  navigation?: ChartNavigationConfig;
  legend?: ChartLegendConfig;
  exporting?: ChartExportOption;
  chart?: ChartGeneralConfig;
}

export const COMPANY_COLOURS: Array<string> = [
  '#004FAC',
  '#1C98F6',
  '#02BEF7',
  '#5EDDF7',
  '#98EFF4',
  '#CEE0DF',
  '#DAE6E7',
  '#A7CACE',
  '#9AB1B2',
  '#529A9F',
];

export const DEFAULT_COLOURS: Array<string> = [
  '#2caffe',
  '#544fc5',
  '#00e272',
  '#fe6a35',
  '#6b8abc',
  '#d568fb',
  '#2ee0ca',
  '#fa4b42',
  '#feb56a',
  '#91e8e1',
];

export const BAR_WIDTH: number = 75;

export const BAR_TOP_BORDER_RADIUS: string = '5px';

export const MARKER_LINE_COLOR: string = 'orange';

export const MARKER_FILL_COLOR: string = 'white';

export const MARKER_RADIUS: number = 5;

export const TOOLTIP_FONT_SIZE: string = '12px';

export const TITLE_FONT_SIZE: string = '14px';

export const SUBTITLE_FONT_SIZE: string = '10px';

export const LINE_WIDTH: number = 2;

export const MARKER_LINE_WIDTH: number = 4;

export const DEFAULT_CHART_CONFIG: ChartConfig = {
  colors: [ChartColor.primary, ChartColor.grey, ChartColor.orange],
  tooltip: {
    style: {
      fontSize: TOOLTIP_FONT_SIZE,
    },
  },
  plotOptions: {
    [ChartType.scatter]: {
      marker: {
        radius: MARKER_RADIUS,
        symbol: ChartSymbol.circle,
        fillColor: MARKER_FILL_COLOR,
        lineColor: MARKER_LINE_COLOR,
        lineWidth: MARKER_LINE_WIDTH,
      },
    },
    series: {
      pointWidth: BAR_WIDTH,
      borderRadius: BAR_TOP_BORDER_RADIUS,
    },
    spline: { connectNulls: true, lineWidth: LINE_WIDTH },
  },
  exporting: {
    enabled: true,
    chartOptions: {
      title: {
        style: {
          fontSize: TITLE_FONT_SIZE,
          lineHeight: '4px',
          transform: 'translate(0, 0px)',
        },
      },
      subtitle: {
        style: {
          fontSize: SUBTITLE_FONT_SIZE,
          transform: 'translate(0, 0px)',
        },
      },
    },
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
      },
    },
  },
};

export const DONUT_CHART_COLOURS = ['#22C55E', '#58A6FB', '#667085'];

export const DEFAULT_DONUT_CHART_CONFIG: Highcharts.Options = {
  accessibility: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'pie',
    animation: true,
  },
  title: {
    text: 'Title',
    align: 'center',
    verticalAlign: 'middle',
    style: { fontSize: '45px', fontWeight: '600', fontFamily: 'Poppins' },
    y: 2,
  },
  subtitle: {
    style: { fontSize: '14px', fontWeight: '400', textAlign: 'center', fontFamily: 'Poppins' },
    text: 'Subtitle',
    align: 'center',
    verticalAlign: 'middle',
  },
  tooltip: {
    animation: true,
    pointFormat: '{point.name}: {point.percentage:.1f}%',
    enabled: true,
    style: {
      fontSize: '12px',
      fontWeight: '400',
      textAlign: 'center',
      fontFamily: 'Poppins',
    },
    headerFormat: '',
    outside: true,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      accessibility: { enabled: false },
      allowPointSelect: false,
      dataLabels: {
        enabled: false,
      },
      startAngle: 180,
      center: ['50%', '50%'],
      size: '100%',
      showInLegend: true,
      innerSize: '80%',
      animation: true,
      enableMouseTracking: true,
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
    },
  },
  legend: {
    enabled: false,
  },
  colors: DONUT_CHART_COLOURS,
  series: [
    {
      type: 'pie',
      innerSize: '80%',
    },
  ],
};
