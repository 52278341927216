<div class="empty-grid">
  <div class="empty-grid-text">
    @if (emptyMessageTitle || emptyMessageDescription) {
      <h4>{{ emptyMessageTitle }}</h4>
      <p>{{ emptyMessageDescription }}</p>
    } @else {
      <h4>{{ "No items to display" | translate }}</h4>
      <p>{{ "Please adjust the filters or create a new item" | translate }}</p>
    }
  </div>
  @if (actionLabel && actionClicked) {
    <button lib-button main-actions buttonType="primary_v2" (click)="onActionClick()">
      {{ actionLabel }}
    </button>
  }
</div>
