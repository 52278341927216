import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroupSetStatusTransition } from '../../models';

@Pipe({
  name: 'castAsValueGroupSetStatusTransition',
})
export class CastAsValueGroupSetStatusTransitionPipe implements PipeTransform {
  // This is not tested. Typing doesn't exist in runtime, only in compile time, so it can't be tested.
  // This pipe was created to fix a typing issue in compile time using a variable coming from a template context.
  // Use example in the template: <app-child [data]="someObject | castAsValueGroupSetStatusTransition"></app-child>
  transform(value: unknown): ValueGroupSetStatusTransition {
    return value as ValueGroupSetStatusTransition;
  }
}
