import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SortingTranslation } from '../sorting.translation';
import { DataRequestValueGroupSetStatus, MetricCategory, PlatformValueGroupSetStatus } from '../../models';

export {
  MetricsIndicatorCategories,
  MetricsIndicatorFrequencies,
  MetricsIndicatorSources,
  MetricsIndicatorSort,
  MetricsIndicatorStatus,
  MetricsIndicatorTargetStatus,
  MetricsMetricCategories,
  MetricsMetricSort,
  PartialMetricsMetricSort,
  PartialMetricValueDefinitionSort,
  VGSetStatusContextDisplayText,
  ValueGroupSetStatusToText,
};

const MetricsIndicatorCategories = {
  [MetricCategory.CUSTOM]: _('Custom'),
  [MetricCategory.REFERENCE]: _('Reference'),
  [MetricCategory.ARCHIVED]: _('Archived'),
  [MetricCategory.THIRD_PARTY]: _('Third Party'),
  [MetricCategory.ACTIVATED]: _('Activated'),
  [MetricCategory.DEACTIVATED]: _('Deactivated'),
};

const MetricsIndicatorFrequencies = {
  daily: _('Daily'),
  weekly: _('Weekly'),
  monthly: _('Monthly'),
  quarterly: _('Quarterly'),
  yearly: _('Yearly'),
  lifetime: _('Lifetime'),
};

const MetricsIndicatorSources = {
  global: _('Single source'),
  source: _('Multiple sources'),
};

const MetricsIndicatorStatus = {
  pending: _('Pending'),
  approved: _('Approved'),
};

const MetricsIndicatorSort = {
  score: SortingTranslation.best_match,
  start: SortingTranslation.last_updated,
  description: SortingTranslation.alphabetical,
};

const MetricsIndicatorTargetStatus = {
  null: _(''),
  on_schedule: _('On schedule'),
  target_met: _('Target met'),
  behind_schedule: _('Behind schedule'),
};

const MetricsMetricCategories = {
  [MetricCategory.CUSTOM]: _('Custom'),
  [MetricCategory.REFERENCE]: _('Reference'),
};

const MetricsMetricSort = {
  score: SortingTranslation.best_match,
  start: SortingTranslation.last_updated,
  description: SortingTranslation.alphabetical,
};

const PartialMetricsMetricSort = {
  start: SortingTranslation.last_updated,
  description: SortingTranslation.alphabetical,
};

const PartialMetricValueDefinitionSort = {
  position: SortingTranslation.position,
  description: SortingTranslation.alphabetical,
};

const VGSetStatusContextDisplayText = {
  entered_via_collect: _('Metric values entered manually'),
  completed_via_collect: _('Metric values completed manually'),
  imported_via_request: _('Metric values approved through a request'),
  request_values_overridden: _('Metric values modified after approval'),
  imported_via_survey: _('Metric completed and imported from a survey'),
  imported_from_green_project: _('Marked as in progress via API'),
  some_values_entered_via_green_project_api: _('Some values entered via carbon management integration'),
  imported_from_minimum: _('Some values entered via carbon management integration'),
  some_values_entered_via_minimum_api: _('Some values entered via carbon management integration'),
  reopened_from_consolidation: _('Marked as in progress due to change in value or configuration rule'),
  user_action: _('User action'),
  data_entered_by_user: _('Data entered by user'),
  new_calculated_field_added: _('New calculated field added'),
  new_source_added: _('New source added'),
  calculated_field_modified: _('Calculated field modified'),
  consolidation_configuration_rules_modified: _('Consolidation configuration rules modified'),
  approved_in_request_and_imported: _('Approved in request and imported'),
  consolidated_value_modified: _('Consolidated value modified'),
  approved_consolidated_value_no_longer_applicable: _('Approved consolidated value no longer applicable'),
};

const ValueGroupSetStatusToText = {
  // collect metric
  [PlatformValueGroupSetStatus.NOT_STARTED]: _('Not started'),
  [PlatformValueGroupSetStatus.IN_PROGRESS]: _('In progress'),
  [PlatformValueGroupSetStatus.COMPLETE]: _('Complete'),

  // data request metric
  [DataRequestValueGroupSetStatus.APPROVED]: _('approved'),
  [DataRequestValueGroupSetStatus.COMPLETED]: _('completed'),
  [DataRequestValueGroupSetStatus.ACCEPTED]: _('accepted'),
  [DataRequestValueGroupSetStatus.REJECTED]: _('rejected'),
  [DataRequestValueGroupSetStatus.VALIDATE]: _('validate'),
  // [DataRequestValueGroupSetStatus.NOT_STARTED]: _('not started'), // breaks with typescript 5.4 with duplicate key
  // [DataRequestValueGroupSetStatus.IN_PROGRESS]: _('in progress'), // breaks with typescript 5.4 with duplicate key
};
