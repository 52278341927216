import { Pipe, PipeTransform } from '@angular/core';
import { DataRequestNoteAggregate } from '../../models';

@Pipe({
  name: 'castAsDataRequestNoteAggregate',
})
export class CastAsDataRequestNoteAggregatePipe implements PipeTransform {
  transform(value: unknown): DataRequestNoteAggregate {
    return value as DataRequestNoteAggregate;
  }
}
