import { Pipe, PipeTransform } from '@angular/core';
import { DashboardWidget, DashboardWidgetType, SINGLE_SIZED_WIDGETS } from '../../models';

export interface HasAvailableSpaceModel {
  dashboardWidgets: DashboardWidget[];
  dashboardWidgetType: DashboardWidgetType;
}

@Pipe({ name: 'dashboardHasAvailableSpace' })
export class DashboardHasAvailableSpacePipe implements PipeTransform {
  private rowLimit: number = 3;
  private columnLimit: number = 4;

  transform(hasAvailableSpaceModel: HasAvailableSpaceModel): boolean {
    const availabilityMatrix = this.getAvailabilityMatrix(hasAvailableSpaceModel.dashboardWidgets);

    if (SINGLE_SIZED_WIDGETS.includes(hasAvailableSpaceModel.dashboardWidgetType)) {
      return this.hasEnoughSpace(availabilityMatrix, 1, 1);
    }

    return this.hasEnoughSpace(availabilityMatrix, 2, 1);
  }

  private getAvailabilityMatrix(dashboardWidgets: DashboardWidget[]): boolean[][] {
    // True meaning that the space is used.
    const availabilityMatrix3By4: boolean[][] = [
      [false, false, false, false],
      [false, false, false, false],
      [false, false, false, false],
    ];

    dashboardWidgets.forEach((dashboardWidget: DashboardWidget) => {
      this.range(dashboardWidget.width).forEach((widthIndex: number) => {
        this.range(dashboardWidget.height).forEach((heightIndex: number) => {
          availabilityMatrix3By4[dashboardWidget.row_location + heightIndex][
            dashboardWidget.column_location + widthIndex
          ] = true;
        });
      });
    });

    return availabilityMatrix3By4;
  }

  private hasEnoughSpace(grid: boolean[][], widgetWidth: number, widgetHeight: number): boolean {
    if (widgetWidth > this.rowLimit || widgetHeight > this.columnLimit) {
      // The element is larger than the grid itself, so there's no space.
      return false;
    }

    for (let row = 0; row <= this.rowLimit - widgetHeight; row++) {
      for (let col = 0; col <= this.columnLimit - widgetWidth; col++) {
        // Check if the current position (row, col) and the subsequent cells
        // are all empty in the grid for the element to fit.
        let canPlaceElement = true;
        for (let i: number = 0; i < widgetHeight; i++) {
          for (let j: number = 0; j < widgetWidth; j++) {
            if (grid[row + i][col + j]) {
              canPlaceElement = false;
              break;
            }
          }
          if (!canPlaceElement) {
            break;
          }
        }

        if (canPlaceElement) {
          return true;
        }
      }
    }

    return false;
  }

  private range(size: number): number[] {
    return [...Array(size).keys()];
  }
}
