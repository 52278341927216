import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastMessage, ToastPosition, ToastStatus } from '../../models';
import { TranslateService } from '../../services/common';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly TOAST_DURATION = 5000;

  constructor(
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService,
  ) {}

  open(
    status: ToastStatus = ToastStatus.INFO,
    message?: string,
    actionLabel?: string,
    routerLink?: string,
    callbackAction?: () => void,
    title?: string,
    position?: ToastPosition,
  ): void {
    if (!position) {
      position = ToastPosition.TOP_RIGHT;
    }

    if (!title) {
      switch (status) {
        case ToastStatus.ERROR:
          title = this.translateService.instant('Error');
          break;

        case ToastStatus.SUCCESS:
          title = this.translateService.instant('Success');
          break;

        case ToastStatus.WARNING:
          title = this.translateService.instant('Warning');
          break;

        case ToastStatus.INFO:
        default:
          title = this.translateService.instant('Information');
      }
    }

    this.messageService.add({
      key: position,
      severity: status,
      summary: title,
      detail: message,
      life: this.TOAST_DURATION,
      data: {
        actionLabel,
        routerLink,
        callbackAction,
      },
    } as ToastMessage);
  }
}
