import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardDatum, UpsertDashboardDatumRequest } from '../../../models';
import { UniquenessValidator } from '../../../validators';
import { DashboardDatumsApiService } from '../../../services/api-services';
import { map } from 'rxjs';

interface UpsertDashboardDatumFormModel {
  label: FormControl<string>;
}

export class UpsertDashboardDatumForm extends FormGroup<UpsertDashboardDatumFormModel> {
  constructor(
    readonly dashboardDatumsApiService: DashboardDatumsApiService,
    dashboardDatum?: DashboardDatum,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      label: fb.nonNullable.control(dashboardDatum?.label ?? '', {
        validators: [Validators.required, Validators.maxLength(60)],
        asyncValidators: UniquenessValidator.validate(
          (value: string) =>
            dashboardDatumsApiService.checkDashboardDatumName(value.trim()).pipe(map((res) => res.data.available)),
          dashboardDatum ? [dashboardDatum.label] : [],
        ),
      }),
    });
  }

  public toModel(valueDefinitionId: string): UpsertDashboardDatumRequest {
    return { label: this.controls.label.value, value_definition_id: valueDefinitionId };
  }
}
