import { Message } from 'primeng/api';

export interface ToastMessageData {
  routerLink?: string;
  actionLabel?: string;
  callbackAction?: () => void;
}

export interface ToastMessage extends Message {
  data?: ToastMessageData;
}

export enum ToastStatus {
  SUCCESS = 'success',
  WARNING = 'warn',
  INFO = 'info',
  ERROR = 'error',
}

export enum ToastPosition {
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
}
