import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable, switchMap, take } from 'rxjs';

import {
  DocumentTypeDetails,
  FileDocumentInterface,
  FiscalYear,
  Metric,
  MetricCategory,
  Platforms,
  SOURCE_CONFIGURATION,
  ValueDefinition,
  ValueDefinitionTemplate,
} from '../models';

import { ValueDefinitionTemplateService } from './services/value-definition-template.service';
import { MetricStructureSelectable } from './models';
import { MetricStructureStateService } from './services/metric-structure-state.service';
import { BaseStandardDocumentsService } from '../documents';
import { BaseMetricStructureService } from './services/base-metric-structure/base-metric-structure.service';
import { DeactivateEntityService } from './services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from './services/activate-entity/activate-entity.service';
import { MetricStructureStore } from './metric-structure.store';
import { provideComponentStore } from '@ngrx/component-store';
import { FeatureFlagService } from '../feature-flag';

@Component({
  selector: 'lib-metric-structure',
  templateUrl: './metric-structure.component.html',
  styleUrls: ['./metric-structure.component.scss'],
  providers: [
    { provide: MetricStructureStateService },
    DeactivateEntityService,
    ActivateEntityService,
    provideComponentStore(MetricStructureStore),
  ],
})
export class MetricStructureComponent implements OnInit {
  @Input()
  set metric(metric: Metric | undefined) {
    if (metric) {
      this.metricStructureService.updateMetric(metric);
      this.metricStructureService.updateSelectedItem(metric);
      this.metricStructureStore.updateMetric(metric);
      this.metricStructureStore.updateFieldsVisibilityEffect();
    }
  }

  @Input() set canEditEveryMetrics(canEditEveryMetrics: boolean) {
    this.metricStructureService.setCanEditEveryMetrics(canEditEveryMetrics);
  }

  @Input() set canCreateCustomChoices(canCreateCustomChoices: boolean) {
    this.metricStructureService.setCanCreateCustomChoices(canCreateCustomChoices);
  }

  @Input() set hostPlatform(hostPlatform: Platforms) {
    this.metricStructureService.setHostPlatform(hostPlatform);
  }

  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input() regularFiscalYears: boolean = false;
  @Input() fiscalYears: FiscalYear[] = [];

  @Output() updatedMetric: EventEmitter<Metric | undefined> = new EventEmitter<Metric | undefined>();

  valueDefinitionTemplates: ValueDefinitionTemplate[] = [];
  metricData?: Metric;
  selectedItem?: MetricStructureSelectable;
  isAdmin: boolean = false;
  disableSpecialTypes: boolean = false;
  fieldVisibility$ = this.metricStructureStore.fieldsVisibility$;

  constructor(
    private baseStandardDocumentsService: BaseStandardDocumentsService,
    private metricStructureService: MetricStructureStateService,
    private valueDefinitionTemplateService: ValueDefinitionTemplateService,
    private baseMetricStructureService: BaseMetricStructureService,
    private metricStructureStore: MetricStructureStore,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.valueDefinitionTemplates = this.valueDefinitionTemplateService.getValueDefinitionTemplates();

    this.metricStructureService.metric$.subscribe((metric) => {
      this.metricData = metric;
      this.updateAllDocumentFields();
      this.updatedMetric.emit(this.metricData);
      this.disableSpecialTypes =
        (!this.metricStructureService.isAdmin && this.metricData?.category == MetricCategory.THIRD_PARTY) ||
        (!this.metricData?.active &&
          !this.metricStructureService.isAdmin &&
          this.featureFlagService.areAnyFeatureFlagsEnabled(['metric_deactivation_enabled']));
    });
    this.metricStructureService.selectedItem$.subscribe((selectedItem) => {
      this.selectedItem = selectedItem;
    });

    this.isAdmin = this.metricStructureService.isAdmin;
  }

  public onPanelClose(): void {
    this.metricStructureService.updateSelectedItem(undefined);
  }

  private updateAllDocumentFields(): void {
    this.metricStructureService.cachedDocumentList$
      .pipe(
        take(1),
        switchMap((cachedDocumentList) => this.getDocumentCalls(cachedDocumentList ?? [])),
      )
      .subscribe((documents) => this.metricStructureService.addDocumentsToTheList(documents));
  }

  private getDocumentCalls(cachedDocumentList: FileDocumentInterface[]): Observable<FileDocumentInterface[]> {
    const documentCalls: Observable<FileDocumentInterface>[] = [];
    this.metricData?.value_definition_groups?.forEach((grp) => {
      grp.value_definitions
        ?.filter((vd) => vd.type_details?.document_id)
        .filter((vd) => !cachedDocumentList.map((doc) => doc.id).includes(String(vd.type_details.document_id)))
        ?.forEach((valueDef: ValueDefinition<DocumentTypeDetails>) => {
          if (valueDef.type_details?.document_host_env === 'core') {
            documentCalls.push(
              this.baseMetricStructureService.getCoreDocumentMetadata(valueDef.type_details.document_id),
            );
          } else {
            documentCalls.push(
              this.baseStandardDocumentsService.getDocumentMetadata(String(valueDef.type_details?.document_id ?? '')),
            );
          }
        });
    });
    return forkJoin(documentCalls);
  }

  public deactivateMetric(): void {
    if (this.metricData) {
      this.deactivateEntityService.deactivateMetric(this.metricData.id);
    }
  }

  public activateMetric(): void {
    if (this.metricData) {
      this.activateEntityService.activateMetric(this.metricData.id);
    }
  }
}
