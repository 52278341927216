import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ValidationMessageService } from '../../../services/common';
import { ActionItem, ValueDefinitionSize } from '../../../models';

let nextId = 0;

@Component({
  selector: 'lib-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnChanges {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() labelControl?: UntypedFormControl;
  @Input() options: ActionItem[] = [];
  @Input() messages: ValidationErrors = {};
  @Input() hint: string = '';
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() disabledOptionsTooltip: string = '';
  @Input() showEmptyOption: boolean = true;
  @Input() ariaLabel: string = '';
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() fieldInfo?: string;
  @Input() disabled: boolean = false;
  @Input() dataTestId: string = '';

  @Output() selectionChangeEvent = new EventEmitter<string>();

  @ViewChild('focusElement') focusElement!: MatSelect;

  readonly _labelId = `select-input-${nextId++}`;

  required: boolean = false;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  onSelectionChange(value: string): void {
    this.selectionChangeEvent.emit(value);
  }

  setFocus(): void {
    this.focusElement.focus();
  }
}
