import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DashboardDatum, EmptyResults, TableColumn, TablePageEvent } from '../../../models';
import { combineLatestWith, map, Observable } from 'rxjs';
import { DashboardDatumsSidebarStore } from '../dashboard-datums-sidebar.store';
import { TranslateService } from '../../../services/common';
import { DashboardDatumsApiService } from '../../../services/api-services/dashboard-datums-api-service/dashboard-datums-api.service';

interface Data {
  isLoading: boolean;
  dashboardDatums: DashboardDatum[];
  total: number;
}

@Component({
  selector: 'lib-dashboard-datums',
  templateUrl: './dashboard-datums.component.html',
  styleUrls: ['./dashboard-datums.component.scss'],
})
export class DashboardDatumsComponent implements OnInit, AfterContentInit {
  @Output() editDashboardDatum = new EventEmitter<DashboardDatum>();
  @Output() renameDashboardDatum = new EventEmitter<DashboardDatum>();

  public readonly emptyResults: EmptyResults = { title: this.translateService.instant('No result found') };

  public data$?: Observable<Data>;
  public dashboardDatumColumns: TableColumn<DashboardDatum>[] = [];

  constructor(
    private readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dashboardDatumsSidebarStore: DashboardDatumsSidebarStore,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.data$ = this.dashboardDatumsSidebarStore.dashboardDatums$.pipe(
      combineLatestWith(this.dashboardDatumsSidebarStore.isLoading$, this.dashboardDatumsSidebarStore.total$),
      map(([dashboardDatums, isLoading, total]) => ({ dashboardDatums, isLoading, total })),
    );
  }

  public ngAfterContentInit(): void {
    this.dashboardDatumColumns = [{ name: this.translateService.instant('Data Points'), dataKey: 'label' }];
  }

  public onDeleteDashboardDatum = (dashboardDatum: DashboardDatum): void => {
    this.dashboardDatumsApiService.deleteDashboardDatum(dashboardDatum.id).subscribe({
      next: () => {
        this.dashboardDatumsSidebarStore.fetchDashboardDatums();
      },
    });
  };

  public onEditDashboardDatum = (dashboardDatum: DashboardDatum): void => {
    this.editDashboardDatum.emit(dashboardDatum);
  };

  public onRenameDashboardDatum = (dashboardDatum: DashboardDatum): void => {
    this.renameDashboardDatum.emit(dashboardDatum);
  };

  public onPageChange = (event: TablePageEvent): void => {
    this.dashboardDatumsSidebarStore.updatePaginationState({
      currentPage: event.currentPage,
      pageSize: event.pageSize,
    });
  };

  public onSearchChange(searchQuery: string): void {
    this.dashboardDatumsSidebarStore.updateSearchQueryState(searchQuery);
  }
}
