<lib-sidebar
  [zIndex]="900"
  [ctaBtn]="'Add more' | translate"
  [size]="'lg'"
  [title]="'Manage Data Points' | translate"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  (ctaClick)="upsertDatum()"
>
  <lib-dashboard-datums
    (editDashboardDatum)="upsertDatum($event)"
    (renameDashboardDatum)="renameDatum($event)"
  ></lib-dashboard-datums>
</lib-sidebar>
