import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, ApplicationApiDefinition, SearchOptions, SelectionSet, SelectionSetItem } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminSelectionSetService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.selection_sets;
  }

  public search(searchOptions?: SearchOptions): Observable<ApiResponse<SelectionSet[]>> {
    let params = new HttpParams().append('load_selection_set_items', false);
    if (searchOptions?.sort) {
      params = params.append('order_by', searchOptions.sort.id);
      if (searchOptions.sort.id === 'updated') {
        params = params.append('order_by_direction', 'desc');
      }
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/selection_sets`, { params }).pipe(
      map((result: ApiResponse<SelectionSet[]>) => {
        if (result.data.length) {
          // filter by keyword
          if (searchOptions?.query.keywords) {
            result.data = result.data.filter((item) =>
              item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()),
            );
            result.meta.count = result.data.length;
          }
        }
        return result;
      }),
    );
  }

  public searchItems(searchOptions: SearchOptions): Observable<ApiResponse<SelectionSetItem[]>> {
    let params = new HttpParams();
    if (searchOptions.sort) {
      params = params.append('order_by', searchOptions.sort.id);
    } else {
      params = params.append('order_by', 'position');
    }
    return this.apiService
      .get(
        `${this.servicePath}${this.resource}/selection_sets/${searchOptions.custom_filters?.id}/selection_set_items`,
        { params },
      )
      .pipe(
        map((result: ApiResponse<SelectionSetItem[]>) => {
          if (result.data.length) {
            // filter by keyword
            if (searchOptions.query.keywords) {
              result.data = result.data.filter((item) =>
                item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()),
              );
              result.meta.count = result.data.length;
            }
          }
          return result;
        }),
      );
  }

  public createSelectionSet(
    name: string,
    description: string,
    visibility: boolean,
  ): Observable<ApiResponse<SelectionSet>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/selection_sets`, {
      name,
      description,
      public: visibility,
    });
  }

  public createSelectionSetItem(selectionSetId: string, name: string): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items`,
      { name },
    );
  }

  public updateSelectionSetItem(
    selectionSetId: string,
    selectionSetItemId: string,
  ): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items/${selectionSetItemId}`,
      {
        public: true,
      },
    );
  }

  public updateSelectionSet(
    selectionSetId: string,
    name: string,
    description: string,
    visibility: boolean,
  ): Observable<ApiResponse<SelectionSet>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/selection_sets/${selectionSetId}`, {
      name,
      description,
      public: visibility,
    });
  }

  public getSelectionSet(selectionSetId: string): Observable<ApiResponse<SelectionSet>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/selection_sets/${selectionSetId}`);
  }

  public getSelectionSetItem(
    selectionSetId: string,
    selectionSetItemId: string,
  ): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items/${selectionSetItemId}`,
    );
  }

  public moveSelectionSetItem(
    selectionSetId: string,
    selectionSetItemId: string,
    position: number,
  ): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items/${selectionSetItemId}/move/${position}`,
      {},
    );
  }

  public deactivateSelectionSetItem(
    selectionSetId: string,
    selectionSetItemId: string,
  ): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items/${selectionSetItemId}/deactivate`,
      {},
    );
  }

  public activateSelectionSetItem(
    selectionSetId: string,
    selectionSetItemId: string,
  ): Observable<ApiResponse<SelectionSetItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items/${selectionSetItemId}/activate`,
      {},
    );
  }

  public deactivateSelectionSet(selectionSetId: string): Observable<ApiResponse<SelectionSet>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/deactivate`, {});
  }

  public activateSelectionSet(selectionSetId: string): Observable<ApiResponse<SelectionSet>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/activate`, {});
  }
}
