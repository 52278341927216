import { NgModule } from '@angular/core';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { EditorModule } from 'primeng/editor';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
import { TreeSelectModule } from 'primeng/treeselect';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { TreeTableModule } from 'primeng/treetable';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeModule } from 'primeng/tree';
import { DragDropModule } from 'primeng/dragdrop';
import { TabViewModule } from 'primeng/tabview';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CarouselModule } from 'primeng/carousel';
import { StepperModule } from 'primeng/stepper';
import { StepsModule } from 'primeng/steps';
import { SkeletonModule } from 'primeng/skeleton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';

const PRIMENG_MODULES = [
  CascadeSelectModule,
  EditorModule,
  MessagesModule,
  MessageModule,
  MultiSelectModule,
  PanelMenuModule,
  SidebarModule,
  TagModule,
  BadgeModule,
  DropdownModule,
  TreeSelectModule,
  MenuModule,
  AvatarModule,
  TableModule,
  RippleModule,
  PaginatorModule,
  ButtonModule,
  TreeTableModule,
  DividerModule,
  FileUploadModule,
  CalendarModule,
  CheckboxModule,
  TreeModule,
  DragDropModule,
  TabViewModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  CarouselModule,
  StepperModule,
  StepsModule,
  SkeletonModule,
  OverlayPanelModule,
  ToastModule,
  TieredMenuModule,
  InputTextModule,
  TooltipModule,
  InputSwitchModule,
];

@NgModule({
  imports: PRIMENG_MODULES,
  providers: [MessageService],
  exports: PRIMENG_MODULES,
})
export class PrimeNgModule {}
