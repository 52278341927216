import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { buffer, concatMap, debounceTime, EMPTY, filter, map, Observable, withLatestFrom } from 'rxjs';
import { Dashboard, DashboardWidget } from '../../models';
import { AuthApiService, DashboardsApiService, DashboardWidgetsApiService } from '../../services/api-services';
import { GridsterItem } from 'angular-gridster2';
import { tapResponse } from '@ngrx/operators';
import keyBy from 'lodash/keyBy';
import { DashboardWidgetUpsertSidebarData } from './dashboard-widget-upsert-sidebar/dashboard-widget-upsert-sidebar.component';

export interface DashboardViewerState {
  dashboard?: Dashboard;
  dashboardWidgets: DashboardWidget[];
  isOwner: boolean;
  isEditing: boolean;
  sidebarData?: DashboardWidgetUpsertSidebarData;
}

@Injectable()
export class DashboardViewerStore extends ComponentStore<DashboardViewerState> {
  private static readonly DEFAULT_STATE: DashboardViewerState = {
    dashboardWidgets: [],
    isOwner: false,
    isEditing: false,
  };

  public readonly dashboard$ = this.select((state) => state.dashboard).pipe(filter((d): d is Dashboard => Boolean(d)));
  public readonly dashboardWidgets$ = this.select((state) => state.dashboardWidgets);
  public readonly isOwner$ = this.select((state) => state.isOwner);
  public readonly isEditing$ = this.select((state) => state.isEditing);
  public readonly sidebarData$ = this.select((state) => state.sidebarData);

  constructor(
    private readonly authApiService: AuthApiService,
    private readonly dashboardsApiService: DashboardsApiService,
    private readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
  ) {
    super(DashboardViewerStore.DEFAULT_STATE);
  }

  public init(dashboardId?: string): void {
    const obs = dashboardId
      ? this.dashboardsApiService.getDashboard(dashboardId)
      : this.dashboardsApiService.getDefaultDashboard();

    obs
      .pipe(
        map((res) => res.data),
        filter((d): d is Dashboard => Boolean(d)),
      )
      .subscribe((dashboard) => {
        this.patchState({
          dashboard,
          dashboardWidgets: dashboard.widgets,
          isOwner: dashboard.created_by === this.authApiService.user?.user_id,
        });
      });
  }

  public readonly updateDashboardState = this.updater(
    (state: DashboardViewerState, dashboard: Dashboard): DashboardViewerState => ({ ...state, dashboard }),
  );

  public readonly updateDashboardWidgetsState = this.updater(
    (state: DashboardViewerState, dashboardWidgets: DashboardWidget[]): DashboardViewerState => ({
      ...state,
      dashboardWidgets,
    }),
  );

  public readonly updateIsEditingState = this.updater(
    (state: DashboardViewerState, isEditing: boolean): DashboardViewerState => ({ ...state, isEditing }),
  );

  public readonly updateSidebarDataState = this.updater(
    (state: DashboardViewerState, sidebarData?: DashboardWidgetUpsertSidebarData): DashboardViewerState => ({
      ...state,
      sidebarData,
    }),
  );

  public readonly updateWidgetPlot = this.effect((updateWidgetPlotEffect$: Observable<GridsterItem>) =>
    updateWidgetPlotEffect$.pipe(
      buffer(updateWidgetPlotEffect$.pipe(debounceTime(500))),
      withLatestFrom(this.dashboard$),
      concatMap(([gridsterItems, dashboard]) =>
        this.dashboardWidgetsApiService.updateDashboardPlot(dashboard.id, {
          widgets: gridsterItems.map((item: GridsterItem) => ({
            id: item.id,
            height: item.rows,
            width: item.cols,
            row_location: item.y,
            column_location: item.x,
          })),
        }),
      ),
      withLatestFrom(this.dashboard$),
      tapResponse(
        ([res, dashboard]) => {
          const widgets = keyBy(res.data, 'id');
          this.updateDashboardState({
            ...dashboard,
            widgets: dashboard.widgets.map((w) => ({ ...(widgets[w.id] || w), dashboard_datums: w.dashboard_datums })),
          });
        },
        () => EMPTY,
      ),
    ),
  );
}
