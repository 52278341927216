import { Pipe, PipeTransform } from '@angular/core';
import { NOT_APPLICABLE_NUMERIC_VALUE } from '../../../../models';
import { NumericInputType } from '../numeric-input.component';

@Pipe({
  name: 'numericInputType',
})
export class NumericInputTypePipe implements PipeTransform {
  transform(value: string | null, isInputDisabled: boolean): NumericInputType {
    if (value === NOT_APPLICABLE_NUMERIC_VALUE) {
      return NumericInputType.notApplicable;
    } else if (isInputDisabled) {
      return NumericInputType.readonly;
    } else {
      return NumericInputType.full;
    }
  }
}
