import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Dashboard, DashboardUpsertRequest } from '../../../models';
import { DashboardsApiService } from '../../../services/api-services';
import { UniquenessValidator } from '../../../validators';
import { map } from 'rxjs';

interface DashboardUpsertFormModel {
  name: FormControl<string>;
  coreDashboardId: FormControl<string | null>;
}

export class DashboardUpsertForm extends FormGroup<DashboardUpsertFormModel> {
  constructor(
    readonly dashboardsApiService: DashboardsApiService,
    readonly dashboard?: Dashboard,
    readonly copy?: boolean,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      name: fb.nonNullable.control(dashboard && copy ? `Copy of ${dashboard.name}` : dashboard?.name ?? '', {
        validators: [Validators.required, Validators.maxLength(200)],
        asyncValidators: UniquenessValidator.validate(
          (value: string) =>
            dashboardsApiService.checkDashboardName(value.trim()).pipe(map((res) => res.data.available)),
          dashboard ? [dashboard.name] : [],
        ),
      }),
      coreDashboardId: fb.control(null),
    });
  }

  public toModel(): DashboardUpsertRequest {
    return { name: this.controls.name.value, core_dashboard_id: this.controls.coreDashboardId.value || undefined };
  }
}
