import { Component, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'lib-popover',
  standalone: false,
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.scss',
})
export class PopoverComponent {
  @Input() style: object = {};
  @ViewChild('popover') popoverElement?: OverlayPanel;

  toggle(event: any): void {
    this.popoverElement?.toggle(event);
  }
}
