import { Component, Input } from '@angular/core';
import { DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';
import { ActionItem, DashboardWidget, DashboardWidgetType, FiscalYear } from '../../models';

@Component({
  selector: 'lib-view-dashboard',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent {
  @Input() widgets: DashboardWidget[] = [];
  @Input() metricUrls: Map<string, string> = new Map();
  @Input() fiscalYearItems: ActionItem<FiscalYear>[] = [];

  gridsterConfig: GridsterConfig;
  readonly eWidgetType = DashboardWidgetType;

  constructor() {
    this.gridsterConfig = {
      pushItems: false,
      gridType: GridType.Fit,
      displayGrid: DisplayGrid.None,
      resizable: {
        enabled: false,
      },
      draggable: {
        enabled: false,
      },
      minCols: 4,
      maxCols: 4,
      minRows: 3,
      maxRows: 3,
      defaultItemCols: 4,
      defaultItemRows: 3,
    };
  }
}
