import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DashboardWidgetUpsertRequest,
  DashboardWidgetType,
  DashboardWidget,
  SINGLE_DATUMS_WIDGETS,
} from '../../../models';

interface DashboardWidgetUpsertFormModel {
  label: FormControl<string>;
  datumId: FormControl<string>;
  datumIds: FormControl<string[]>;
}

export class DashboardWidgetUpsertForm extends FormGroup<DashboardWidgetUpsertFormModel> {
  constructor(
    widgetType: DashboardWidgetType,
    dashboardWidget?: DashboardWidget,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    const singleDatumWidget = SINGLE_DATUMS_WIDGETS.includes(widgetType);

    super({
      label: fb.nonNullable.control(dashboardWidget?.label || '', {
        validators: [Validators.required, Validators.maxLength(200)],
      }),
      datumId: fb.nonNullable.control(dashboardWidget?.dashboard_datums[0].id || '', {
        validators: singleDatumWidget ? [Validators.required] : [],
      }),
      datumIds: fb.nonNullable.control(dashboardWidget?.dashboard_datums.map((d) => d.id) || [], {
        validators: singleDatumWidget ? [] : [Validators.required],
      }),
    });
  }

  public toModel(
    widgetType: DashboardWidgetType,
    columnLocation: number,
    rowLocation: number,
    width: number,
    height: number,
  ): DashboardWidgetUpsertRequest {
    return {
      label: this.controls.label.value,
      dashboard_datum_ids: SINGLE_DATUMS_WIDGETS.includes(widgetType)
        ? [this.controls.datumId.value]
        : this.controls.datumIds.value,
      column_location: columnLocation,
      row_location: rowLocation,
      widget_type: widgetType,
      width,
      height,
    };
  }
}
