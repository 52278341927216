import { Observable } from 'rxjs';

import { ApiResponse, AttributeCheckResponse, DashboardDatum, UpsertDashboardDatumRequest } from '../../../models';

export abstract class DashboardDatumsApiService {
  public abstract createDashboardDatum(payload: UpsertDashboardDatumRequest): Observable<ApiResponse<DashboardDatum>>;

  public abstract deleteDashboardDatum(dashboardDatumId: string): Observable<undefined>;

  public abstract editDashboardDatum(
    dashboardDatumId: string,
    payload: UpsertDashboardDatumRequest,
  ): Observable<ApiResponse<DashboardDatum>>;

  public abstract listDashboardDatums(
    query?: string,
    page?: number,
    pageSize?: number,
  ): Observable<ApiResponse<DashboardDatum[]>>;

  public abstract checkDashboardDatumName(name: string): Observable<ApiResponse<AttributeCheckResponse>>;
}
