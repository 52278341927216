import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  BaseValue,
  DataRequestPublicDocument,
  Doc,
  DocMetaData,
  DocumentFileData,
  DocumentHostEnv,
  DocumentPayloadOptions,
  DocumentTypeDetails,
  EsSearchOptionsPayload,
  Filter,
  ItemType,
  PublicDocUploadedFileMetaData,
  RequestDocMetadata,
  SearchOptions,
  ToastStatus,
} from '../../../models';
import { ClientAssetsService } from '../client-assets/client-assets.service';
import { DownloadDocumentService } from '../../common/document/download-document.service';
import { DocumentContext } from '../../../metric-editor-form/models/documentContext';
import { ClientCollaboratorsService } from '../client-collaborators/client-collaborators.service';
import { DataRequestDocumentPayloadOptions } from '../../../models/documents';
import { TranslateService } from '../../common/translate/translate.service';
import { ClientPublicCollaboratorsService } from '../client-public-data-request-collaborators/client-public-collaborators.service';
import { DataRequestInfo } from '../../../metric-editor-form/models/dataRequestInfo';
import { DocumentCategory, PlaformDocumentCategory } from '../../../documents';
import { ToastService } from '../../../components';

@Injectable({
  providedIn: 'root',
})
export class ClientDocumentsService {
  apiNameSearch: keyof ApplicationApiDefinition = 'search';
  apiNameDocuments: keyof ApplicationApiDefinition = 'documents';

  resourceDoc: string;
  resourceDocSearch: string;
  resourceCoreSearch: string;
  resourceAssetsDoc: string;
  resourceCore: string;

  serviceDocumentsPath: string;
  serviceSearchPath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private toastService: ToastService,
    private clientCollaboratorsService: ClientCollaboratorsService,
    private clientPublicCollaboratorsService: ClientPublicCollaboratorsService,
    private assetsService: ClientAssetsService,
    private translateService: TranslateService,
    private downloadDocumentService: DownloadDocumentService,
  ) {
    this.serviceDocumentsPath = apiService.getServicePath(this.apiNameDocuments);
    this.serviceSearchPath = apiService.getServicePath(this.apiNameSearch);

    this.resourceDocSearch = this.apiService.apiConfig.apis.search.resources.documents_search;
    this.resourceCoreSearch = this.apiService.apiConfig.apis.search.resources.resources;
    this.resourceAssetsDoc = this.apiService.apiConfig.apis.documents.resources.assets;
    this.resourceDoc = this.apiService.apiConfig.apis.documents.resources.documents;
    this.resourceCore = this.apiService.apiConfig.apis.documents.resources.resources;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): EsSearchOptionsPayload {
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'status', 'public');
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'tag', 'tag_ids');

    const payload: EsSearchOptionsPayload = this.baseService.esPayloadFromSearchOptions(searchOptions);

    switch (searchOptions.item_type) {
      case ItemType.metric_data_exports:
        payload.filters.push({
          field: 'category',
          value: [
            DocumentCategory.custom_template_export,
            DocumentCategory.framework_template_export,
            DocumentCategory.metric_data_export,
            DocumentCategory.questionnaire_export,
            DocumentCategory.questionnaire_submission,
            DocumentCategory.cdp_automated_transfer,
            DocumentCategory.benchmark_v2_export,
            DocumentCategory.source_comparison_v1_export,
            DocumentCategory.data_table_v2_export,
            DocumentCategory.xbrl_tag_export,
          ],
          type: 'only',
        });
        break;
      default:
        payload.filters.push({
          field: 'category',
          value: [
            DocumentCategory.custom_template_export,
            DocumentCategory.framework_template_export,
            PlaformDocumentCategory.metric_attachment,
            DocumentCategory.metric_data_export,
            DocumentCategory.questionnaire_export,
            DocumentCategory.questionnaire_submission,
            DocumentCategory.cdp_automated_transfer,
            DocumentCategory.benchmark_v2_export,
            DocumentCategory.source_comparison_v1_export,
            DocumentCategory.data_table_v2_export,
            DocumentCategory.xbrl_tag_export,
          ],
          type: 'exclude',
        });
    }

    const tagIdsFilter: Filter | undefined = payload.filters.find((filter: Filter) => filter.field === 'tag_ids');
    if (tagIdsFilter) {
      // the document search api uses a different name for the tag filter
      tagIdsFilter.value = searchOptions.filters.tag_ids?.id;
    }

    const sourceFilter: Filter | undefined = payload.filters.find((filter: Filter) => filter.field === 'source');
    if (sourceFilter) {
      // the document search api uses a different name for the source filter
      sourceFilter.field = 'business_unit_ids';
    }

    return payload;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Doc[]>> {
    const payload = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.serviceSearchPath}${this.resourceDocSearch}/search`, payload).pipe(
      map((result: ApiResponse<Doc[]>) => {
        result.data.forEach((doc: any) => {
          const highlights: string[] = [];
          if (doc.highlight) {
            for (const highlight_item of doc.highlight) {
              if (highlights.indexOf(String(highlight_item)) < 0) {
                highlights.push(String(highlight_item));
              }
            }
          }
          doc.highlight = highlights;
        });
        return result;
      }),
    );
  }

  getDocuments(document_ids: string[]): Observable<ApiResponse<Doc[]>> {
    return this.apiService.post(`${this.serviceSearchPath}${this.resourceDocSearch}/documents`, { document_ids });
  }

  getCoreDocuments(document_ids: string[]): Observable<ApiResponse<Doc[]>> {
    return this.apiService.post(`${this.serviceSearchPath}${this.resourceCoreSearch}/documents`, { document_ids });
  }

  deleteDocument(doc_id: string): Observable<ApiResponse<Doc>> {
    return this.apiService.delete(`${this.serviceDocumentsPath}${this.resourceDoc}/${doc_id}`);
  }

  updateDocument(doc_id: string, payload: any): Observable<ApiResponse<DocMetaData>> {
    return this.apiService.post(`${this.serviceDocumentsPath}${this.resourceDoc}/${doc_id}/metadata`, payload);
  }

  getDocumentMetaData(doc_id: string): Observable<ApiResponse<DocMetaData>> {
    return this.apiService.get(`${this.serviceDocumentsPath}${this.resourceDoc}/${doc_id}/metadata`);
  }

  listDocumentsMetadata(docIds: string[]): Observable<ApiResponse<DocMetaData[]>> {
    return this.apiService.post(`${this.serviceDocumentsPath}${this.resourceDoc}/metadata/list`, {
      document_ids: docIds,
    });
  }

  // Request documents
  uploadRequestDocument(
    formData: FormData,
  ): Observable<ApiResponse<{ document_id: string; name: string; format: string }>> {
    return this.apiService.upload(
      `${this.serviceDocumentsPath}${this.resourceAssetsDoc}/data_request_documents/v1/documents`,
      formData,
    );
  }

  getRequestDocument(docId: string): Observable<Blob> {
    return this.apiService.get(
      `${this.serviceDocumentsPath}${this.resourceAssetsDoc}/data_request_documents/v1/documents/${docId}`,
      { headers: { 'Content-Type': 'application/octet-stream' }, responseType: 'blob' },
    );
  }

  deleteRequestDocument(docId: string): Observable<null> {
    return this.apiService.delete(
      `${this.serviceDocumentsPath}${this.resourceAssetsDoc}/data_request_documents/v1/documents/${docId}`,
    );
  }

  getRequestDocumentMetaData(
    document_ids: string[],
    showDeleted = false,
  ): Observable<ApiResponse<RequestDocMetadata[]>> {
    return this.apiService.post(
      `${this.serviceDocumentsPath}${this.resourceAssetsDoc}/data_request_documents/v1/documents/metadata`,
      { document_ids, show_deleted: showDeleted },
    );
  }

  // Request public documents
  uploadPublicRequestDocument(
    dataRequestId: string,
    dataRequestSourceId: string,
    formData: FormData,
  ): Observable<ApiResponse<{ document_id: string }>> {
    return this.clientPublicCollaboratorsService.uploadDataRequestDocument(
      dataRequestId,
      dataRequestSourceId,
      formData,
    );
  }

  getPublicRequestDocument(dataRequestInfo: DataRequestInfo, docId: string): Observable<Blob> {
    return this.clientPublicCollaboratorsService.getDocument(dataRequestInfo.id, dataRequestInfo.sourceId, docId);
  }

  getPublicRequestDocumentMetaData(
    dataRequestId: string,
    dataRequestSourceId: string,
    document_ids: string[],
  ): Observable<ApiResponse<RequestDocMetadata[]>> {
    return this.clientPublicCollaboratorsService.getDocumentMetaData(dataRequestId, dataRequestSourceId, document_ids);
  }

  getValueDefinitionDocumentMetaData(
    dataRequestId: string,
    dataRequestSourceId: string,
    documentId: string,
    metricId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<DataRequestPublicDocument>> {
    return this.clientPublicCollaboratorsService.getValueDefinitionDocumentMetaData(
      dataRequestId,
      dataRequestSourceId,
      documentId,
      metricId,
      valueDefinitionId,
    );
  }

  listDocumentsUploadedForDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    valueGroupSetId: string,
    documentIds: string[],
    showHistoricalData: boolean = false,
    showRecommendationData: boolean = false,
  ): Observable<ApiResponse<PublicDocUploadedFileMetaData[]>> {
    return this.clientPublicCollaboratorsService.listDocumentsUploadedForDataRequest(
      dataRequestId,
      dataRequestSourceId,
      indicatorId,
      valueGroupSetId,
      documentIds,
      showHistoricalData,
      showRecommendationData,
    );
  }

  serveDocumentsUploadedForDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    valueGroupSetId: string,
    documentId: string,
    showHistoricalData: boolean = false,
    showRecommendationData: boolean = false,
  ): Observable<Blob> {
    return this.clientPublicCollaboratorsService.serveDocumentsUploadedForDataRequest(
      dataRequestId,
      dataRequestSourceId,
      indicatorId,
      valueGroupSetId,
      documentId,
      showHistoricalData,
      showRecommendationData,
    );
  }

  // Utilities
  downloadDocumentByItemType(
    value: BaseValue<DocumentTypeDetails>,
    docFileName: string,
    documentContext: DocumentContext,
    document?: Doc,
  ): void {
    switch (documentContext.itemType) {
      case ItemType.data_requests_request:
      case ItemType.public_data_requests_request:
        this.getDocumentFileDataByItemType(
          documentContext.itemType,
          this.getDocumentPayloadOptions(value, documentContext, docFileName),
        ).subscribe((response) => {
          this.downloadDocumentService.downloadAction(docFileName || response.doc_name, response.doc_blob!);
        });
        break;
      default:
        if (document) {
          this.getDocumentFileDataByItemType(
            documentContext.itemType,
            document,
            value.type_details.document_host_env,
          ).subscribe((response) => {
            this.downloadDocumentService.downloadAction(response.doc_name, response.doc_blob!);
          });
        }
    }
  }

  downloadDocumentById(documentId: string, itemType: ItemType = ItemType.docs_doc): void {
    this.displayStartDownloadAlert();

    this.getDocumentsDataFromPlatform(documentId)
      .pipe(
        switchMap((documentFileData: DocumentFileData) =>
          this.assetsService
            .getDoc(itemType, documentFileData.doc!.storage_filename)
            .pipe(map((blob) => ({ ...documentFileData, doc_blob: blob }))),
        ),
      )
      .subscribe((documentFileData: DocumentFileData) => {
        this.downloadDocumentService.downloadAction(
          `${documentFileData.doc_name}.${documentFileData.doc_format}`,
          documentFileData.doc_blob!,
        );
      });
  }

  getDocumentPayloadOptions(
    value: BaseValue<DocumentTypeDetails>,
    documentContext: DocumentContext,
    fileName?: string,
  ): DocumentPayloadOptions | DataRequestDocumentPayloadOptions {
    switch (documentContext.itemType) {
      case ItemType.data_requests_request:
      case ItemType.public_data_requests_request:
        if (documentContext.dataRequestInfo) {
          return {
            data_request_id: documentContext.dataRequestInfo.id,
            data_request_source_id: documentContext.dataRequestInfo.sourceId,
            metric_id: documentContext.dataRequestInfo.metricId,
            document_id: value.type_details.document_id,
            value_definition_id: value.value_definition_id,
            document_name: fileName,
          };
        } else {
          throw new Error(this.translateService.instant('Data request related details are required.'));
        }
      default:
        return {
          document_id: value.type_details.document_id,
          host_env: value.type_details.document_host_env,
        };
    }
  }

  public getDocumentMetaDataByItemType(
    itemType: ItemType,
    payload: DocumentPayloadOptions | DataRequestDocumentPayloadOptions,
  ): Observable<DocumentFileData> {
    switch (itemType) {
      case ItemType.data_requests_request:
        return this.getDocumentDataForDataRequest(payload as DataRequestDocumentPayloadOptions);
      case ItemType.public_data_requests_request:
        return this.getDocumentDataForPublicDataRequest(payload as DataRequestDocumentPayloadOptions);
      default:
        if (payload.host_env === 'core') {
          return this.getDocumentsDataFromCore(payload.document_id);
        }
        return this.getDocumentsDataFromPlatform(payload.document_id);
    }
  }

  private getDocumentFileDataByItemType(
    itemType: ItemType,
    payload: Doc | DocumentPayloadOptions | DataRequestDocumentPayloadOptions,
    hostEnv: DocumentHostEnv = 'platform',
  ): Observable<DocumentFileData> {
    switch (itemType) {
      case ItemType.data_requests_request:
        return this.downloadDocumentFromDataRequest(<DataRequestDocumentPayloadOptions>payload);
      case ItemType.public_data_requests_request:
        return this.downloadDocumentFromPublicDataRequest(<DataRequestDocumentPayloadOptions>payload);
      default:
        const itemType = hostEnv === 'core' ? ItemType.docs_resource : ItemType.docs_doc;
        return this.downloadDocumentFromAssets(<Doc>payload, itemType);
    }
  }

  private getDocumentDataForDataRequest(payload: DataRequestDocumentPayloadOptions): Observable<DocumentFileData> {
    return this.clientCollaboratorsService
      .getValueDefinitionDocumentMetaData(
        payload.data_request_id,
        payload.data_request_source_id,
        payload.document_id,
        payload.metric_id,
        payload.value_definition_id,
      )
      .pipe(
        map((res) => ({
          doc_name: res.data.meta.name,
          doc_format: res.data.extension,
        })),
      );
  }

  private getDocumentDataForPublicDataRequest(
    payload: DataRequestDocumentPayloadOptions,
  ): Observable<DocumentFileData> {
    return this.clientPublicCollaboratorsService
      .getValueDefinitionDocumentMetaData(
        payload.data_request_id,
        payload.data_request_source_id,
        payload.document_id,
        payload.metric_id,
        payload.value_definition_id,
      )
      .pipe(
        map((res) => ({
          doc_name: res.data.meta.name,
          doc_format: res.data.extension,
        })),
      );
  }

  private getDocumentsDataFromPlatform(documentId: string): Observable<DocumentFileData> {
    return this.getDocuments([documentId]).pipe(
      map((res) => {
        const doc = res.data[0];
        return {
          doc,
          doc_name: doc.name,
          doc_format: doc.format,
        };
      }),
    );
  }

  private getDocumentsDataFromCore(documentId: string): Observable<DocumentFileData> {
    return this.getCoreDocuments([documentId]).pipe(
      map((res) => {
        const doc = res.data[0];
        return {
          doc,
          doc_name: doc.name,
          doc_format: doc.format,
        };
      }),
    );
  }

  private downloadDocumentFromAssets(doc: Doc, itemType: ItemType = ItemType.docs_doc): Observable<DocumentFileData> {
    const docFileName = `${doc.name.split(' ').join('_')}.${doc.format}`;
    this.displayStartDownloadAlert(docFileName);
    return this.assetsService
      .getDoc(itemType, doc.storage_filename)
      .pipe(map((response) => ({ doc_name: docFileName, doc_blob: response, doc_format: '' })));
  }

  private downloadDocumentFromDataRequest(payload: DataRequestDocumentPayloadOptions): Observable<DocumentFileData> {
    this.displayStartDownloadAlert(payload.document_name);
    return this.clientCollaboratorsService
      .serveValueDefinitionDocument(
        payload.data_request_id,
        payload.data_request_source_id,
        payload.document_id,
        payload.metric_id,
        payload.value_definition_id,
      )
      .pipe(map((response) => ({ doc_name: payload.document_name || 'no_name', doc_blob: response, doc_format: '' })));
  }

  private downloadDocumentFromPublicDataRequest(
    payload: DataRequestDocumentPayloadOptions,
  ): Observable<DocumentFileData> {
    this.displayStartDownloadAlert(payload.document_name);
    return this.clientPublicCollaboratorsService
      .serveValueDefinitionDocument(
        payload.data_request_id,
        payload.data_request_source_id,
        payload.document_id,
        payload.metric_id,
        payload.value_definition_id,
      )
      .pipe(map((response) => ({ doc_name: payload.document_name || 'no_name', doc_blob: response, doc_format: '' })));
  }

  private displayStartDownloadAlert(documentName?: string): void {
    this.toastService.open(
      ToastStatus.SUCCESS,
      this.translateService.instant('{documentName} will start downloading in a few seconds', {
        documentName: documentName ?? 'Document',
      }),
    );
  }
}
