import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { ApiService } from '../../common';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Dashboard,
  DashboardRenameRequest,
  DashboardsFilteringOptions,
  DashboardCopyRequest,
  DashboardUpdatePublicVisibilityRequest,
  AttributeCheckResponse,
  DashboardUpsertRequest,
} from '../../../models';
import { HttpParams } from '@angular/common/http';
import { DashboardsApiService } from '../../api-services';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardsService extends DashboardsApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dashboards;
  }

  public checkDashboardName(name: string): Observable<ApiResponse<AttributeCheckResponse>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/name_check/${name}`);
  }

  public createDashboard(dashboardCreationRequest: DashboardUpsertRequest): Observable<ApiResponse<Dashboard>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/dashboards`, dashboardCreationRequest);
  }

  public renameDashboard(
    dashboardId: string,
    dashboardRenameRequest: DashboardRenameRequest,
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`, dashboardRenameRequest);
  }

  public getDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`);
  }

  public getDefaultDashboard(): Observable<ApiResponse<Dashboard | null>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/default`);
  }

  public listCoreDashboards(): Observable<ApiResponse<Dashboard[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/core/dashboards`);
  }

  public listDashboards(filters: DashboardsFilteringOptions): Observable<ApiResponse<Dashboard[]>> {
    let params = new HttpParams();

    if (filters.page) {
      params = params.append('page', filters.page);
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size);
    }
    if (filters.filters.search_term) {
      params = params.append('query', filters.filters.search_term);
    }
    if (filters.filters.owner) {
      params = params.append('owner_id', filters.filters.owner);
    }
    if (filters.filters.organizational_visibility) {
      params = params.append('visibility', filters.filters.organizational_visibility);
    }
    if (filters.filters.v2) {
      params = params.append('v2', true);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards`, { params });
  }

  public deleteDashboard(dashboardId: string): Observable<undefined> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`);
  }

  public updateDashboardPublicVisibility(
    dashboardId: string,
    dashboardUpdatePublicVisibilityRequest: DashboardUpdatePublicVisibilityRequest,
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/update_public_visibility`,
      dashboardUpdatePublicVisibilityRequest,
    );
  }

  public copyDashboard(
    dashboardId: string,
    dashboardCopyRequest: DashboardCopyRequest,
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/copy`,
      dashboardCopyRequest,
    );
  }

  public publishDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }

  public unpublishDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }
}
