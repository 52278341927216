import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { combineLatestWith, map } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';

import {
  ActionItem,
  ApiResponse,
  Dashboard,
  DashboardPublicVisibility,
  DashboardStatus,
  LimitedUser,
  PaginationState,
  ResourceType,
} from '../../models';
import { DashboardsApiService } from '../../services/api-services/dashboards-api-service/dashboards-api.service';
import { DEFAULT_PAGE_SIZE } from '../../data-table';
import { UsersApiService } from '../../services/api-services/users-api-service/users-api.service';
import { ActionItemUtils } from '../../classes';
import { AuthApiService } from '../../services/api-services';
import { TranslateService } from '../../services/common';
import { DashboardGridOptions } from './dashboard-grid.component';

export interface FilterState {
  owner?: string;
  searchQuery?: string;
  status?: DashboardStatus;
  visibility?: DashboardPublicVisibility;
}

export interface FilterOptionsState {
  userActionItems: ActionItem<LimitedUser>[];
  users: LimitedUser[];
}

export interface DashboardState {
  datumsSidebarVisible: boolean;
  defaultDashboard: Dashboard | null;
  filterOptionsState: FilterOptionsState;
  filterState: FilterState;
  isLoading: boolean;
  dashboards: Dashboard[];
  paginationState: PaginationState;
  total: number;
}

@Injectable()
export class DashboardGridStore extends ComponentStore<DashboardState> {
  public static readonly DEFAULT_STATE: DashboardState = {
    datumsSidebarVisible: false,
    defaultDashboard: null,
    filterOptionsState: { userActionItems: [], users: [] },
    filterState: {},
    isLoading: true,
    dashboards: [],
    paginationState: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE },
    total: 0,
  };

  public readonly datumsSidebarVisible$: Observable<boolean> = this.select((state) => state.datumsSidebarVisible);
  public readonly defaultDashboard$: Observable<Dashboard | null> = this.select((state) => state.defaultDashboard);
  public readonly filterOptionsState$: Observable<FilterOptionsState> = this.select(
    (state) => state.filterOptionsState,
  );
  public readonly filterState$: Observable<FilterState> = this.select((state) => state.filterState);
  public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);
  public readonly dashboards$: Observable<Dashboard[]> = this.select((state) => state.dashboards);
  public readonly paginationState$: Observable<PaginationState> = this.select((state) => state.paginationState);
  public readonly total$: Observable<number> = this.select((state) => state.total);

  constructor(
    private readonly authApiService: AuthApiService,
    private readonly dashboardsApiService: DashboardsApiService,
    private readonly translateService: TranslateService,
    private readonly usersApiService: UsersApiService,
  ) {
    super(DashboardGridStore.DEFAULT_STATE);
  }

  public initialize(options: DashboardGridOptions) {
    let defaultDashboardObs: Observable<Dashboard | null> = of(null);

    if (options.withDefaultForColumn) {
      defaultDashboardObs = this.dashboardsApiService.getDefaultDashboard().pipe(map((res) => res.data));
    }

    forkJoin([
      this.usersApiService.listLimitedUsers({ order_by: 'first_name' }).pipe(
        map((res) => {
          const currentUser = this.authApiService.user;
          return res.data.sort((a, b) => (a.id == currentUser?.user_id ? -1 : b.id == currentUser?.user_id ? 1 : 0));
        }),
      ),
      defaultDashboardObs,
    ]).subscribe(([users, defaultDashboard]) => {
      const userActionItems = ActionItemUtils.resourcesToActionItem(users, ResourceType.user);
      userActionItems[0].title = this.translateService.instant('Me');
      this.patchState({ defaultDashboard, filterOptionsState: { userActionItems, users } });
      this.fetchDashboards();
    });
  }

  public readonly updateFilterState = this.updater(
    (state: DashboardState, newFilterState: Partial<FilterState>): DashboardState => ({
      ...DashboardGridStore.DEFAULT_STATE,
      isLoading: true,
      filterOptionsState: state.filterOptionsState,
      filterState: {
        ...DashboardGridStore.DEFAULT_STATE.filterState,
        searchQuery: state.filterState.searchQuery,
        ...newFilterState,
      },
    }),
  );

  public readonly updateDatumsSidebarVisibleState = this.updater(
    (state: DashboardState, datumsSidebarVisible: boolean): DashboardState => ({
      ...state,
      datumsSidebarVisible,
    }),
  );

  public readonly updateFilterStateSearchQuery = this.updater(
    (state: DashboardState, searchQuery?: string): DashboardState => ({
      ...DashboardGridStore.DEFAULT_STATE,
      isLoading: true,
      filterOptionsState: state.filterOptionsState,
      filterState: { ...state.filterState, searchQuery },
    }),
  );

  public readonly updatePaginationState = this.updater(
    (state: DashboardState, paginationState: Partial<PaginationState>): DashboardState => ({
      ...state,
      paginationState: {
        currentPage: paginationState.currentPage ?? state.paginationState.currentPage,
        pageSize: paginationState.pageSize ?? state.paginationState.pageSize,
      },
    }),
  );

  private readonly updateDashboardsState = this.updater(
    (state: DashboardState, res: ApiResponse<Dashboard[]>): DashboardState => ({
      ...state,
      dashboards: res.data,
      total: res.meta.total_count ?? 0,
    }),
  );

  public readonly fetchDashboards = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.paginationState$, this.filterState$),
      switchMap(([_, paginationState, filterState]) =>
        this.dashboardsApiService.listDashboards({
          page: paginationState.currentPage,
          page_size: paginationState.pageSize,
          filters: {
            organizational_visibility: filterState.visibility,
            owner: filterState.owner,
            search_term: filterState.searchQuery,
            status: filterState.status,
            v2: true,
          },
        }),
      ),
      tapResponse(
        (res: ApiResponse<Dashboard[]>) => {
          this.updateDashboardsState(res);
          this.patchState({ isLoading: false });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );
}
