import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DashboardPublicVisibility, DashboardStatus, DashboardWidgetType } from '../models';

export const DashboardStatusToText = {
  [DashboardStatus.DRAFT]: _('Unpublished'),
  [DashboardStatus.PUBLISHED]: _('Published'),
};

export const DashboardPublicVisibilityToText = {
  [DashboardPublicVisibility.PUBLIC]: _('Anyone with the link'),
  [DashboardPublicVisibility.ORGANIZATION]: _('Organization'),
  [DashboardPublicVisibility.RESTRICTED]: _('Restricted'),
};

export const DashboardPublicVisibilityToIcon = {
  [DashboardPublicVisibility.PUBLIC]: 'globe-black-outline',
  [DashboardPublicVisibility.ORGANIZATION]: 'factory-black-outline',
  [DashboardPublicVisibility.RESTRICTED]: 'locked',
};

export const DashboardWidgetTypeToText: Record<DashboardWidgetType, string> = {
  [DashboardWidgetType.PIE_CHART_WIDGET]: _('Pie chart'),
  [DashboardWidgetType.LINE_CHART_WIDGET]: _('Line chart'),
  [DashboardWidgetType.BAR_CHART_WIDGET]: _('Bar chart'),
  [DashboardWidgetType.STACKED_BAR_CHART_WIDGET]: _('Stack Bar chart'),
  [DashboardWidgetType.DONUT_CHART_WIDGET]: _('Donut chart'),
  [DashboardWidgetType.CHART_WIDGET]: _('Stack Bar Chart'),
  [DashboardWidgetType.DATA_POINT_WIDGET]: _('Single Data Point'),
  [DashboardWidgetType.VALUE_DEFINITION_TARGET_WIDGET]: _('Target Widget'),
};

export const DashboardWidgetTypeToIcon: Record<DashboardWidgetType, string> = {
  [DashboardWidgetType.PIE_CHART_WIDGET]: 'single-data-point',
  [DashboardWidgetType.LINE_CHART_WIDGET]: 'target',
  [DashboardWidgetType.BAR_CHART_WIDGET]: 'stack-bar-chart',
  [DashboardWidgetType.STACKED_BAR_CHART_WIDGET]: 'stack-bar-chart',
  [DashboardWidgetType.DONUT_CHART_WIDGET]: 'single-data-point',
  [DashboardWidgetType.CHART_WIDGET]: 'stack-bar-chart',
  [DashboardWidgetType.DATA_POINT_WIDGET]: 'single-data-point',
  [DashboardWidgetType.VALUE_DEFINITION_TARGET_WIDGET]: 'target',
};

export const DashboardsHelperTranslations = {
  changesSaved: _('Changes saved'),
  copy: _('Copy'),
  create: _('Create'),
  created: _('{name} has been created'),
  createdDatum: _('Data point added'),
  createdWidget: _('The widget was successfully created.'),
  deleteDashboard: _('Delete dashboard'),
  deletedDashboard: _('The dashboard was successfully deleted.'),
  deletedWidget: _('The widget was successfully deleted.'),
  deleteDashboardWarning: _('You are about to delete this dashboard. Would you like to continue?'),
  deleteWidgetWarning: _('Are you sure you want to remove this widget from the dashboard?'),
  editedDashboard: _('The widget was successfully edited.'),
  publishedWarning: _(
    'This dashboard is published as template in customer environments. Are you sure you want to delete it?',
  ),
  removeWidget: _('Remove widget?'),
  rename: _('Rename'),
  renamedDashboard: _('The dashboard was successfully renamed.'),
  publishedDashboard: _('The dashboard was successfully published.'),
  unpublishedDashboard: _('The dashboard was successfully unpublished.'),
  yes: _('Yes'),
};
