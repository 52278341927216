import { Component, Inject, OnInit } from '@angular/core';
import {
  DashboardDatum,
  DialogResult,
  Indicator,
  Status,
  UpsertDashboardDatumRequest,
  ValueDefinition,
  ValueDefinitionFieldType,
} from '../../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpsertDashboardDatumForm } from './upsert-dashboard-datum.form';
import { DashboardGridOptions } from '../../dashboard-grid/dashboard-grid.component';
import { DashboardDatumsApiService } from '../../../services/api-services';

export interface UpsertDashboardDatumDialogResults {
  payload: UpsertDashboardDatumRequest;
}

export interface UpsertDashboardDatumDialogData {
  options: DashboardGridOptions;
  dashboardDatum?: DashboardDatum;
}

enum StepperSteps {
  SELECT_METRIC_STEP = 0,
  SELECT_VALUE_DEFINITION_STEP = 1,
  CONFIGURE_DATUM_STEP = 2,
}

@Component({
  selector: 'lib-upsert-dashboard-datum-dialog',
  templateUrl: './upsert-dashboard-datum-dialog.component.html',
  styleUrls: ['./upsert-dashboard-datum-dialog.component.scss'],
})
export class UpsertDashboardDatumDialogComponent implements OnInit {
  public readonly eStepperSteps = StepperSteps;
  public readonly supportedValueDefinitionFieldType = [
    ValueDefinitionFieldType.calculated,
    ValueDefinitionFieldType.table_total,
    ValueDefinitionFieldType.number,
  ];

  public form?: UpsertDashboardDatumForm;
  public selectedIndex: number = StepperSteps.SELECT_METRIC_STEP;
  public selectedIndicator?: Indicator;
  public selectedValueDefinition?: ValueDefinition;

  constructor(
    private readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dialogRef: MatDialogRef<
      UpsertDashboardDatumDialogComponent,
      DialogResult<UpsertDashboardDatumDialogResults>
    >,
    @Inject(MAT_DIALOG_DATA) public data: UpsertDashboardDatumDialogData,
  ) {}

  public ngOnInit(): void {
    this.form = new UpsertDashboardDatumForm(this.dashboardDatumsApiService, this.data.dashboardDatum);
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public onSelectedIndexChange(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
  }

  public onSelectMetric(indicator: Indicator) {
    this.selectedIndicator = indicator;
    this.selectedValueDefinition = undefined;
    this.selectedIndex = StepperSteps.SELECT_VALUE_DEFINITION_STEP;
  }

  public onSelectValueDefinition(valueDefinition: ValueDefinition): void {
    this.selectedValueDefinition = valueDefinition;

    if (this.form && !this.data.dashboardDatum) {
      this.form.controls.label.setValue(valueDefinition.label || '');
      this.form.markAllAsTouched();
    }

    this.selectedIndex = StepperSteps.CONFIGURE_DATUM_STEP;
  }

  public back(): void {
    if (this.selectedIndex !== StepperSteps.SELECT_METRIC_STEP) {
      this.selectedIndex -= 1;
    }
  }

  public next(): void {
    if (this.selectedIndex !== StepperSteps.CONFIGURE_DATUM_STEP) {
      this.selectedIndex += 1;
      return;
    }

    if (!this.form || !this.selectedValueDefinition) {
      return;
    }

    this.dialogRef.close({
      status: Status.SUCCESS,
      data: { payload: this.form.toModel(this.selectedValueDefinition.id) },
    });
  }
}
