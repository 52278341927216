import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  ApplicationApiDefinition,
  DataRequestCommentsReport,
  FieldAuditEvent,
  FieldAuditEventsRequest,
} from '../../../models';
import { ApiService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicDataRequestsService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.dataRequests;
  }

  listFieldAuditEventsForDataRequest(
    dataRequestId: string,
    fieldAuditRequest: FieldAuditEventsRequest,
  ): Observable<ApiResponse<FieldAuditEvent[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/field_audit_events`,
      fieldAuditRequest,
    );
  }

  public getDataRequestSourceCommentsReport(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<DataRequestCommentsReport[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/comments_report`,
    );
  }
}
