import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  DocumentTypeDetails,
  FileDocumentInterface,
  ValueDefinition,
  ValueDefinitionTemplateType,
} from '../../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';
import { SearchService } from '../../../search';
import { Icon } from '../../../icons/icons.model';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'lib-metric-structure-document-field',
  templateUrl: './metric-structure-document-field.component.html',
  styleUrls: ['./metric-structure-document-field.component.scss'],
})
export class MetricStructureDocumentFieldComponent implements OnInit {
  @Input() valueDefinition?: ValueDefinition<DocumentTypeDetails>;
  documentId: string = '';
  documentWithIcon$?: Observable<{ document: FileDocumentInterface | undefined; icon: Icon }>;

  readonly eValueDefinitionTemplateType: typeof ValueDefinitionTemplateType = ValueDefinitionTemplateType;

  constructor(
    private metricStructureStateService: MetricStructureStateService,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.documentId = this.valueDefinition?.type_details?.document_id ?? '';

    this.documentWithIcon$ = this.metricStructureStateService.cachedDocumentList$.pipe(
      map((docs) => {
        const doc = docs.find((item) => item.id === this.documentId);
        return { document: doc, icon: doc?.format ? this.searchService.getFileFormatIcon(doc?.format) : '' };
      }),
      startWith({ document: undefined, icon: '' }),
    );
  }
}
