import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewerActionDirective } from './viewer-action/viewer-action.directive';
import { PermissionDirective } from './permission/permission.directive';
import { DebounceClickDirective } from './debounce-click/debounce-click.directive';
import { DynamicHostDirective } from './dynamic-component-loader/dynamic-host.directive';
import { DynamicComponentLoaderDirective } from './dynamic-component-loader/dynamic-component-loader.directive';
import { StripNonPrintableCharactersDirective } from './strip-non-printable-characters/strip-non-printable-characters.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation/click-stop-propagation.directive';
import { RightClickMenuTriggerDirective } from './right-click-menu-trigger/right-click-menu-trigger.directive';
import { AutoFocusInputDirective } from './auto-focus-input/auto-focus-input.directive';
import { EllipsifyMeDirective } from './ellipsify-me/ellipsify-me.directive';
import { TextCompareDirective } from './text-compare/text-compare.directive';
import { ScrollToBottomDirective } from './scroll-to-bottom/scroll-to-bottom.directive';
import { TrimWhitespaceDirective } from './trim-whitespace/trim-whitespace.directive';
import { BeforeUnloadDirective } from './before-unload/before-unload.directive';
import { TitleOnEllipsisDirective } from './title-on-ellipsis/title-on-ellipsis.directive';
import { PasskeyReadyDirective } from './passkey-ready/passkey-ready.directive';

export const DIRECTIVES = [
  ViewerActionDirective,
  PermissionDirective,
  DebounceClickDirective,
  DynamicHostDirective,
  DynamicComponentLoaderDirective,
  StripNonPrintableCharactersDirective,
  ClickStopPropagationDirective,
  RightClickMenuTriggerDirective,
  AutoFocusInputDirective,
  EllipsifyMeDirective,
  TextCompareDirective,
  ScrollToBottomDirective,
  TrimWhitespaceDirective,
  BeforeUnloadDirective,
  TitleOnEllipsisDirective,
  PasskeyReadyDirective,
];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
