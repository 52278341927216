import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionItem, Source, TreeTableColumn, TreeTableDataNode, TreeTableEvent } from '../../models';
import cloneDeep from 'lodash/cloneDeep';
import { TreeTable } from 'primeng/treetable';

@Component({
  selector: 'lib-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
})
export class TreeTableComponent<T> implements OnInit {
  private _dataSource!: TreeTableDataNode<T>[];

  @Input() selectionMode: 'single' | 'multiple' | null = null;
  @Input() columns?: TreeTableColumn<T>[];
  @Input() selectable: boolean = false;
  @Input() showHeader: boolean = false;
  @Input() allNodesSelectable: boolean = true;
  @Input() expandAllNodes: boolean = false;
  @Input() showExpandAllButton: boolean = false;
  @Input() showGlobalSearch: boolean = false;
  @Input() globalFilterFields: string[] = [];
  @Input() withGridLines: boolean = false;
  @Output() nodeSelected: EventEmitter<TreeTableDataNode<T>> = new EventEmitter();
  @Output() nodeUnselected: EventEmitter<TreeTableDataNode<T>> = new EventEmitter();
  @Output() rowClicked: EventEmitter<Source> = new EventEmitter();

  @ViewChild('tt') treeTable?: TreeTable;

  @Input()
  set dataSource(data: TreeTableDataNode<T>[]) {
    this._dataSource = data;
    this.expandableNodes.clear();
    this.updateExpandedStates();
  }

  get dataSource(): TreeTableDataNode<T>[] {
    return this._dataSource;
  }

  selectedNodes: TreeTableDataNode<T>[] = [];

  expandedNodes: Set<string> = new Set();

  expandableNodes: Set<string> = new Set();

  ngOnInit() {
    if (this.expandAllNodes) {
      this.dataSource.forEach((node) => this.recursiveExpandNode(node, true));
    }
  }

  public recursiveExpandNode(node: TreeTableDataNode<T>, expand: boolean) {
    if (node.children) {
      node.expanded = expand;
      if (expand) {
        this.expandedNodes.add(node.id);
      } else {
        this.expandedNodes.delete(node.id);
      }
      node.children.forEach((child) => this.recursiveExpandNode(child, expand));
    }
  }

  public nodeSelect(event: TreeTableEvent<T>): void {
    event.node.selected = true;
    this.nodeSelected.emit(event.node);
  }

  public nodeUnselect(event: TreeTableEvent<T>): void {
    event.node.selected = false;
    this.selectedNodes = this.selectedNodes.filter((node) => node.id !== event.node.id);
    this.nodeUnselected.emit(event.node);
  }

  public nodeExpand(event: TreeTableEvent<T>): void {
    event.node.expanded = true;
    this.expandedNodes.add(event.node.id);
    this.checkExpandAllNodes();
  }

  public nodeCollapse(event: TreeTableEvent<T>): void {
    event.node.expanded = false;
    this.expandedNodes.delete(event.node.id);
    this.checkExpandAllNodes();
  }

  public rowClick(sourceActionItem: ActionItem<Source>): void {
    this.rowClicked.emit(sourceActionItem.item);
  }

  private updateExpandedStates(): void {
    if (!this._dataSource) {
      return;
    }
    this._dataSource.forEach((node) => this.recursiveUpdateExpanded(node));
  }

  private recursiveUpdateExpanded(node: TreeTableDataNode<T>): void {
    if (node.selected) {
      this.selectedNodes.push(node);
    }
    if (node.children) {
      node.expanded = this.expandedNodes.has(node.id);
      this.expandableNodes.add(node.id);
      node.children.forEach((child) => this.recursiveUpdateExpanded(child));
    }
  }

  public expandOrCollapseAll(expandAll: boolean): void {
    const temp = cloneDeep(this.dataSource);
    temp.forEach((node) => {
      this.recursiveExpandNode(node, expandAll);
    });
    this.dataSource = temp;
    this.checkExpandAllNodes();
  }

  private checkExpandAllNodes(): void {
    this.expandAllNodes = this.expandableNodes.size > 0 && this.expandableNodes.size === this.expandedNodes.size;
  }

  public filterGlobally(searchTerm: string): void {
    this.treeTable?.filterGlobal(searchTerm, 'contains');
    this.expandOrCollapseAll(Boolean(searchTerm));
  }
}
