import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly tabFeatures = 'noopener,noreferrer';
  private readonly windowFeatures =
    'toolbar=yes,location=yes,status=yes,menubar=yes,scrollbars=yes,resizable=yes,width=800,height=600,noopener,noreferrer';

  public openInNewTab(url: string): void {
    window.open(url, '_blank', this.tabFeatures);
  }

  public openInNewWindow(url: string): void {
    window.open(url, '_blank', this.windowFeatures);
  }
}
