import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';

import { Observable } from 'rxjs';

import { Dashboard } from '../../../models';
import { DashboardsApiService } from '../../../services/api-services';

export interface DashboardUpsertDialogState {
  dashboards: Dashboard[];
}

@Injectable()
export class DashboardUpsertDialogStore extends ComponentStore<DashboardUpsertDialogState> implements OnStoreInit {
  public static readonly DEFAULT_STATE: DashboardUpsertDialogState = { dashboards: [] };

  public readonly dashboards$: Observable<Dashboard[]> = this.select((state) => state.dashboards);

  constructor(private readonly dashboardsApiService: DashboardsApiService) {
    super(DashboardUpsertDialogStore.DEFAULT_STATE);
  }

  public ngrxOnStoreInit(): void {
    this.dashboardsApiService.listCoreDashboards().subscribe((res) => {
      this.patchState({ dashboards: res.data });
    });
  }
}
