import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  ApplicationApiDefinition,
  ClientDataRequestSettings,
  LicenseeSettings,
  LicenseeSettingsRequest,
} from '../../../models';

import { ApiService } from '../../common/api/api.service';
import { BaseClientLicenseeSettingsService } from './base-client-licensee-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ClientLicenseeSettingsService extends BaseClientLicenseeSettingsService {
  private collectApiName: keyof ApplicationApiDefinition = 'collect';
  private collectResource: string;
  private collectServicePath: string;

  constructor(private readonly apiService: ApiService) {
    super();

    this.collectResource = apiService.getServicePath(this.collectApiName);
    this.collectServicePath = this.apiService.apiConfig.apis.collect.resources.licenseeSettings;
  }

  public getLicenseeSettings(): Observable<ApiResponse<LicenseeSettings>> {
    return this.apiService.get(`${this.collectResource}${this.collectServicePath}/settings`).pipe(
      map((response: ApiResponse<LicenseeSettingsRequest>) => ({
        ...response,
        data: {
          language: response.data.language,
          login_lock_time: response.data.login_lock_time,
          logout_inactivity_time: response.data.logout_inactivity_time,
          max_login_attempts: response.data.max_login_attempts,
          warn_inactivity_time: response.data.warn_inactivity_time,
          enforce_2fa: response.data.enforce_2fa,
          basic_file_extensions: response.data.basic_file_extensions,
          risky_file_extensions: response.data.risky_file_extensions,
          allow_risky_extensions_platform: response.data.allow_risky_extensions_platform,
          allow_risky_extensions_public: response.data.allow_risky_extensions_public,
          password_reuse_rule_enabled: response.data.password_reuse_rule_enabled,
          password_reuse_max_number: response.data.password_reuse_max_number,
          password_reuse_max_age_months: response.data.password_reuse_max_age_months,
          password_expiration_days: response.data.password_expiration_days,
          default_dashboard_id: response.data.default_dashboard_id,
          allow_shared_dashboards: response.data.allow_shared_dashboards,
        },
      })),
    );
  }

  public updateLicenseeSettings(body: LicenseeSettings): Observable<ApiResponse<LicenseeSettings>> {
    return this.apiService.put(`${this.collectResource}${this.collectServicePath}/settings`, body).pipe(
      map((response: ApiResponse<LicenseeSettingsRequest>) => ({
        ...response,
        data: {
          language: response.data.language,
          login_lock_time: response.data.login_lock_time,
          logout_inactivity_time: response.data.logout_inactivity_time,
          max_login_attempts: response.data.max_login_attempts,
          warn_inactivity_time: response.data.warn_inactivity_time,
          enforce_2fa: response.data.enforce_2fa,
          basic_file_extensions: response.data.basic_file_extensions,
          risky_file_extensions: response.data.risky_file_extensions,
          allow_risky_extensions_platform: response.data.allow_risky_extensions_platform,
          allow_risky_extensions_public: response.data.allow_risky_extensions_public,
          password_reuse_rule_enabled: response.data.password_reuse_rule_enabled,
          password_reuse_max_number: response.data.password_reuse_max_number,
          password_reuse_max_age_months: response.data.password_reuse_max_age_months,
          password_expiration_days: response.data.password_expiration_days,
          default_dashboard_id: response.data.default_dashboard_id,
          allow_shared_dashboards: response.data.allow_shared_dashboards,
        },
      })),
    );
  }

  public getClientDataRequestSettings(): Observable<ApiResponse<ClientDataRequestSettings>> {
    return this.apiService.get(`${this.collectResource}${this.collectServicePath}/data_requests_settings`);
  }

  public updateClientDataRequestSettings(
    body: ClientDataRequestSettings,
  ): Observable<ApiResponse<ClientDataRequestSettings>> {
    return this.apiService.post(`${this.collectResource}${this.collectServicePath}/data_requests_settings`, body);
  }
}
