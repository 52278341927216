import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  DashboardWidget,
  DashboardWidgetValuesRequest,
  Value,
} from '../../../models';
import { ApiService } from '../../common';
import { EMPTY, Observable } from 'rxjs';
import { DashboardWidgetsApiService } from '../../api-services';

@Injectable({ providedIn: 'root' })
export class ClientPublicDashboardWidgetsService extends DashboardWidgetsApiService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.dashboards;
  }

  public getWidgetValues(
    dashboardId: string,
    dashboardWidgetId: string,
    request: DashboardWidgetValuesRequest,
  ): Observable<ApiResponse<Value[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/dashboard_widgets/${dashboardWidgetId}/values`,
      request,
    );
  }

  public createWidget(): Observable<ApiResponse<DashboardWidget>> {
    return EMPTY;
  }

  public deleteWidget(): Observable<undefined> {
    return EMPTY;
  }

  public updateDashboardPlot(): Observable<ApiResponse<DashboardWidget[]>> {
    return EMPTY;
  }

  public updateWidget(): Observable<ApiResponse<DashboardWidget>> {
    return EMPTY;
  }
}
