import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, startWith, take, tap } from 'rxjs/operators';

import { RequireMatch } from '../../classes';
import {
  ActionItem,
  DefaultSearchOptions,
  ItemType,
  SearchBarFilterResourceArgs,
  SearchBarFilterResourceType,
  SearchFilterConfig,
  SearchOptionFilters,
  SearchOptions,
  Source,
  SOURCE_CONFIGURATION,
  DEFAULT_SOURCE_CONFIGURATION,
} from '../../models';
import { TranslateService } from '../../services/common';
import { SearchService } from '../services/search.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'lib-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() itemType: ItemType | undefined;
  @Input() searchFilters: string = '';
  @Input() mode: 'nav' | 'drag' | 'select' = 'nav';
  @Input() customFilters?: Record<string, string | string[] | boolean | undefined>;
  @Input() customProperties?: Record<string, string | undefined>;
  @Input() lockedFilters?: { action: 'lock' | 'hide'; filters: { [field: string]: ActionItem } };
  @Input() externalFilters: ActionItem[] = [];
  @Input() sortOrder?: ActionItem;
  @Input() searchPlaceholder: string = this.translateService.instant('Search');
  @Input() filterArgs: SearchBarFilterResourceArgs = {};
  @Input() searchFilterConfig? = SearchFilterConfig;
  @Input() sourceConfiguration: SOURCE_CONFIGURATION = DEFAULT_SOURCE_CONFIGURATION;
  @Input() isPublic: boolean = false;

  @Output() searchOptionsChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('calendarOverlay') calendarOverlay?: OverlayPanel;

  // TODO: get current open fiscal year instead
  currentYear = new Date().getFullYear().toString();

  searchOptions: SearchOptions;
  initialQuery: string = '';
  initialFilters: SearchOptionFilters = {};
  initialSort?: ActionItem | undefined;
  minMenuScrollItems: number = 10;

  filters: {
    [key: string]: {
      items: ActionItem[];
      filteredItems?: Observable<ActionItem[]>;
      control?: UntypedFormControl;
    };
  } = {};

  searchQueryChanged: Subject<{ value: string; debounceTime: number }> = new Subject();
  readonly eSearchBarFilterResourceType = SearchBarFilterResourceType;

  private readonly destroy$: Subject<void> = new Subject<void>();
  readonly allSourcesItem: ActionItem<Source> = {
    id: '',
    title: this.translateService.instant('All'),
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private translateService: TranslateService,
  ) {
    this.searchOptions = DefaultSearchOptions;
    this.registerItems(
      [
        SearchBarFilterResourceType.start,
        SearchBarFilterResourceType.role,
        SearchBarFilterResourceType.year,
        SearchBarFilterResourceType.framework,
        SearchBarFilterResourceType.questionnaire_framework,
        SearchBarFilterResourceType.status,
        SearchBarFilterResourceType.author,
        SearchBarFilterResourceType.sort,
      ],
      false,
    );
    this.registerItems(
      [
        SearchBarFilterResourceType.source,
        SearchBarFilterResourceType.topic,
        SearchBarFilterResourceType.category,
        SearchBarFilterResourceType.type,
        SearchBarFilterResourceType.industry,
        SearchBarFilterResourceType.tag,
        SearchBarFilterResourceType.admin_standard_codes,
        SearchBarFilterResourceType.standard_codes,
        SearchBarFilterResourceType.taxonomy,
        SearchBarFilterResourceType.non_favorite_taxonomy,
      ],
      true,
    );
  }

  public get filterCount(): number {
    let count = Object.keys(this.searchOptions.filters).filter(
      (key) => !(this.lockedFilters?.action === 'lock' && Object.keys(this.lockedFilters).includes(key)),
    ).length;
    if (this.searchOptions.query.keywords) {
      if (this.searchOptions.sort?.id != this.filters.sort.items[0]?.id) {
        count++;
      }
    } else if (this.searchOptions.sort?.id != this.filters.sort.items[1]?.id) {
      count++;
    }
    return count;
  }

  public get currentFiscalYear(): string {
    // TODO: get active fiscal year instead of current calendar year
    return new Date().getFullYear().toString();
  }

  // Register items and controls

  registerItems(keys: string[], registerControl: boolean): void {
    for (const key of keys) {
      this.registerItem(key, registerControl);
    }
  }

  registerItem(key: string, registerControl: boolean): void {
    this.filters[key] = {
      items: [],
    };
    if (registerControl) {
      this.filters[key].control = new UntypedFormControl('', [RequireMatch]);
      this.filters[key].filteredItems = this.filters[key].control?.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : (value as ActionItem).title)),
        map((value) => this.searchService.filterItems(this.filters[key].items, value)),
      );
    }
  }

  // Lifecycle events
  ngOnInit(): void {
    this.searchOptions.item_type = this.itemType;
    this.initialSort = this.sortOrder;
    this.route.queryParams.pipe(take(1)).subscribe((queryParams) => {
      // Find the query params only once on component load after receiving the query data.
      if (this.mode === 'nav') {
        if (queryParams.q) {
          this.initialQuery = String(queryParams.q);
        }
        if (queryParams.f) {
          try {
            this.initialFilters = JSON.parse(
              decodeURIComponent(escape(atob(String(queryParams.f)))),
            ) as SearchOptionFilters;
          } catch {
            // continue regardless of error
          }
        }
        if (queryParams.s) {
          try {
            this.initialSort = JSON.parse(decodeURIComponent(escape(atob(String(queryParams.s))))) as ActionItem;
          } catch {
            // continue regardless of error
          }
        }
      }
      this.initSearchOptions();
    });

    this.searchQueryChanged
      .pipe(
        debounce((queryData: { value: string; debounceTime: number }) => timer(queryData.debounceTime)),
        distinctUntilChanged(),
        tap((queryData) => {
          this.searchOptions.query.keywords = queryData.value;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.search());
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  // Initialize items and filters
  initSearchOptions(): void {
    this.searchOptions = DefaultSearchOptions;
    this.searchOptions.query.keywords = this.initialQuery;
    this.searchOptions.filters = this.initialFilters;
    this.searchOptions.filter_args = this.filterArgs;
    this.searchOptions.item_type = this.itemType;
    this.searchOptions.custom_filters = this.customFilters;
    this.searchOptions.custom_properties = this.customProperties;
    this.searchOptions.sort = this.initialSort;
    if (this.lockedFilters?.filters && this.lockedFilters.action === 'lock') {
      Object.keys(this.lockedFilters.filters).forEach((key) => {
        this.initialFilters[key] = this.lockedFilters?.filters[key];
      });
    }
    this.initFilters();
  }

  initItem(key: string): void {
    this.filters[key].items = [];
    if (this.filters[key].control) {
      this.filters[key].control?.setValue('');
    }
  }

  initFilters(): void {
    this.searchOptions.query.keywords = this.initialQuery;
    this.resetFilters(true);

    // load regular filters
    Object.values(SearchBarFilterResourceType).forEach((item) => {
      this.initItem(item);
      if (this.itemType && this.searchFilterConfig && this.searchFilterConfig[this.itemType][item]) {
        this.searchService
          .searchResources(item, this.itemType, undefined, this.filterArgs, this.isPublic)
          .subscribe((items) => {
            this.filters[item].items = items;
          });
      }
    });
    this.searchService.searchResources('sort', this.itemType).subscribe((items) => {
      this.filters.sort.items = items;
      if (!this.searchOptions.sort) {
        this.searchOptions.sort = this.searchOptions.query.keywords
          ? this.filters.sort.items[0]
          : this.filters.sort.items[1];
      }
    });

    // load external filters
    this.externalFilters.forEach((filter) => {
      if (filter.children) {
        this.registerItem(filter.id, false);
        this.filters[filter.id].items = filter.children;
      }
    });
  }

  resetFilters(initial: boolean = false): void {
    for (const key of [
      'start',
      'role',
      'topic',
      'category',
      'type',
      'industry',
      'source',
      'tag',
      'admin_standard_codes',
      'standard_codes',
      'taxonomy',
    ]) {
      this.filters[key].control?.setValue('');
    }
    this.searchOptions.filters = initial ? this.initialFilters : {};
    this.initialFilters = {};
    this.initialSort = undefined;
    this.emitChanges();
  }

  // Set items and emit changes

  emitChanges(): void {
    let s: string | null = null;
    if (this.searchOptions.sort) {
      if (this.searchOptions.query.keywords) {
        if (this.searchOptions.sort != this.filters.sort.items[0]) {
          s = btoa(unescape(encodeURIComponent(JSON.stringify(this.searchOptions.sort))));
        }
      } else {
        if (this.searchOptions.sort != this.filters.sort.items[1]) {
          s = btoa(unescape(encodeURIComponent(JSON.stringify(this.searchOptions.sort))));
        }
      }
    }
    if (this.mode === 'nav') {
      const queryParams = {
        q: this.searchOptions.query.keywords || null,
        f: Object.keys(this.searchOptions.filters).length
          ? btoa(unescape(encodeURIComponent(JSON.stringify(this.searchOptions.filters))))
          : null,
        s,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
    this.searchOptionsChange.emit(JSON.stringify(this.searchOptions));
  }

  search(): void {
    const defaultSearchOrder = this.sortOrder ?? this.filters.sort.items[1];
    this.searchOptions.sort = this.searchOptions.query.keywords ? this.filters.sort.items[0] : defaultSearchOrder;
    this.emitChanges();
  }

  clearSearch(): void {
    this.initialQuery = '';
    this.searchQueryChanged.next({
      value: '',
      debounceTime: 0,
    });
  }

  setItem(key: keyof SearchOptionFilters, item?: ActionItem): void {
    if (item && item.id) {
      this.searchOptions.filters[key] = item;
    } else {
      delete this.searchOptions.filters[key];
    }
    this.filters[key].control?.setValue('');
    this.emitChanges();
  }

  resetItem(key: keyof SearchOptionFilters): void {
    this.filters[key].control?.setValue('');
    this.applyItem(key);
  }

  resetItems(keys: (keyof SearchOptionFilters)[]): void {
    for (const key of keys) {
      this.filters[key].control?.setValue('');
    }
    this.applyItems(keys);
  }

  applyItem(key: keyof SearchOptionFilters, event?: MouseEvent): void {
    if (event) {
      if (this.filters[key].control?.invalid) {
        event.stopPropagation();
      }
    }
    const val = this.filters[key].control?.value as string | ActionItem;
    if (val === '') {
      delete this.searchOptions.filters[key];
      this.emitChanges();
    } else if (val && typeof val === 'object' && val.id) {
      this.searchOptions.filters[key] = val;
      this.emitChanges();
    }
  }

  applyItems(keys: (keyof SearchOptionFilters)[], event?: MouseEvent): void {
    if (event) {
      for (const key of keys) {
        if (this.filters[key].control?.invalid) {
          event.stopPropagation();
        }
      }
    }
    keys.forEach((key: keyof SearchOptionFilters) => {
      const val = this.filters[key].control?.value as string | ActionItem;
      if (val === '') {
        delete this.searchOptions.filters[key];
      } else if (val && typeof val === 'object' && val.id) {
        this.searchOptions.filters[key] = val;
      }
    });

    this.emitChanges();
  }
  applySort(item: ActionItem): void {
    if (item.id !== this.searchOptions.sort?.id) {
      this.searchOptions.sort = item;
      this.emitChanges();
    }
  }

  onSearchQueryChanged(query: string) {
    this.searchQueryChanged.next({
      value: query,
      debounceTime: 500,
    });
  }

  openDatePicker(event: Event): void {
    this.calendarOverlay?.toggle(event);
  }

  onDateSelect(date: Date): void {
    this.setItem('start', { id: 'start', title: 'Start Date', item: date });
    this.calendarOverlay?.toggle(date);
  }
}
