import { Injectable } from '@angular/core';
import { filter, finalize, Observable, switchMap, take, tap } from 'rxjs';
import { ApiResponse, Metric, Status, ToastStatus, ValueDefinition, ValueDefinitionType } from '../../../models';
import { TranslateService } from '../../../services/common';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../metric-structure-state.service';
import { MetricTableGroup } from '../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { ObservableUtils } from '../../../classes';
import { ToastService } from '../../../components';

@Injectable()
export class ActivateEntityService {
  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private dialogsService: DialogsService,
  ) {}

  activateValueDefinition(metricId: string, valueDefinition: ValueDefinition): Observable<ApiResponse<Metric>> {
    if (valueDefinition.type === ValueDefinitionType.calculated) {
      return this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Activate field'),
            warningMsg: this.translateService.instant(
              'If this metric has been marked as complete in an open fiscal year, the status will be set back to in progress as a result of modifying the calculated field.',
            ),
            primaryBtn: this.translateService.instant('Confirm'),
          },
        })
        .afterClosed()
        .pipe(
          ObservableUtils.filterNullish(),
          filter((result) => result.status === Status.CONFIRMED),
          switchMap(() =>
            this.metricsService
              .activateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
              .pipe(
                tap((response) => {
                  this.metricStructureService.updateMetric(response.data);
                  this.metricStructureService.updateSelectedItem({
                    ...valueDefinition,
                    active: true,
                    end_fiscal_year_frequency_code: undefined,
                  });
                  this.toastService.open(ToastStatus.SUCCESS, this.translateService.instant('Field reactivated'));
                }),
              ),
          ),
        );
    } else {
      const ignoreTaxonomy: boolean = Boolean(
        valueDefinition.core_value_definition_id &&
          valueDefinition.hidden_by_taxonomy &&
          !valueDefinition.ignore_taxonomies,
      );
      return this.metricsService
        .activateValueDefinition(
          metricId,
          valueDefinition.value_definition_group_id,
          valueDefinition.id,
          ignoreTaxonomy,
        )
        .pipe(
          tap((response) => {
            this.metricStructureService.updateMetric(response.data);
            this.metricStructureService.updateSelectedItem({
              ...valueDefinition,
              active: true,
              end_fiscal_year_frequency_code: undefined,
              ignore_taxonomies: ignoreTaxonomy || valueDefinition.ignore_taxonomies,
            });
            this.toastService.open(ToastStatus.SUCCESS, this.translateService.instant('Field reactivated'));
          }),
        );
    }
  }

  activateMetricTable(metricTableGroup: MetricTableGroup): void {
    this.metricStructureService.updateIsMetricUpdating(true);
    const ignoreTaxonomy: boolean = Boolean(
      metricTableGroup.core_metric_table_definition_id &&
        metricTableGroup.hidden_by_taxonomy &&
        !metricTableGroup.ignore_taxonomies,
    );
    this.metricsService
      .activateMetricTable(metricTableGroup.metric_id, metricTableGroup.table_id, ignoreTaxonomy)
      .pipe(
        take(1),
        finalize(() => this.metricStructureService.updateIsMetricUpdating(false)),
      )
      .subscribe((metricResponse) => {
        this.metricStructureService.updateMetric(metricResponse.data);

        this.metricStructureService.updateSelectedItem({
          ...metricTableGroup,
          active: true,
          end_fiscal_year_frequency_code: undefined,
          ignore_taxonomies: ignoreTaxonomy || metricTableGroup.ignore_taxonomies,
        });

        this.metricStructureService.updateMetricTableUpdated();

        this.toastService.open(ToastStatus.SUCCESS, this.translateService.instant('Metric table activated'));
      });
  }

  activateMetric(metricId: string): void {
    this.metricStructureService.updateIsMetricUpdating(true);
    this.metricsService
      .activateMetric(metricId)
      .pipe(
        take(1),
        finalize(() => {
          this.metricStructureService.updateIsMetricUpdating(false);
        }),
      )
      .subscribe((result) => {
        this.metricStructureService.updateMetric(result.data);
      });
  }
}
