<lib-text-input
  [control]="valueFormControl"
  [hint]="hint"
  [label]="label"
  [placeholder]="placeholder"
  [size]="size"
  [maxCharacterCount]="maxLength"
  (libBeforeUnload)="updateValue()"
  [trimWhitespaceMethod]="TrimMethod.none"
></lib-text-input>
