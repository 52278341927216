@if (control) {
  <div libFormField [size]="size" [required]="required" [control]="control" [labelPosition]="labelPosition">
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <mat-form-field appearance="fill">
      <textarea
        cdkTextareaAutosize
        matInput
        #input
        [id]="_inputId"
        [placeholder]="placeholder"
        [formControl]="inputControl"
        [matAutocomplete]="autocomplete"
        (blur)="onBlur($event)"
      ></textarea>
      <mat-autocomplete
        #autocomplete="matAutocomplete"
        (optionSelected)="selectOption($event.option)"
        aria-label="autocomplete"
        [autoActiveFirstOption]="true"
      >
        @if (isLoadingOptions) {
          <mat-option class="loading-options">
            <lib-progress-bar [mode]="ProgressBarMode.INDETERMINATE" [showValue]="false"></lib-progress-bar>
          </mat-option>
        }
        @if (!isLoadingOptions) {
          @for (item of filteredOptions$ | async; track item) {
            <mat-option [value]="getOptionValue(item)">
              {{ getOptionLabel(item) }}
            </mat-option>
          }
        }
      </mat-autocomplete>
      <mat-hint>
        @if ((control.untouched || control.valid) && hint) {
          {{ hint }}
        }
        @if (control.touched && control.invalid) {
          @for (error of control.errors | errorKeys; track error) {
            <span class="color-error"> {{ errorMessages[error] }}</span>
          }
        }
      </mat-hint>
    </mat-form-field>
  </div>
}
