<p-sidebar
  [autoZIndex]="!zIndex"
  [baseZIndex]="900"
  [style]="zIndex ? { 'z-index': zIndex } : {}"
  [dismissible]="dismissible"
  [closeOnEscape]="dismissible"
  [styleClass]="'p-sidebar-' + size"
  [showCloseIcon]="false"
  position="right"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
>
  <ng-template pTemplate="header">
    <h3 class="m-0">{{ title }}</h3>

    @if (ctaBtn) {
      <button lib-button class="ml-auto mr-2" buttonType="primary" (click)="ctaClick.emit()">{{ ctaBtn }}</button>
    }

    <button
      lib-button
      class="close-btn"
      [ngClass]="{ 'ml-auto': !ctaBtn }"
      buttonType="icon"
      (click)="visibleChange.emit(false)"
    >
      <i class="pi pi-times"></i>
    </button>
  </ng-template>

  <ng-template pTemplate="content">
    <ng-content></ng-content>
  </ng-template>

  @if (secondaryBtn || primaryBtn) {
    <ng-template pTemplate="footer">
      @if (secondaryBtn) {
        <button lib-button buttonType="cancel" (click)="secondaryClick.emit()">
          {{ secondaryBtn }}
        </button>
      }
      @if (primaryBtn) {
        <button
          lib-button
          buttonType="primary"
          class="ml-2"
          [disabled]="primaryBtnDisabled | boolean"
          [isLoading]="primaryBtnLoading | boolean"
          (click)="primaryClick.emit()"
        >
          {{ primaryBtn }}
        </button>
      }

      <ng-content select="footerContent"></ng-content>
    </ng-template>
  }
</p-sidebar>
