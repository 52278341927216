@if (data$ | async; as data) {
  @if (options.withContextBar) {
    <lib-page-context-bar [withSeparator]="true" [pageName]="data.dashboard.name">
      @if (options.withMainActions) {
        <ng-container main-actions>
          @if (data.isEditing) {
            <button lib-button buttonType="primary" (click)="onEditDashboard(false)">
              {{ "Done" | translate }}
            </button>
          } @else if (data.isOwner) {
            <button lib-button buttonType="cancel_with_border" (click)="onEditDashboard(true)">
              <mat-icon class="icon-sm mr-1" svgIcon="edit"></mat-icon>
              {{ "Edit" | translate }}
            </button>

            @if (options.withShareAction) {
              <button lib-button class="ml-2" buttonType="primary" (click)="share.emit(data.dashboard)">
                <mat-icon
                  class="icon-sm mr-1"
                  [svgIcon]="eDashboardPublicVisibilityToIcon[data.dashboard.visibility || '']"
                ></mat-icon>
                {{ "Share" | translate }}
              </button>
            }
          }

          <lib-popup-menu
            class="popup-menu"
            [icon]="'bars'"
            [menuItems]="[
              { id: eAction.copy, icon: 'copy', label: 'Copy' | translate },
              { id: eAction.rename, icon: 'pencil', label: 'Rename' | translate },
              { id: eAction.delete, icon: 'trash', label: 'Delete' | translate },
            ]"
            (action)="onMenuClick($event.id, data.dashboard)"
          ></lib-popup-menu>
        </ng-container>
      }
    </lib-page-context-bar>
  }

  <div class="h-100 w-100" [ngClass]="{ 'page-container': data.isEditing }">
    @if (data.isEditing) {
      <div class="left-container">
        <h4>{{ "Add widget" | translate }}</h4>
        @for (dashboardWidgetType of dashboardWidgetTypes; track dashboardWidgetType) {
          <div class="widget-card" [attr.data-testid]="'card' + eDashboardWidgetTypeToText[dashboardWidgetType]">
            <div class="title">
              <mat-icon [svgIcon]="eDashboardWidgetTypeToIcon[dashboardWidgetType]"></mat-icon>
              <div>{{ eDashboardWidgetTypeToText[dashboardWidgetType] }}</div>
            </div>

            <button
              lib-button
              buttonType="stroked_light"
              (click)="onCreateWidget(data.dashboard, dashboardWidgetType)"
              [disabled]="
                !({ dashboardWidgets: data.dashboardWidgets, dashboardWidgetType } | dashboardHasAvailableSpace)
              "
            >
              {{ "Add" | translate }}
            </button>
          </div>
        }
      </div>
    }

    <main class="main">
      @if (options.withFilters) {
        <div class="filters-container">
          <ng-content></ng-content>
        </div>
      }

      <div class="dashboard-container" [ngClass]="{ 'with-filters': options.withFilters }">
        <lib-dashboard
          class="h-100 w-100"
          [isEditing]="data.isEditing"
          [gridsterConfig]="data.isEditing ? editGridsterConfig : viewGridsterConfig"
          [widgets]="data.dashboardWidgets"
          [options]="dashboardOptions"
          (edit)="onEditWidget($event)"
          (itemChange)="onWidgetPlot($event)"
          (remove)="onDeleteWidget(data.dashboardWidgets, $event)"
        >
        </lib-dashboard>

        @if (data.isEditing) {
          <div class="placeholder-grid">
            @for (i of [].constructor(12); track index; let index = $index) {
              <div>{{ "Insert Widget from left side panel" | translate }}</div>
            }
          </div>
        }
      </div>
    </main>
  </div>

  @if (data.sidebarData) {
    <lib-dashboard-widget-upsert-sidebar
      [options]="options"
      [sidebarData]="data.sidebarData"
      (save)="onSaveWidget($event, data.dashboardWidgets, data.sidebarData.dashboardWidget)"
    ></lib-dashboard-widget-upsert-sidebar>
  }
}
