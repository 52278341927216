@if (control) {
  @if (label) {
    <lib-form-field-label
      [label]="label"
      [control]="control"
      [for]="inputId"
      [fieldInfo]="fieldInfo"
      [ngClass]="{ 'big-padding': bigPaddingField }"
    >
    </lib-form-field-label>
  }

  <ng-content>
    <!--
      This input is for testing purpose in storybook.
      This component should always receive something in content projection
     -->
    <input [id]="inputId" type="text" pInputText [formControl]="control" />
  </ng-content>

  @if ((hint || maxCharacterCount) && !control.errors) {
    <div class="fx-row justify-space-between align-baseline input-hint">
      @if (hint) {
        <span [ngClass]="{ 'input-disabled': control.disabled }">{{ hint }}</span>
      }

      @if (maxCharacterCount) {
        <span> {{ control.value?.length ?? 0 }}/{{ maxCharacterCount }}</span>
      }
    </div>
  }

  @if (control.errors) {
    <div class="input-error">
      @for (error of control.errors | errorKeys; track error; let count = $count) {
        @if (count <= 1) {
          <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{ $implicit: error }">
          </ng-container>
        } @else {
          <li class="input-error-list">
            <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{ $implicit: error }">
            </ng-container>
          </li>
        }
      }
    </div>
  }
} @else {
  @if (label) {
    <lib-form-field-label
      [label]="label"
      [for]="inputId"
      [fieldInfo]="fieldInfo"
      [ngClass]="{ 'big-padding': bigPaddingField }"
    >
    </lib-form-field-label>
  }

  <ng-content select="no-control">
    <!--
      This input is for testing purpose in storybook.
      This component should always receive something in content projection
     -->
    <input [id]="inputId" type="text" pInputText />
  </ng-content>

  @if (hint || maxCharacterCount) {
    <div class="fx-row justify-space-between align-baseline input-hint">
      @if (hint) {
        <span>{{ hint }}</span>
      }
    </div>
  }
}

<ng-template #errorMessage let-error>
  {{ errorMessages[error] }}
  {{
    error === "maxlength" && maxCharacterCount
      ? ("({length}/{maxLength})" | translate: { length: control?.value?.length ?? 0, maxLength: maxCharacterCount })
      : ""
  }}
</ng-template>
