import { Pipe, PipeTransform } from '@angular/core';
import { IndicatorCommentDetails } from '../../../models';

@Pipe({
  name: 'filterFieldComments',
})
export class FilterFieldCommentsPipe implements PipeTransform {
  transform(
    fieldComments?: IndicatorCommentDetails[],
    valueGroupId?: string,
    valueDefinitionId?: string,
  ): IndicatorCommentDetails[] {
    return (
      fieldComments?.filter(
        (comment) =>
          comment.data_request_value_group_id == valueGroupId && comment.value_definition_id == valueDefinitionId,
      ) ?? []
    );
  }
}
