import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ValidationMessageService } from '../../../services/common';

@Component({
  selector: 'lib-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
})
export class InputContainerComponent implements OnChanges {
  @Input() control?: UntypedFormControl;
  @Input() label?: string;
  @Input() hint?: string;
  @Input() messages: ValidationErrors = {};
  @Input() maxCharacterCount?: number;
  @Input() fieldInfo?: string;
  @Input() inputId: string = uuidv4();
  @Input() bigPaddingField: boolean = false;

  isRequired: boolean = false;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.isRequired = !!this.control?.hasValidator(Validators.required);
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }
}
