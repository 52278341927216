import { ModuleWithProviders, NgModule } from '@angular/core';
// Angular
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// SyncFusion
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { GridModule } from '@syncfusion/ej2-angular-grids';
// PDF
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXdeeXRcR2RcWU1wWEo=');
import { AppConfig } from './models';

import { IconsModule } from './icons/icons.module';
import { MaterialModule } from './material/material.module';
import { MetricStructureModule } from './metric-structure';
import { ComponentsModule } from './components';
import { PipesModule } from './pipes';
import { DirectivesModule } from './directives';
import { CdkModule } from './material/cdk.module';
import { DataListModule } from './data-list';
import { DataTableModule } from './data-table';
import { NumberMaskModule } from './number-mask';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SearchModule } from './search';
import { MetricsModule } from './metrics';
import { PanelsModule } from './panels';
import { TagsMultiSelectInputModule } from './tags-multi-select-input';
import { DialogsModule } from './dialogs';
import { SectionsModule } from './sections';
import { FeatureFlagModule } from './feature-flag';
import { LayoutModule } from './layout';
import { MetricPreviewModule } from './metric-preview/metric-preview.module';
import { NavigationModule } from './navigation';
import { LoaderModule } from './loader';
import { ChartsModule } from './charts';
import { TemplateReportStructureModule } from './template-report-structure/template-report-structure.module';
import { MetricSearchModule } from './metric-search';
import { SelectMetricValueDefinitionModule } from './select-metric-value-definition';
import { PasswordConfirmationModule } from './password-confirmation';
import { LocaleModule } from './modules/locale.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { MetricEditorFormModule } from './metric-editor-form';
import { CurtainModule } from './curtain';
import { TablesModule } from './tables/tables.module';
import { OptionListsModule } from './option-lists/option-lists.module';
import { WebsocketModule } from './websocket';

@NgModule({
  declarations: [],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    // SyncFusion
    RichTextEditorModule,
    GridModule,
    // PDF
    NgxExtendedPdfViewerModule,
    MaterialModule,
    IconsModule,
    CdkModule,
    MetricEditorFormModule,
    MetricStructureModule,
    MetricPreviewModule,
    DataListModule,
    DataTableModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    NumberMaskModule,
    SearchModule,
    MetricsModule,
    PanelsModule,
    TagsMultiSelectInputModule,
    DialogsModule,
    SectionsModule,
    FeatureFlagModule,
    LayoutModule,
    NavigationModule,
    LoaderModule,
    TemplateReportStructureModule,
    ChartsModule,
    MetricSearchModule,
    SelectMetricValueDefinitionModule,
    DashboardsModule,
    PasswordConfirmationModule,
    CurtainModule,
    TablesModule,
    OptionListsModule,
    WebsocketModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // SyncFusion
    RichTextEditorModule,
    GridModule,
    // PDF
    NgxExtendedPdfViewerModule,
    IconsModule,
    CdkModule,
    MaterialModule,
    ComponentsModule,
    TagsMultiSelectInputModule,
    PipesModule,
    DirectivesModule,
    NumberMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    LoaderModule,
    ChartsModule,
    LocaleModule,
    CurtainModule,
  ],
  providers: [provideNgxMask(), DecimalPipe, provideHttpClient(withInterceptorsFromDi())],
})
export class NovistoCommonModule {
  static forRoot(config: AppConfig): ModuleWithProviders<NovistoCommonModule> {
    return {
      ngModule: NovistoCommonModule,
      providers: [{ provide: AppConfig, useValue: config }, DatePipe],
    };
  }
}
