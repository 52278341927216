import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { combineLatestWith } from 'rxjs/operators';
import { EMPTY, Observable, switchMap } from 'rxjs';

import { ApiResponse, DashboardDatum, PaginationState } from '../../models';
import { DashboardDatumsApiService } from '../../services/api-services/dashboard-datums-api-service/dashboard-datums-api.service';
import { DEFAULT_PAGE_SIZE } from '../../data-table';

export interface FilterState {
  searchQuery: string;
}

export interface DashboardDatumsSidebarState {
  filterState: FilterState;
  isLoading: boolean;
  dashboardDatums: DashboardDatum[];
  paginationState: PaginationState;
  total: number;
}

@Injectable()
export class DashboardDatumsSidebarStore extends ComponentStore<DashboardDatumsSidebarState> implements OnStoreInit {
  public static readonly DEFAULT_STATE: DashboardDatumsSidebarState = {
    filterState: { searchQuery: '' },
    isLoading: true,
    dashboardDatums: [],
    paginationState: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE },
    total: 0,
  };

  public readonly filterState$: Observable<FilterState> = this.select((state) => state.filterState);
  public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);
  public readonly dashboardDatums$: Observable<DashboardDatum[]> = this.select((state) => state.dashboardDatums);
  public readonly paginationState$: Observable<PaginationState> = this.select((state) => state.paginationState);
  public readonly total$: Observable<number> = this.select((state) => state.total);

  constructor(private readonly dashboardDatumsApiService: DashboardDatumsApiService) {
    super(DashboardDatumsSidebarStore.DEFAULT_STATE);
  }

  public ngrxOnStoreInit(): void {
    this.fetchDashboardDatums();
  }

  public readonly updateSearchQueryState = this.updater(
    (state: DashboardDatumsSidebarState, searchQuery: string): DashboardDatumsSidebarState => ({
      ...DashboardDatumsSidebarStore.DEFAULT_STATE,
      isLoading: true,
      filterState: { ...state.filterState, searchQuery },
    }),
  );

  public readonly updatePaginationState = this.updater(
    (state: DashboardDatumsSidebarState, paginationState: Partial<PaginationState>): DashboardDatumsSidebarState => ({
      ...state,
      paginationState: {
        currentPage: paginationState.currentPage ?? state.paginationState.currentPage,
        pageSize: paginationState.pageSize ?? state.paginationState.pageSize,
      },
    }),
  );

  private readonly updateDashboardDatumsState = this.updater(
    (state: DashboardDatumsSidebarState, res: ApiResponse<DashboardDatum[]>): DashboardDatumsSidebarState => ({
      ...state,
      dashboardDatums: res.data,
      total: res.meta.total_count ?? 0,
    }),
  );

  public readonly fetchDashboardDatums = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.paginationState$, this.filterState$),
      switchMap(([_, paginationState, filterState]) =>
        this.dashboardDatumsApiService.listDashboardDatums(
          filterState.searchQuery,
          paginationState.currentPage,
          paginationState.pageSize,
        ),
      ),
      tapResponse(
        (apiResponse: ApiResponse<DashboardDatum[]>) => {
          this.updateDashboardDatumsState(apiResponse);
          this.patchState({ isLoading: false });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );
}
