@if (form) {
  <lib-text-input
    class="formfield"
    [control]="form.controls.name"
    [label]="'Name' | translate"
    [messages]="{ isUnique: 'Name must be unique.' | translate }"
  ></lib-text-input>
  @if (withTagFields) {
    <lib-text-input
      class="formfield"
      [control]="form.controls.spDataDpdValue"
      [label]="'API Tag - S&P' | translate"
    ></lib-text-input>
    <lib-text-input
      class="formfield"
      [control]="form.controls.cdpOptionId"
      [label]="'API Tag - CDP' | translate"
    ></lib-text-input>
  }
  <lib-switch
    class="formfield mb-3"
    [control]="form.controls.displayExplanation"
    [switchLabel]="'Show explanation field' | translate"
  ></lib-switch>
  <lib-switch
    class="formfield"
    [control]="form.controls.explanationRequired"
    [switchLabel]="'Set explanation field to required' | translate"
  ></lib-switch>
}
