import {
  ActionItem,
  ItemType,
  Layout,
  Permission,
  Presentation,
  SearchBarFilterResourceArgs,
  SOURCE_CONFIGURATION,
  DEFAULT_SOURCE_CONFIGURATION,
  DummySkeletonType,
  PresentationV2,
} from '../../models';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input({ required: true }) itemType!: ItemType;
  @Input({ required: true }) layout!: Layout;
  @Input({ required: true }) presentation!: Presentation;
  @Input() grid: string = '';
  @Input() filters?: Record<string, string | string[] | boolean | undefined>;
  @Input() externalFilters: ActionItem[] = [];
  @Input() properties?: Record<string, string | undefined>;
  @Input() mode: 'nav' | 'drag' | 'select' = 'nav';
  @Input() showActions: boolean = false;
  @Input() cache?: string;
  @Input() lockedFilters?: { action: 'lock' | 'hide'; filters: { [field: string]: ActionItem } } = {
    action: 'lock',
    filters: {},
  };
  @Input() sortOrder?: ActionItem;
  @Input() searchPlaceholder: string = this.translateService.instant('Search');
  @Input() filterArgs: SearchBarFilterResourceArgs = {};
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() clickItemPermissions: Permission[] = [];
  @Input() sourceConfiguration: SOURCE_CONFIGURATION = DEFAULT_SOURCE_CONFIGURATION;
  @Input() dummySkeletonType: DummySkeletonType = DummySkeletonType.item;
  @Input() dummySkeletonPresentationType: PresentationV2 = PresentationV2.listItem;
  @Input() dummyNumber: number = 12;
  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  searchOptions: string = '';
  eLayout = Layout;

  constructor(private translateService: TranslateService) {}

  change(searchOptions: string): void {
    this.searchOptions = searchOptions;
  }

  applyAction(action: ActionItem): void {
    this.action.emit(action);
  }
}
