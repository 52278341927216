import { Injectable } from '@angular/core';
import { finalize, Observable, of, switchMap, take, tap } from 'rxjs';
import {
  ApiResponse,
  DeactivateEntityTypes,
  Metric,
  Status,
  ToastStatus,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../models';
import { TranslateService } from '../../../services/common';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../metric-structure-state.service';
import { MetricTableGroup } from '../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { FeatureFlagService } from '../../../feature-flag';
import { ToastService } from '../../../components';

@Injectable()
export class DeactivateEntityService {
  constructor(
    private metricStructureService: MetricStructureStateService,
    private metricsService: MetricApiService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private dialogsService: DialogsService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public deactivate(
    deactivationEntityType: DeactivateEntityTypes,
    metricId: string,
    valueDefinition?: ValueDefinition,
    valueDefinitionGroup?: ValueDefinitionGroup,
    metricTableGroup?: MetricTableGroup,
  ): void {
    this.updateFieldStatus(true);
    this.deactivateEntity(deactivationEntityType, metricId, valueDefinition, valueDefinitionGroup, metricTableGroup);
  }

  private deactivateValueDefinition(metricId: string, valueDefinition: ValueDefinition): void {
    this.updateFieldStatus(true);
    this.metricsService
      .deactivateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
      .pipe(
        tap((resp) => {
          this.updateDeactivatedValueDefinition(resp.data, valueDefinition);
        }),
        switchMap((resp) =>
          this.canDeactivateMetric(resp.data) ? this.raiseLastFieldMetricDeactivationDialog(resp) : of(resp),
        ),
        take(1),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe();
  }

  private deactivateMetricTableGroup(metricId: string, metricTableGroup: MetricTableGroup): void {
    this.updateFieldStatus(true);
    this.metricsService
      .deactivateMetricTable(metricId, metricTableGroup.table_id)
      .pipe(
        tap((resp) => {
          this.updateDeactivatedMetricTable(resp.data, metricTableGroup);
        }),
        switchMap((resp) =>
          this.canDeactivateMetric(resp.data) ? this.raiseLastFieldMetricDeactivationDialog(resp) : of(resp),
        ),
        take(1),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe();
  }

  private getValueDefinitionFromMetric(vdId: string, metric: Metric): ValueDefinition | undefined {
    return metric.value_definition_groups
      ?.flatMap((x) => x.value_definitions?.flatMap((y) => y))
      .find((vd) => vd?.id === vdId);
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private deactivateEntity(
    deactivationEntityType: DeactivateEntityTypes,
    metricId: string,
    valueDefinition?: ValueDefinition,
    valueDefinitionGroup?: ValueDefinitionGroup,
    metricTableGroup?: MetricTableGroup,
  ): void {
    if (deactivationEntityType === DeactivateEntityTypes.FIELD && valueDefinition) {
      this.deactivateValueDefinition(metricId, valueDefinition);
    } else if (deactivationEntityType === DeactivateEntityTypes.TABLE && metricTableGroup) {
      this.deactivateMetricTableGroup(metricId, metricTableGroup);
    }
  }

  private areAllMetricFieldsDeactivated(metric: Metric): boolean {
    return !metric.value_definition_groups?.flatMap((vdg) => vdg.value_definitions || []).some((vd) => vd.active);
  }

  private raiseLastFieldMetricDeactivationDialog(
    resp: ApiResponse<Metric | undefined>,
  ): Observable<ApiResponse<Metric>> {
    return this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Deactivate metric'),
          warningMsg: this.translateService.instant(
            'All the fields in this metric are deactivated for all Fiscal years. Do you wish to deactivate the metric?',
          ),
          primaryBtn: this.translateService.instant('Deactivate'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) =>
          result && result.status == Status.CONFIRMED
            ? this.handleMetricDeactivation((resp.data as Metric).id)
            : of(resp as ApiResponse<Metric>),
        ),
      );
  }

  private handleMetricDeactivation(metricId: string): Observable<ApiResponse<Metric>> {
    return this.metricsService.deactivateMetric(metricId).pipe(
      tap((resp) => {
        this.metricStructureService.updateMetric(resp.data);
        this.metricStructureService.updateSelectedItem(resp.data);
      }),
    );
  }

  private updateDeactivatedValueDefinition(metric: Metric, valueDefinition: ValueDefinition): void {
    this.metricStructureService.updateMetric(metric);
    const updatedValueDefinition = this.getValueDefinitionFromMetric(valueDefinition.id, metric);
    this.metricStructureService.updateSelectedItem({
      ...valueDefinition,
      active: updatedValueDefinition?.active,
      end_fiscal_year_frequency_code: updatedValueDefinition?.end_fiscal_year_frequency_code,
    });

    const alertMessage = this.metricStructureService.isAdmin
      ? this.translateService.instant('Field deactivated')
      : this.translateService.instant(
          `Field deactivated from ${updatedValueDefinition?.end_fiscal_year_frequency_code}`,
        );
    this.toastService.open(ToastStatus.SUCCESS, alertMessage);
  }

  private updateDeactivatedMetricTable(metric: Metric, metricTableGroup: MetricTableGroup): void {
    this.metricStructureService.updateMetric({
      ...metric,
      value_definition_groups: metric.value_definition_groups,
    });

    const end_fiscal_year_frequency_code = metric.value_definition_groups?.find(
      (vdg) => vdg.table_id == metricTableGroup.id,
    )?.end_fiscal_year_frequency_code;

    this.metricStructureService.updateSelectedItem({
      ...metricTableGroup,
      active: false,
      end_fiscal_year_frequency_code: end_fiscal_year_frequency_code
        ? end_fiscal_year_frequency_code
        : metricTableGroup.end_fiscal_year_frequency_code,
    });
    const alertMessage = this.metricStructureService.isAdmin
      ? this.translateService.instant('Table deactivated')
      : this.translateService.instant(`Table deactivated from ${end_fiscal_year_frequency_code}`);
    this.toastService.open(ToastStatus.SUCCESS, alertMessage);
  }

  private canDeactivateMetric(metric: Metric): boolean {
    return (
      this.featureFlagService.areAnyFeatureFlagsEnabled(['metric_deactivation_enabled']) &&
      metric.active &&
      this.areAllMetricFieldsDeactivated(metric)
    );
  }

  public deactivateMetric(metricId: string): void {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Metric Deactivation'),
          warningMsg: this.translateService.instant(
            'Deactivating this metric will also deactivate all the fields inside this metric.',
          ),
          primaryBtn: this.translateService.instant('Deactivate'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) =>
          result && result.status == Status.CONFIRMED ? this.handleMetricDeactivation(metricId) : of(undefined),
        ),
        take(1),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe();
  }
}
