<lib-page-context-bar [withSeparator]="true" [pageName]="'Dashboards' | translate">
  <button
    *libPermission="[ePermission.DASHBOARDS_MANAGE]"
    lib-button
    main-actions
    (click)="setDatumsSidebarVisibility(true)"
    buttonType="cancel_with_border"
  >
    {{ "Manage Data Points" | translate }}
  </button>

  <button
    *libPermission="[ePermission.DASHBOARDS_MANAGE]"
    lib-button
    main-actions
    class="ml-2"
    (click)="onCreateDashboard()"
    buttonType="primary"
  >
    {{ "Create" | translate }}
  </button>
</lib-page-context-bar>

@if (data$ | async; as data) {
  <div class="mt-2">
    @if (data.tableFilters.length) {
      <lib-filter-bar
        [withSearchBar]="true"
        [secondaryFilters]="data.tableFilters"
        [searchBarPlaceholder]="'Search dashboards' | translate"
        (onSearchChanged)="onSearchQueryChange($event)"
        (onFilterChanged)="onFilterChange($event)"
      ></lib-filter-bar>
    }

    <div class="ph-5">
      @if (!data.isLoading && data.total) {
        <lib-table-grid
          [columns]="columns"
          [values]="data.dashboards"
          [isCustomSort]="false"
          [isLoading]="data.isLoading"
          [isPaginable]="true"
          [isResizable]="false"
          [totalItemCount]="data.total"
          [actionMenuItems]="actionMenuItems"
          (rowSelected)="onViewDashboard($event)"
          (pageChanged)="onPageChange($event)"
        >
        </lib-table-grid>
      } @else if (data.isLoading) {
        <lib-skeleton-table [columns]="columns" [numberOfRow]="25" data-testid="skeleton"></lib-skeleton-table>
      } @else {
        <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
      }
    </div>
  </div>

  <lib-dashboard-datums-sidebar
    [options]="options"
    [visible]="data.datumsSidebarVisible"
    (visibleChange)="setDatumsSidebarVisibility($event)"
  >
  </lib-dashboard-datums-sidebar>
}

<ng-template #defaultForCell let-dashboardId>
  <div>{{ (defaultDashboard?.id === dashboardId ? "Company-Wide" : "-") | translate }}</div>
</ng-template>

<ng-template #ownerCell let-createdBy>
  <div>{{ createdBy | updatedByUser: users }}</div>
</ng-template>

<ng-template #statusCell let-status>
  <div>{{ eDashboardStatusToText[status] | translate }}</div>
</ng-template>

<ng-template #visibilityCell let-visibility>
  <div class="fx-row align-center">
    <mat-icon class="icon-sm mr-1" [svgIcon]="eDashboardPublicVisibilityToIcon[visibility]"></mat-icon>
    {{ eDashboardPublicVisibilityToText[visibility] | translate }}
  </div>
</ng-template>
