<div class="card-list p-2">
  <div class="card-list-scroll" role="list">
    @for (item of items; track item.id; let index = $index) {
      <lib-drag-item-card
        [item]="item"
        [menuItems]="menuItems[item.id]"
        (menuAction)="action.emit($event)"
        [pDraggableDisabled]="!draggable"
        [disabled]="!!item?.disabled"
        [menuDisabled]="!!item?.menuDisabled"
        pDraggable
        pDroppable
        dragHandle=".drag-handle"
        (onDragStart)="onDragStart(index)"
        (onDragEnter)="onDragEnter(index)"
        (onDrop)="onDrop(item, index)"
        data-testid="drag-item-card-selector"
      >
        <ng-template #afterTitle let-item>
          @if (afterTitleTmpl) {
            <ng-container
              [ngTemplateOutlet]="afterTitleTmpl"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          }
        </ng-template>
        <ng-template #extraContent>
          @if (extraContentTmpl) {
            <ng-container
              [ngTemplateOutlet]="extraContentTmpl"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          }
        </ng-template>
      </lib-drag-item-card>
    }
  </div>
</div>
