import { Component, Input } from '@angular/core';
import { ActionItem, Permission } from '../../../models';

@Component({
  selector: 'lib-actionable-info-card',
  templateUrl: './actionable-info-card.component.html',
  styleUrl: './actionable-info-card.component.scss',
})
export class ActionableInfoCardComponent<T> {
  @Input() item?: ActionItem<T>;
  @Input() withExternalLink: boolean = true;
  @Input() displayDefaultHeader: boolean = true;
  @Input() cardSize?: 'default' | 'medium' = 'default';
  @Input() externalRedirectionLink?: string = '';
  @Input() externalRedirectionLinkTarget: string = '_self';

  protected readonly ePermission = Permission;
}
