import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, AuditEvent, ListAuditEventsRequest } from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientAuditEventsService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  public listAuditEvents(listAuditEventsRequest: ListAuditEventsRequest): Observable<ApiResponse<AuditEvent[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/audit_events`, listAuditEventsRequest);
  }
}
