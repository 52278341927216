<div class="panel-properties-tabs">
  <button lib-button (click)="closeProperties()">
    <mat-icon class="icon-md" svgIcon="close"></mat-icon>
  </button>

  <button
    lib-button
    (click)="setSelectedPanelTab(panelTabActions[0])"
    data-testid="Settings"
    [ngClass]="{ active: panelTabActions[0].id === selectedPanelTabAction.id }"
  >
    <mat-icon svgIcon="{{ panelTabActions[0].icon }}"></mat-icon>
  </button>

  @if ((isCreatingField$ | async) === false) {
    @if (selectedItemType === "valueDefinition" || selectedItemType === "group" || selectedItemType === "table") {
      <button
        lib-button
        data-testid="FieldInformation"
        (click)="setSelectedPanelTab(panelTabActions[1])"
        [ngClass]="{ active: panelTabActions[1].id === selectedPanelTabAction.id }"
      >
        <mat-icon svgIcon="{{ panelTabActions[1].icon }}"></mat-icon>
      </button>
    }
  }

  @if ((isCreatingField$ | async) === false) {
    @if (
      selectedItem
        | canHaveConditionalTrigger: selectedItemType : parentValueDefinitionGroup : metric : canEditEveryMetrics
    ) {
      <button
        lib-button
        data-testid="ConditionalTriggers"
        (click)="setSelectedPanelTab(panelTabActions[2])"
        [ngClass]="{ active: panelTabActions[2].id === selectedPanelTabAction.id }"
      >
        <mat-icon svgIcon="{{ panelTabActions[2].icon }}"></mat-icon>
      </button>
    }
  }

  @if (selectedItemType === eMetricStructureSelectableType.table) {
    <button
      lib-button
      data-testid="TableTotals"
      (click)="setSelectedPanelTab(panelTabActions[3])"
      [ngClass]="{ active: panelTabActions[3].id === selectedPanelTabAction.id }"
    >
      <mat-icon [svgIcon]="panelTabActions[3].icon!"></mat-icon>
    </button>
  }

  @if (isRepeatableGroup$ | async) {
    @if (selectedItemType === eMetricStructureSelectableType.group) {
      <button
        lib-button
        data-testid="RepeatableGroupExportConfiguration"
        (click)="setSelectedPanelTab(panelTabActions[4])"
        [ngClass]="{ active: panelTabActions[4].id === selectedPanelTabAction.id }"
      >
        <mat-icon [svgIcon]="panelTabActions[4].icon!"></mat-icon>
      </button>
    }
  }

  @if ((isCreatingField$ | async) === false) {
    <ng-container *libFeatureFlag="{ featureFlags: ['frequency_enabled'] }">
      @if (
        regularFiscalYears &&
        selectedItemType === "valueDefinition" &&
        $any(selectedItem).type === eValueDefinitionType.number &&
        !isAdmin
      ) {
        <button
          lib-button
          data-testid="fieldFrequency"
          (click)="setSelectedPanelTab(panelTabActions[5])"
          [ngClass]="{ active: panelTabActions[5].id === selectedPanelTabAction.id }"
        >
          <mat-icon svgIcon="{{ panelTabActions[5].icon }}"></mat-icon>
        </button>
      }
    </ng-container>
  }
</div>
@if (selectedPanelTabAction.id === panelTabActions[0].id) {
  @if (selectedItemType === eMetricStructureSelectableType.metric) {
    <lib-metric-structure-settings
      class="panel-properties-content h-100"
      (closeProperties)="closeProperties()"
    ></lib-metric-structure-settings>
  }
  @if (selectedItemType === eMetricStructureSelectableType.group) {
    <lib-metric-structure-group-properties
      [valueDefinitionGroup]="$any(selectedItem)"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      (closeGroup)="closeProperties()"
    ></lib-metric-structure-group-properties>
  }
  @if (selectedItemType === eMetricStructureSelectableType.table) {
    <lib-metric-structure-table-properties
      [metricTableGroup]="$any(selectedItem)"
      [metric]="metric"
      [sourceConfiguration]="sourceConfiguration"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      (closePanel)="closeProperties()"
    ></lib-metric-structure-table-properties>
  }
  @if (selectedItemType === eMetricStructureSelectableType.valueDefinition) {
    <lib-metric-structure-field-properties
      [valueDefinition]="$any(selectedItem)"
      [metric]="metric"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      [sourceConfiguration]="sourceConfiguration"
      (closePanel)="closeProperties()"
    ></lib-metric-structure-field-properties>
  }
}
@if (selectedPanelTabAction.id === panelTabActions[1].id) {
  <lib-metric-structure-field-information
    class="panel-properties-content"
    [metric]="metric"
    [fieldVisibility]="fieldVisibility"
    [panelTabAction]="panelTabActions[1]"
    (closePanel)="closeProperties()"
  >
  </lib-metric-structure-field-information>
}
@if (selectedPanelTabAction.id === panelTabActions[2].id) {
  <lib-metric-structure-panel-conditional-trigger
    class="panel-properties-content"
    [metric]="metric"
    [sourceValueDefinition]="$any(selectedItem)"
    [valueDefinitionGroups]="metric.value_definition_groups"
    (updateMetric)="updateMetricAndKeepSelectedTab($event)"
  ></lib-metric-structure-panel-conditional-trigger>
}
@if (selectedPanelTabAction.id === panelTabActions[3].id) {
  <lib-metric-table-total-tab
    class="panel-properties-content"
    [metricTableGroup]="$any(selectedItem)"
    (close)="closeProperties()"
    [metric]="metric"
  >
  </lib-metric-table-total-tab>
}
@if (isRepeatableGroup$ | async) {
  @if (selectedPanelTabAction.id === panelTabActions[4].id) {
    <lib-metric-structure-repeatable-group-properties-tab
      class="panel-properties-content h-100"
      [valueDefinitionGroupId]="$any(selectedItem).id"
      [metric]="metric"
    >
    </lib-metric-structure-repeatable-group-properties-tab>
  }
}
<ng-container *libFeatureFlag="{ featureFlags: ['frequency_enabled'] }">
  @if (selectedPanelTabAction.id === panelTabActions[5].id && regularFiscalYears) {
    <lib-metric-structure-field-frequency
      class="panel-properties-content"
      [panelTabAction]="panelTabActions[5]"
      [fiscalYears]="fiscalYears"
      [metric]="metric"
      [valueDefinition]="$any(selectedItem)"
      (closePanel)="closeProperties()"
    >
    </lib-metric-structure-field-frequency>
  }
</ng-container>
