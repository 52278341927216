<lib-dialog-title [title]="'{cta} dashboard' | translate: { cta: data.cta }" (closeEvent)="close()"></lib-dialog-title>

@if (form) {
  <div mat-dialog-content>
    <form class="form">
      <lib-text-input
        [control]="form.controls.name"
        class="formfield large"
        [label]="'Dashboard name' | translate"
        [messages]="{ isUnique: 'A dashboard with this name already exists, choose a different name.' | translate }"
      ></lib-text-input>

      @if (data$ | async; as data) {
        @if (data.dashboards.length) {
          <lib-dropdown-input
            class="formfield large"
            optionLabel="name"
            optionValue="id"
            [showClear]="true"
            [label]="'Select a template' | translate"
            [options]="data.dashboards"
            [control]="form.controls.coreDashboardId"
          ></lib-dropdown-input>
        }
      }
    </form>
  </div>

  <div mat-dialog-actions>
    <button lib-button buttonType="primary" [disabled]="form.pristine || form.invalid" (click)="save()">
      {{ data.cta }}
    </button>

    <button lib-button buttonType="cancel_with_border" class="btn ml-3" (click)="close()">
      {{ "Cancel" | translate }}
    </button>
  </div>
}
