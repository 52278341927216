import { Component, Input } from '@angular/core';
import { ProgressBarClassColours, ProgressBarMode } from '../../../models';

@Component({
  selector: 'lib-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() showValue: boolean = true;
  @Input() progress: number = 0;
  @Input() sourceClass: string = ProgressBarClassColours.GREY_PROGRESS;
  @Input() mode: ProgressBarMode = ProgressBarMode.DETERMINATE;
}
