import { Observable } from 'rxjs';

import { ApiResponse, Source } from '../../../models';

export interface SourcesApi {
  listSources(): Observable<ApiResponse<Source[]>>;
}

export abstract class SourcesApiService implements SourcesApi {
  public abstract listSources(): Observable<ApiResponse<Source[]>>;
}
