import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives';
import { CdkModule } from '../material/cdk.module';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import { SectionPrimaryComponent } from './section-primary/section-primary.component';
import { SectionComponent } from './section/section.component';
import { SectionSecondaryComponent } from './section-secondary/section-secondary.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { ComponentsModule } from '../components';
import { FeatureFlagModule } from '../feature-flag';

@NgModule({
  declarations: [SectionPrimaryComponent, SectionComponent, SectionSecondaryComponent, FormSectionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    GridModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    DocumentEditorContainerModule,
    ComponentsModule,
    FeatureFlagModule,
  ],
  exports: [SectionPrimaryComponent, SectionComponent, SectionSecondaryComponent, FormSectionComponent],
})
export class SectionsModule {}
