import { Pipe, PipeTransform } from '@angular/core';
import { MetricTableColumnDefinition } from '../../../../../../models';

@Pipe({
  name: 'getContextColumnOptions',
})
export class GetContextColumnOptionsPipe implements PipeTransform {
  transform(column: string, contextColumnDefinitions: MetricTableColumnDefinition[]): string[] {
    return (
      contextColumnDefinitions.find((col) => col.label === column)?.context_options.map((option) => option.label) ?? []
    );
  }
}
