<lib-sidebar
  [zIndex]="900"
  [primaryBtn]="(sidebarData.dashboardWidget ? 'Update Widget' : 'Insert Widget') | translate"
  [primaryBtnDisabled]="!form || form.pristine || form.invalid"
  [secondaryBtn]="'Cancel' | translate"
  [title]="(sidebarData.dashboardWidget ? 'Edit widget' : 'Create widget') | translate"
  [visible]="true"
  (visibleChange)="close($event)"
  (primaryClick)="onSave()"
  (secondaryClick)="close()"
>
  @if (eSINGLE_DATUMS_WIDGETS.includes(sidebarData.widgetType)) {
    <div class="mt-2">
      <lib-info-banner
        [message]="
          '{widgetType} widget can only have a single data point'
            | translate: { widgetType: eDashboardWidgetTypeToText[sidebarData.widgetType] }
        "
      ></lib-info-banner>
    </div>
  }

  @if (data$ | async; as data) {
    @if (form && data.dashboardDatums.length) {
      <form class="form dashboard-form mt-2">
        <lib-text-input
          [control]="form.controls.label"
          class="large"
          [label]="'Widget Label' | translate"
          [maxCharacterCount]="200"
        ></lib-text-input>

        @if (eSINGLE_DATUMS_WIDGETS.includes(sidebarData.widgetType)) {
          <lib-dropdown-input
            class="large"
            optionLabel="title"
            optionValue="id"
            [showClear]="false"
            [label]="'Select data point' | translate"
            [options]="data.dashboardDatums"
            [control]="form.controls.datumId"
          ></lib-dropdown-input>
        } @else {
          <lib-multi-select-dropdown
            class="large"
            [label]="'Select data points' | translate"
            [control]="form.controls.datumIds"
            [options]="data.dashboardDatums"
            [withSelectAllOption]="true"
          ></lib-multi-select-dropdown>
        }
      </form>

      <button lib-button buttonType="cancel_with_border" class="mt-2" (click)="addMore()">
        + {{ "Add more" | translate }}
      </button>
    }
  }
</lib-sidebar>
