import { Observable } from 'rxjs';

import { ApiResponse, FiscalYear } from '../../../models';

export interface FiscalYearsApi {
  listFiscalYears(): Observable<ApiResponse<FiscalYear[]>>;
}

export abstract class FiscalYearsApiService implements FiscalYearsApi {
  public abstract listFiscalYears(): Observable<ApiResponse<FiscalYear[]>>;
}
