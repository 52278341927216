import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-table-empty-grid',
  templateUrl: './table-empty-grid.component.html',
  styleUrls: ['./table-empty-grid.component.scss'],
})
export class TableEmptyGridComponent {
  @Input() emptyMessageTitle?: string;
  @Input() emptyMessageDescription?: string;
  @Input() actionLabel?: string;
  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();

  onActionClick(): void {
    this.actionClicked.emit();
  }
}
