import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { DashboardViewerStore } from './dashboard-viewer.store';
import { provideComponentStore } from '@ngrx/component-store';
import { combineLatestWith, map, Observable } from 'rxjs';
import { DashboardOptions } from '../dashboard/dashboard.component';
import {
  Dashboard,
  DashboardPublicVisibility,
  DashboardWidget,
  DashboardWidgetType,
  SINGLE_SIZED_WIDGETS,
  ToastStatus,
  WidgetGridster2Dimensions,
} from '../../models';
import {
  DashboardPublicVisibilityToIcon,
  DashboardsHelperTranslations,
  DashboardWidgetTypeToIcon,
  DashboardWidgetTypeToText,
} from '../dashboards.translation';
import { ActivatedRoute } from '@angular/router';
import { DashboardsHelper } from '../dashboards-helper';
import {
  DashboardDatumsApiService,
  DashboardsApiService,
  DashboardWidgetsApiService,
} from '../../services/api-services';
import { DialogsService } from '../../dialogs';
import { TranslateService } from '../../services/common';
import { ToastService } from '../../components/toast';
import { DisplayGrid, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType } from 'angular-gridster2';
import { DashboardWidgetUpsertSidebarData } from './dashboard-widget-upsert-sidebar/dashboard-widget-upsert-sidebar.component';
import { DashboardGridOptions } from '../dashboard-grid/dashboard-grid.component';

enum Action {
  copy = 'copy',
  rename = 'rename',
  delete = 'delete',
}

interface DashboardViewerOptions extends DashboardGridOptions {
  withShareAction?: boolean;
  withFilters?: boolean;
  withMainActions?: boolean;
  withContextBar?: boolean;
}

interface Data {
  dashboard: Dashboard;
  dashboardWidgets: DashboardWidget[];
  isEditing: boolean;
  isOwner: boolean;
  sidebarData?: DashboardWidgetUpsertSidebarData;
}

@Component({
  selector: 'lib-dashboard-viewer',
  templateUrl: './dashboard-viewer.component.html',
  styleUrls: ['./dashboard-viewer.component.scss'],
  providers: [provideComponentStore(DashboardViewerStore)],
})
export class DashboardViewerComponent implements OnInit {
  @Input() dashboardOptions: DashboardOptions = {};
  @Input() options: DashboardViewerOptions = {};

  @Output() delete = new EventEmitter<void>();
  @Output() share = new EventEmitter<Dashboard>();
  @Output() view = new EventEmitter<Dashboard>();

  public readonly dashboardWidgetTypes = [
    DashboardWidgetType.PIE_CHART_WIDGET,
    DashboardWidgetType.LINE_CHART_WIDGET,
    DashboardWidgetType.BAR_CHART_WIDGET,
    DashboardWidgetType.DONUT_CHART_WIDGET,
    DashboardWidgetType.DATA_POINT_WIDGET,
    DashboardWidgetType.STACKED_BAR_CHART_WIDGET,
  ];
  public readonly eAction = Action;
  public readonly eDashboardPublicVisibility = DashboardPublicVisibility;
  public readonly eDashboardPublicVisibilityToIcon: Record<string, string> = DashboardPublicVisibilityToIcon;
  public readonly eDashboardWidgetTypeToIcon: Record<string, string> = DashboardWidgetTypeToIcon;
  public readonly eDashboardWidgetTypeToText: Record<string, string> = DashboardWidgetTypeToText;

  public data$?: Observable<Data>;
  public viewGridsterConfig: GridsterConfig = {
    pushItems: false,
    gridType: GridType.Fit,
    displayGrid: DisplayGrid.None,
    resizable: { enabled: false },
    draggable: { enabled: false },
    minCols: 4,
    maxCols: 4,
    minRows: 3,
    maxRows: 3,
    defaultItemCols: 4,
    defaultItemRows: 3,
  };
  public editGridsterConfig: GridsterConfig = {
    ...this.viewGridsterConfig,
    pushItems: true,
    swap: true,
    displayGrid: DisplayGrid.OnDragAndResize,
    draggable: {
      delayStart: 0,
      enabled: true,
      ignoreContentClass: 'gridster-item-content',
      ignoreContent: false,
      dragHandleClass: 'drag-handler',
      dropOverItems: false,
    },
    resizable: { enabled: true },
  };

  private dashboardsHelper: DashboardsHelper;

  constructor(
    readonly dashboardsApiService: DashboardsApiService,
    readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
    readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dashboardViewerStore: DashboardViewerStore,
    readonly dialogsService: DialogsService,
    private readonly route: ActivatedRoute,
    readonly toastService: ToastService,
    readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.dashboardsHelper = new DashboardsHelper(
      dashboardsApiService,
      dashboardWidgetsApiService,
      dashboardDatumsApiService,
      dialogsService,
      toastService,
      translateService,
    );
  }

  public ngOnInit(): void {
    this.data$ = this.dashboardViewerStore.dashboard$.pipe(
      combineLatestWith(
        this.dashboardViewerStore.dashboardWidgets$,
        this.dashboardViewerStore.isOwner$,
        this.dashboardViewerStore.isEditing$,
        this.dashboardViewerStore.sidebarData$,
      ),
      map(([dashboard, dashboardWidgets, isOwner, isEditing, sidebarData]) => ({
        dashboard,
        dashboardWidgets,
        isEditing,
        isOwner,
        sidebarData,
      })),
    );

    this.route.params.subscribe((params) => {
      this.dashboardViewerStore.init(params.id ? String(params.id) : undefined);
    });
  }

  public onCopyDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.copyDashboard(dashboard, this.viewContainerRef).subscribe((res) => {
      this.view.emit(res.data);
    });
  };

  public onCreateWidget(dashboard: Dashboard, widgetType: DashboardWidgetType): void {
    const availablePosition = this.getFirstPossiblePosition(
      SINGLE_SIZED_WIDGETS.includes(widgetType) ? { rows: 1, cols: 1 } : { rows: 1, cols: 2 },
    );

    this.dashboardViewerStore.updateSidebarDataState({
      dashboardId: dashboard.id,
      widgetType,
      columnLocation: availablePosition?.x,
      rowLocation: availablePosition?.y,
    });
  }

  public onDeleteDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.deleteDashboard(dashboard).subscribe(() => {
      this.delete.emit();
    });
  };

  public onDeleteWidget(dashboardWidgets: DashboardWidget[], widget: DashboardWidget): void {
    this.dashboardsHelper.deleteDashboardWidget(widget).subscribe(() => {
      this.dashboardViewerStore.updateDashboardWidgetsState(dashboardWidgets.filter((w) => w.id !== widget.id));
    });
  }

  public onEditDashboard(isEditing: boolean): void {
    this.dashboardViewerStore.updateIsEditingState(isEditing);
  }

  public onEditWidget(dashboardWidget: DashboardWidget): void {
    this.dashboardViewerStore.updateSidebarDataState({
      dashboardWidget,
      dashboardId: dashboardWidget.dashboard_id,
      widgetType: dashboardWidget.widget_type,
    });
  }

  public onMenuClick(action: Action, dashboard: Dashboard): void {
    switch (action) {
      case Action.copy:
        this.onCopyDashboard(dashboard);
        break;
      case Action.delete:
        this.onDeleteDashboard(dashboard);
        break;
      case Action.rename:
        this.onRenameDashboard(dashboard);
        break;
    }
  }

  public onRenameDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.editDashboard(dashboard, this.viewContainerRef).subscribe((res) => {
      this.dashboardViewerStore.updateDashboardState(res.data);
    });
  };

  public onSaveWidget(
    targetWidget: DashboardWidget,
    dashboardWidgets: DashboardWidget[],
    dashboardWidget?: DashboardWidget,
  ): void {
    let message: string;
    const widgets = [...dashboardWidgets];

    if (dashboardWidget) {
      message = DashboardsHelperTranslations.editedDashboard;
      widgets[widgets.findIndex((w) => w.id === dashboardWidget.id)] = targetWidget;
    } else {
      message = DashboardsHelperTranslations.createdWidget;
      widgets.push(targetWidget);
    }

    this.dashboardViewerStore.updateDashboardWidgetsState(widgets);
    this.toastService.open(ToastStatus.SUCCESS, this.translateService.instant(message));
  }

  public onWidgetPlot(gridsterChangeEvent: { item: GridsterItem; itemComponent: GridsterItemComponentInterface }) {
    this.dashboardViewerStore.updateWidgetPlot(gridsterChangeEvent.item);
  }

  private getFirstPossiblePosition(dimensions: WidgetGridster2Dimensions): GridsterItem | undefined {
    const fakeSingleDataPointWidget = { x: 0, y: 0, resizeEnabled: true, id: 123, ...dimensions };
    return this.editGridsterConfig.api?.getFirstPossiblePosition?.(fakeSingleDataPointWidget);
  }
}
