<p-table
  #ptable
  class="table-grid-container"
  [ngClass]="{ 'adjusted-height': isPaginable }"
  [value]="isLoading ? [] : values"
  [columns]="columns"
  [scrollable]="true"
  [lazy]="isPaginable"
  scrollHeight="flex"
  [resizableColumns]="isResizable"
  columnResizeMode="expand"
  [dataKey]="uniquenessRowProperty"
  [customSort]="isCustomSort"
  [selectionMode]="rowGroupingProperties?.length ? undefined : enableCheckboxSelection ? 'multiple' : 'single'"
  [styleClass]="withGridLines ? 'p-datatable-gridlines' : ''"
  [rowGroupMode]="rowGroupingProperties?.length ? 'rowspan' : undefined"
  [groupRowsBy]="rowGroupingProperties?.[0] ?? undefined"
  [loading]="isLoading"
  [showLoader]="false"
  [selection]="selection"
  [expandedRowKeys]="expandedRowKeys"
  (sortFunction)="sortColumn($event)"
  (onLazyLoad)="sortPaginatedColumn($event)"
  (onRowReorder)="reorderRow($event)"
  (onRowSelect)="clickRow($event, true)"
  (onRowUnselect)="clickRow($event, false)"
  (onFilter)="filterColumn($event)"
>
  @if (tableTitle) {
    <ng-template pTemplate="caption">
      <div class="captionTitle">
        {{ tableTitle }}
      </div>
    </ng-template>
  }

  <ng-template pTemplate="loadingbody">
    @for (i of pageSize | range; track i) {
      <tr class="dummy-table-row" data-testid="dummy-table-rows">
        @for (
          j of columns.length +
            (isExpandable ? 1 : 0) +
            (isRowReorderable ? 1 : 0) +
            (actionMenuItems?.length ? 1 : 0) +
            (enableCheckboxSelection ? 1 : 0) | range;
          track j
        ) {
          <td>
            <p-skeleton></p-skeleton>
          </td>
        }
      </tr>
    }
  </ng-template>

  @if (withHeader; as columns) {
    <ng-template pTemplate="header" let-columns>
      <tr
        lib-table-header-row
        [columns]="columns"
        [isExpandable]="isExpandable"
        [isResizable]="isResizable"
        [isRowReorderable]="isRowReorderable"
        [withHeader]="withHeader"
        [actionMenuItems]="actionMenuItems"
        [horizontalPaginationConfig]="horizontalPaginationConfig"
        [enableCheckboxSelection]="enableCheckboxSelection"
        [selectAllOption]="selectAllOption"
        [expandAllOption]="expandAllOption"
        [areAllRowsExpanded]="areAllRowsExpanded"
        (horizontalPageChanged)="horizontalPageChanged.emit($event)"
        (toggleExpandAll)="toggleExpandAll()"
      ></tr>
    </ng-template>
  }
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-expanded="expanded"
    let-index="rowIndex"
    let-rowgroup="rowgroup"
    let-rowspan="rowspan"
  >
    @if (enableCheckboxSelection) {
      <tr
        lib-table-body-row
        [ngClass]="{ 'row-disabled': disabledRowProperty && rowData[disabledRowProperty] }"
        [pReorderableRow]="index"
        [columns]="columns"
        [rowData]="rowData"
        [isRowReorderable]="isRowReorderable"
        [isExpandable]="isExpandable"
        [isExpanded]="expanded"
        [actionMenuItems]="actionMenuItems"
        [defaultEmptyValue]="defaultEmptyValue"
        [isWrapping]="isWrapping"
        [rowgroup]="rowgroup"
        [rowspan]="rowspan"
        [rowGroupingProperties]="rowGroupingProperties"
        [enableCheckboxSelection]="enableCheckboxSelection"
      ></tr>
    } @else {
      <tr
        lib-table-body-row
        [ngClass]="{ 'row-disabled': disabledRowProperty && rowData[disabledRowProperty] }"
        [pReorderableRow]="index"
        [pSelectableRow]="rowData"
        [columns]="columns"
        [rowData]="rowData"
        [isRowReorderable]="isRowReorderable"
        [isExpandable]="isExpandable"
        [isExpanded]="expanded"
        [actionMenuItems]="actionMenuItems"
        [defaultEmptyValue]="defaultEmptyValue"
        [isWrapping]="isWrapping"
        [rowgroup]="rowgroup"
        [rowspan]="rowspan"
        [rowGroupingProperties]="rowGroupingProperties"
        [enableCheckboxSelection]="enableCheckboxSelection"
      ></tr>
    }
  </ng-template>

  @if (isExpandable && expandedRowConfig) {
    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr>
        <td
          [attr.colspan]="
            columns.length +
            1 +
            (isRowReorderable ? 1 : 0) +
            (actionMenuItems?.length ? 1 : 0) +
            (enableCheckboxSelection ? 1 : 0)
          "
        >
          <lib-table-grid-expanded-row [row]="rowData" [config]="expandedRowConfig"></lib-table-grid-expanded-row>
        </td>
      </tr>
    </ng-template>
  }

  @if (emptyMessage) {
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="
            columns.length +
            (isExpandable ? 1 : 0) +
            (isRowReorderable ? 1 : 0) +
            (actionMenuItems?.length ? 1 : 0) +
            (enableCheckboxSelection ? 1 : 0)
          "
          class="pv-3"
        >
          <div class="fx-row empty-message">
            <p class="mb-0 mr-1">{{ emptyMessage }}</p>
            <a (click)="clearFilter()">{{ "Clear filter" | translate }}</a>
          </div>
        </td>
      </tr>
    </ng-template>
  }
</p-table>

@if (isPaginable) {
  <lib-table-paginator
    [totalItemCount]="totalItemCount"
    [pageSize]="pageSize"
    [withPaginationAlternatives]="withPaginationAlternatives"
    [showCurrentPageReportTemplateText]="showCurrentPageReportTemplateText"
    (pageChanged)="resetExpandState(); pageChanged.emit($event)"
  >
    <ng-content ngProjectAs="footer" select="footer"><div></div></ng-content>
  </lib-table-paginator>
}
