import { Observable } from 'rxjs';
import { ApiResponse, Doc, EsSearchOptionsPayload, Filter, SearchOptions } from '../../../../models';
import { BaseService } from '../../../common/base/base.service';
import { DocumentCategory, PlaformDocumentCategory } from '../../../../documents/models';

export abstract class BaseClientDataRequestsDocumentsService {
  abstract isPublic: boolean;

  constructor(public baseService: BaseService) {}

  abstract searchDataRequestDocuments(
    dataRequestId: string,
    searchOptions: SearchOptions,
  ): Observable<ApiResponse<Doc[]>>;

  public payloadFromSearchOptions(searchOptions: SearchOptions): EsSearchOptionsPayload {
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'tag', 'tag_ids');
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'status', 'public');

    const payload: EsSearchOptionsPayload = this.baseService.esPayloadFromSearchOptions(searchOptions);

    payload.filters.push({
      field: 'category',
      value: [
        DocumentCategory.custom_template_export,
        DocumentCategory.framework_template_export,
        PlaformDocumentCategory.metric_attachment,
        DocumentCategory.metric_data_export,
        DocumentCategory.questionnaire_export,
        DocumentCategory.questionnaire_submission,
        DocumentCategory.cdp_automated_transfer,
        DocumentCategory.benchmark_v2_export,
        DocumentCategory.source_comparison_v1_export,
        DocumentCategory.xbrl_tag_export,
      ],
      type: 'exclude',
    });

    const tagIdsFilter: Filter | undefined = payload.filters.find((filter: Filter) => filter.field === 'tag_ids');
    if (tagIdsFilter) {
      // the document search api uses a different name for the tag filter
      tagIdsFilter.value = searchOptions.filters.tag_ids?.id;
    }

    const sourceFilter: Filter | undefined = payload.filters.find((filter: Filter) => filter.field === 'source');
    if (sourceFilter) {
      // the document search api uses a different name for the source filter
      sourceFilter.field = 'business_unit_ids';
    }

    return payload;
  }
}
