import { Component, Input, OnChanges } from '@angular/core';
import {
  ChoiceFieldSource,
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  ValueDefinition,
  ValueDefinitionDisplayType,
} from '../../../../models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { OptionListsApiService } from '../../../../services/api-services';

@Component({
  selector: 'lib-metric-structure-choice-field',
  templateUrl: './metric-structure-choice-field.component.html',
  styleUrls: ['./metric-structure-choice-field.component.scss'],
})
export class MetricStructureChoiceFieldComponent implements OnChanges {
  @Input({ required: true }) valueDefinitionDisplayType!: ValueDefinitionDisplayType;
  @Input({ required: true }) valueDefinition!: ValueDefinition<ChoiceTypeDetails>;
  @Input({ required: true }) typeDetails!: ChoiceTypeDetails;
  @Input() isTriggered: boolean = false;
  @Input() isTrigger: boolean = false;
  readonly eValueDefinitionDisplayType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eChoiceWidgetType: typeof ChoiceFieldWidgetType = ChoiceFieldWidgetType;

  public dummyControl = new FormControl();
  public options$?: Observable<string[]> = of([]);

  constructor(private optionListsApiService: OptionListsApiService) {}

  ngOnChanges(): void {
    if (this.typeDetails.selection_set_id != null) {
      this.fetchOptions(this.typeDetails.selection_set_id, this.typeDetails.selection_set_source);
    } else {
      this.options$ = of([]);
    }
  }

  private fetchOptions(optionListId: string, source?: ChoiceFieldSource | null): void {
    this.options$ = this.optionListsApiService
      .listOptionListItemsFromSource(optionListId, { order_by: 'position', order_by_direction: 'asc' }, source)
      .pipe(map((res) => res.data.filter((item) => item.active).map((item) => item.name)));
  }
}
