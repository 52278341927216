import { Pipe, PipeTransform } from '@angular/core';
import { FieldAuditEvent } from '../../models';

@Pipe({
  name: 'castAsFieldAuditEvent',
})
export class CastAsFieldAuditEventPipe implements PipeTransform {
  // This is not tested. Typing doesn't exist in runtime, only in compile time, so it can't be tested.
  // This pipe was created to fix a typing issue in compile time using a variable coming from a template context.
  // Use example in the template: <app-child [data]="someObject | castAsFieldAuditEvent"></app-child>
  transform(value: unknown): FieldAuditEvent {
    return value as FieldAuditEvent;
  }
}
