import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardWidget, DashboardWidgetType, DEFAULT_COLOURS } from '../../../models';
import { DisplayGrid, GridType } from 'angular-gridster2';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardChartStore } from './dashboard-chart.store';
import { combineLatestWith, map, Observable } from 'rxjs';
import { ChartConfig } from '../../../models';

type Data = {
  isLoading: boolean;
  chart: ChartConfig;
};

enum Action {
  edit = 'edit',
  remove = 'remove',
}

@Component({
  selector: 'lib-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
  providers: [provideComponentStore(DashboardChartStore)],
})
export class DashboardChartComponent implements OnInit {
  @Input({ required: true }) dashboardWidget!: DashboardWidget;
  @Input() isEditing: boolean = false;

  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  public readonly eAction = Action;
  public readonly eDEFAULT_COLOURS = DEFAULT_COLOURS;
  public readonly eGridType = GridType;
  public readonly eDashboardWidgetType = DashboardWidgetType;
  public readonly eDisplayGrid = DisplayGrid;

  public data$?: Observable<Data>;

  constructor(private readonly dashboardChartStore: DashboardChartStore) {}

  public ngOnInit(): void {
    this.dashboardChartStore.init(this.dashboardWidget);
    this.data$ = this.dashboardChartStore.chart$.pipe(
      combineLatestWith(this.dashboardChartStore.isLoading$),
      map(([chart, isLoading]) => ({ chart, isLoading })),
    );
  }

  public onMenuClick(action: Action) {
    const emitter = action === Action.edit ? this.edit : this.remove;
    emitter.emit();
  }
}
