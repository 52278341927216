import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { ApiService } from '../../common';
import { ApiResponse, ApplicationApiDefinition, AttributeCheckResponse, DashboardDatum } from '../../../models';
import { DashboardDatumsApiService } from '../../api-services/dashboard-datums-api-service/dashboard-datums-api.service';

@Injectable({ providedIn: 'root' })
export class ClientPublicDashboardDatumsService extends DashboardDatumsApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dashboards;
  }

  public checkDashboardDatumName(): Observable<ApiResponse<AttributeCheckResponse>> {
    return EMPTY;
  }

  public createDashboardDatum(): Observable<ApiResponse<DashboardDatum>> {
    return EMPTY;
  }

  public editDashboardDatum(): Observable<ApiResponse<DashboardDatum>> {
    return EMPTY;
  }

  public deleteDashboardDatum(): Observable<undefined> {
    return EMPTY;
  }

  public listDashboardDatums(): Observable<ApiResponse<DashboardDatum[]>> {
    return EMPTY;
  }
}
