import { Pipe, PipeTransform } from '@angular/core';
import { ReportStatusTransition } from '../../models';

@Pipe({
  name: 'castAsReportStatusTransition',
})
export class CastAsReportStatusTransitionPipe implements PipeTransform {
  // This is not tested. Typing doesn't exist in runtime, only in compile time, so it can't be tested.
  // This pipe was created to fix a typing issue in compile time using a variable coming from a template context.
  // Use example in the template: <app-child [data]="someObject | castAsReportStatusTransition"></app-child>
  transform(value: unknown): ReportStatusTransition {
    return value as ReportStatusTransition;
  }
}
