export interface MentionItem {
  id: string;
  value: string;
  isCurrentUser: boolean;
  firstName: string;
  lastName: string;
  email: string;
  menuDisplayName: string;
  section: MentionItemSection;
  disabled?: boolean;
}

export enum MentionItemSection {
  requestManager = 'Request Manager',
  contributors = 'Contributors',
  reviewers = 'Reviewers',
  approvers = 'Approvers',
}
