import { Observable } from 'rxjs';

import {
  ApiResponse,
  AttributeCheckResponse,
  Dashboard,
  DashboardsFilteringOptions,
  DashboardUpsertRequest,
} from '../../../models';

export abstract class DashboardsApiService {
  public abstract checkDashboardName(name: string): Observable<ApiResponse<AttributeCheckResponse>>;

  public abstract copyDashboard(id: string, request: DashboardUpsertRequest): Observable<ApiResponse<Dashboard>>;

  public abstract createDashboard(request: DashboardUpsertRequest): Observable<ApiResponse<Dashboard>>;

  public abstract deleteDashboard(id: string): Observable<undefined>;

  public abstract getDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>>;

  public abstract getDefaultDashboard(): Observable<ApiResponse<Dashboard | null>>;

  public abstract listCoreDashboards(): Observable<ApiResponse<Dashboard[]>>;

  public abstract listDashboards(filters: DashboardsFilteringOptions): Observable<ApiResponse<Dashboard[]>>;

  public abstract publishDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>>;

  public abstract renameDashboard(id: string, request: DashboardUpsertRequest): Observable<ApiResponse<Dashboard>>;

  public abstract unpublishDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>>;
}
