import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../common';
import {
  ApiResponse,
  ApplicationApiDefinition,
  AttributeCheckResponse,
  DashboardDatum,
  UpsertDashboardDatumRequest,
} from '../../../models';
import { HttpParams } from '@angular/common/http';
import { DashboardDatumsApiService } from '../../api-services/dashboard-datums-api-service/dashboard-datums-api.service';

@Injectable({ providedIn: 'root' })
export class ClientDashboardDatumsService extends DashboardDatumsApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dashboards;
  }

  public checkDashboardDatumName(name: string): Observable<ApiResponse<AttributeCheckResponse>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboard_datums/name_check/${name}`);
  }

  public createDashboardDatum(payload: UpsertDashboardDatumRequest): Observable<ApiResponse<DashboardDatum>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/dashboard_datums`, payload);
  }

  public editDashboardDatum(
    dashboardDatumId: string,
    payload: UpsertDashboardDatumRequest,
  ): Observable<ApiResponse<DashboardDatum>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/dashboard_datums/${dashboardDatumId}`, payload);
  }

  public deleteDashboardDatum(dashboardDatumId: string): Observable<undefined> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/dashboard_datums/${dashboardDatumId}`);
  }

  public listDashboardDatums(
    query: string = '',
    page: number = 0,
    pageSize: number = 0,
  ): Observable<ApiResponse<DashboardDatum[]>> {
    let params = new HttpParams();

    if (typeof page === 'number') {
      params = params.append('page', page);
    }

    if (typeof pageSize === 'number') {
      params = params.append('page_size', pageSize);
    }

    if (query) {
      params = params.append('query', query);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/dashboard_datums`, { params });
  }
}
