import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castAs',
})
export class CastAsPipe implements PipeTransform {
  // This is not tested. Typing doesn't exist in runtime, only in compile time, so it can't be tested.
  // This pipe was created to fix a typing issue in compile time using a variable coming from a template context.
  // Use example in the template: <app-child [data]="someObject | cast: MyType"></app-child>
  transform<T>(value: unknown, _: new (...args: unknown[]) => T): T {
    return value as T;
  }
}
