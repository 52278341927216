@if (label !== "") {
  <label
    class="form-field-label label"
    [id]="id"
    [for]="for"
    [ngClass]="{
      required: required,
      disabled: control?.disabled,
      'force-same-size-labels': sameSizeLabels,
    }"
    data-testid="formFieldLabel"
  >
    <span>{{ label }}</span>

    <div class="icon-container">
      @if (fieldInfo) {
        <i data-testid="labelInfoIcon" class="color-primary-900 pi pi-info-circle info-icon" [pTooltip]="tooltip"></i>
      }
      @if (displayDeleteIcon()) {
        <a (click)="deleteValue()" data-testid="deleteIcon" class="pointer-event">
          <i class="color-primary-900 pi pi-undo field-clear ml-1"></i>
        </a>
      }
    </div>
  </label>
}

<ng-template #tooltip>
  {{ fieldInfo }}
</ng-template>
