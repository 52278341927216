import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperStep } from '../../../models';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterViewInit {
  @Input() selectedIndex = 0;
  @Input() steps: (StepperStep | null)[] = [];

  @Output() selectedIndexChange = new EventEmitter<number>();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  public selectionChange(event: number): void {
    this.selectedIndexChange.emit(event);
  }
}
