import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueDefinitionSize } from '../../../models';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'lib-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input() checked?: boolean;
  @Input() disabled: boolean = false;
  @Input() label?: string; // Label of the input on top of the switch
  @Input() switchLabel = ''; // Label of the switch on the right of it
  @Input() control?: UntypedFormControl;
  @Input() hint?: string;
  @Input() id: string = uuidv4();
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;

  @Output() switchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public uncontrolled: boolean = false;

  public ngOnInit(): void {
    this.uncontrolled = typeof this.checked === 'boolean';
  }
}
