import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { filter, Observable, of } from 'rxjs';
import { finalize, switchMap, takeWhile, tap } from 'rxjs/operators';

import {
  ActionItem,
  ApiResponse,
  ConsolidationRuleOptions,
  ConsolidationRules,
  consolidationTriggerFromRule,
  CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES,
  DeactivateEntityTypes,
  DialogResult,
  DialogSize,
  Doc,
  isDBConsolidationEnabled,
  MAXIMUM_BYPASS_DEPTH,
  Metric,
  MetricCategory,
  SOURCE_CONFIGURATION,
  Status,
  TipIcon,
  TipIconConfig,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionGroup,
  ValueDefinitionTemplate,
  ValueDefinitionTemplateType,
  ValueDefinitionType,
} from '../../../../models';

import { ValueDefinitionTemplateService } from '../../../services/value-definition-template.service';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { DynamicForm, ObservableUtils } from '../../../../classes';
import { MetricStructureFieldPropertiesFormService } from './forms/metric-structure-field-properties-form.service';
import { MetricStructureFieldFormModel } from './forms/metric-structure-field-properties-form-configs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import { MetricApiService } from '../../../../services/types';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { ValueDefinitionUtils } from '../../../classes/ValueDefinitionUtils/value-definition-utils';
import {
  ConsolidationManualDialogComponent,
  ConsolidationManualDialogResults,
} from '../../consolidation-manual-dialog/consolidation-manual-dialog.component';
import { CurtainStateService, TranslateService } from '../../../../services/common';
import { ErrorManagerService } from '../../../../error-manager';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { FeatureFlagService } from '../../../../feature-flag';
import { MetricStructureStore } from '../../../metric-structure.store';

@Component({
  selector: 'lib-metric-structure-field-properties',
  templateUrl: './metric-structure-field-properties.component.html',
  styleUrls: ['./metric-structure-field-properties.component.scss'],
  providers: [MetricStructureFieldPropertiesFormService],
})
export class MetricStructureFieldPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) valueDefinition!: ValueDefinition;
  @Input() formDisabled: boolean = false;
  @Input() metric?: Metric;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('fieldsContainer') fieldsContainer?: ElementRef<HTMLDivElement>;

  updating$: Observable<boolean>;
  isRepeatableGroup$: Observable<boolean>;
  valueDefinitionGroup?: ValueDefinitionGroup;
  allLevelsSelectedMessage: string | undefined = undefined;

  dynamicFieldForm$: Observable<DynamicForm<MetricStructureFieldFormModel> | undefined> =
    this.metricStructureFieldPropertiesFormService.dynamicFieldForm$;
  unitFamilies$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.unitFamilies$;
  unitDefaults$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.unitDefaults$;
  allUnits$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.allUnits$;
  areConsolidationParamsChanged: boolean = false;
  fieldHeaderDetails: ValueDefinitionTemplate | undefined;

  readonly contextFieldPermittedValueDefinitionTypes = CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES;
  readonly dateOptionsAnswers: ActionItem[] = [
    {
      id: 'YYYY-MM-DD',
      title: this.translateService.instant('YYYY-MM-DD'),
    },
  ];
  readonly numericDecimalOptions = Array(11)
    .fill(null)
    .map((_, i) => ({ id: `${i}`, title: `${i}` })) as ActionItem[];

  readonly characterLimitErrorMsgs: ValidationErrors = {
    notNumeric: this.translateService.instant('Please enter a positive number'),
  };
  readonly numberMinMaxErrorMsgs: ValidationErrors = {
    minMax: this.translateService.instant('Min cannot be greater than max'),
    maxMin: this.translateService.instant('Max cannot be less than min'),
    tooManyVariables: this.translateService.instant('Too many variables in formula'),
  };
  readonly filesLimitErrorMsgs: ValidationErrors = {
    max: this.translateService.instant('File attachment limit cannot be greater than 50'),
    min: this.translateService.instant('File attachment limit cannot be lesser than 1'),
    minMaxFiles: this.translateService.instant('Published file attachment limit can only be increased'),
  };
  readonly displaySizeErrorMsgs: ValidationErrors = {
    required: this.translateService.instant('Display size is required'),
  };

  readonly dateErrorMessages: ValidationErrors = {
    maxLessThanMin: this.translateService.instant('Maximum date must be later than minimum date.'),
    minGreaterThanMax: this.translateService.instant('Minimum date must be earlier than maximum date.'),
  };

  readonly calculationChangedMsg: string = this.translateService.instant(
    'Changes to the configuration rules may result in completed metrics being set back to in progress should their values be modified.',
  );

  fieldsThatCanChangeType = [ValueDefinitionType.number];
  fieldsThatWarnWhenChanged = [ValueDefinitionType.calculated];
  fieldsWithLongRunningUpdate = [ValueDefinitionType.calculated];
  canEditEveryMetrics$?: Observable<boolean>;

  readonly valueDefinitionTemplates: ValueDefinitionTemplate[];
  readonly eValueDefinitionDisplayType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eValueDefinitionTemplateType: typeof ValueDefinitionTemplateType = ValueDefinitionTemplateType;
  readonly valueDefinitionTypeSizes = this.valueDefinitionTemplateService.valueDefinitionTypeSizes;
  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly tipIcon: TipIconConfig = TipIcon.info;
  readonly isAdmin: boolean = this.metricStructureService.isAdmin;
  readonly eConsolidationRules: typeof ConsolidationRules = ConsolidationRules;
  readonly eSourceConfiguration: typeof SOURCE_CONFIGURATION = SOURCE_CONFIGURATION;

  public consolidationRuleOptions: ActionItem[] = [];
  public consolidationTriggerOptions: ActionItem[] = [];
  public consolidationBypassLevelOptions: ActionItem[] = [...Array(MAXIMUM_BYPASS_DEPTH).keys()]
    .map((i) => i + 1)
    .map((i) => ({ id: i.toString(), title: i.toString() }));
  public deactivationEnabled: boolean = false;

  constructor(
    private metricStructureService: MetricStructureStateService,
    private valueDefinitionTemplateService: ValueDefinitionTemplateService,
    private metricsService: MetricApiService,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private metricStructureFieldPropertiesFormService: MetricStructureFieldPropertiesFormService,
    private errorManagerService: ErrorManagerService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService,
    private curtainStateService: CurtainStateService,
    private featureFlagService: FeatureFlagService,
    private metricStructureStore: MetricStructureStore,
  ) {
    this.consolidationRuleOptions = ConsolidationRuleOptions;
    this.valueDefinitionTemplates = this.valueDefinitionTemplateService.getValueDefinitionTemplates();
    this.isRepeatableGroup$ = this.metricStructureService.isRepeatableGroup$;
    this.updating$ = this.metricStructureService.isMetricUpdating$;
  }

  ngOnInit(): void {
    this.deactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_deactivation_enabled',
    ]);
  }

  ngOnChanges(): void {
    this.metricStructureFieldPropertiesFormService.clearValueDefinitionForm();
    this.metricStructureFieldPropertiesFormService.initializeValueDefinitionForm(this.valueDefinition, this.metric);
    this.updateIsRepeatableGroup();

    if (this.fieldsContainer) {
      this.fieldsContainer.nativeElement.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    this.fieldHeaderDetails = this.getFieldHeaderDetails();
    this.checkConsolidationParamsChanged();
    this.consolidationTriggerOptions = consolidationTriggerFromRule(this.valueDefinition.consolidation_rule);
    this.setAllLevelsSelectedMessage(this.valueDefinition.bypass_consolidation_levels);
  }

  ngOnDestroy(): void {
    this.cleanTemporaryField();
    this.metricStructureFieldPropertiesFormService.clearValueDefinitionForm();
  }

  private setUpdating(updating: boolean, valueDefinition: ValueDefinition): void {
    this.metricStructureService.updateIsMetricUpdating(updating);

    if (this.fieldsWithLongRunningUpdate.includes(valueDefinition.type)) {
      if (updating) {
        const message = this.translateService.instant('Please wait while we apply all changes...');
        this.curtainStateService.openCurtain(message);
      } else {
        this.curtainStateService.closeCurtain();
      }
    }
  }

  public updateDocResource(doc: Doc): void {
    this.metricStructureService.addDocumentsToTheList([doc]);
  }

  public disableDisplay(): void {
    this.metricStructureFieldPropertiesFormService.toggleMultipleLine();
  }

  public handleAllowUrlAsFile(): void {
    this.metricStructureFieldPropertiesFormService.handleAllowUrlAsFile();
  }

  public handleAllowAttachingDocument(): void {
    this.metricStructureFieldPropertiesFormService.handleAllowAttachingDocument();
  }

  public getFieldType(type: string[]): boolean {
    const currentValueDefinitionType = this.valueDefinitionDisplayType;
    return type.some((t) => t === currentValueDefinitionType);
  }

  public getFieldHeaderDetails(): ValueDefinitionTemplate | undefined {
    const fieldTemplates = this.valueDefinitionTemplates.filter((x) => !['category', 'group'].includes(x.type));
    switch (this.valueDefinitionDisplayType) {
      case ValueDefinitionDisplayType.text_area:
      case ValueDefinitionDisplayType.text_simple:
        return fieldTemplates.find((x) => x.code === 'textarea');
      case ValueDefinitionDisplayType.text_rich:
        return fieldTemplates.find((x) => x.code === 'rich-text');
      case ValueDefinitionDisplayType.number:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.number);
      case ValueDefinitionDisplayType.date:
      case ValueDefinitionDisplayType.datetime:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.date);
      case ValueDefinitionDisplayType.boolean:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.boolean);
      case ValueDefinitionDisplayType.choice:
      case ValueDefinitionDisplayType.choice_multiple:
      case ValueDefinitionDisplayType.choice_searchable:
      case ValueDefinitionDisplayType.choice_radio:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.choice);
      case ValueDefinitionDisplayType.file_v2:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.file_v2);
      default:
        return fieldTemplates.find((x) => x.type === this.valueDefinition.type);
    }
  }

  public deleteField(event: MouseEvent): void {
    event.stopPropagation();
    const valueDefinitions: ValueDefinition[] | undefined = this.metric?.value_definition_groups?.find(
      (x) => this.valueDefinition.value_definition_group_id === x.id,
    )?.value_definitions;
    if (valueDefinitions) {
      const index = valueDefinitions.findIndex((x) => x.id === this.valueDefinition.id);

      const valueDefinition: ValueDefinition = valueDefinitions[index];
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Delete field'),
            warningMsg: this.translateService.instant(
              valueDefinition.type === ValueDefinitionType.calculated
                ? 'Please note that deleting this calculated field will also delete any stored calculated values. The data from the variables in the formula are untouched.'
                : 'Are you sure you wish to delete this field? All the data associated with it will be lost.',
            ),
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.CONFIRMED),
          switchMap(() => {
            if (valueDefinition.id !== ValueDefinitionTemplateType.template) {
              this.setUpdating(true, valueDefinition);
              return this.metricsService.deleteField(
                this.metric?.id as string,
                valueDefinition.value_definition_group_id,
                valueDefinition.id,
              );
            }
            return of();
          }),
          finalize(() => this.setUpdating(false, valueDefinition)),
        )
        .subscribe({
          next: () => {
            valueDefinitions.splice(index, 1);
            this.closeProperties();
          },
          error: (errorResponse: unknown) => {
            try {
              this.metricStructureService.handleDeleteValidationErrors(errorResponse as HttpErrorResponse);
            } catch (_) {
              this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
            }
          },
        });
    }
  }

  public saveField(): void {
    if (!this.metricStructureFieldPropertiesFormService.isValid) {
      return;
    }
    const fieldDefinition: ValueDefinition = this.metricStructureFieldPropertiesFormService.toValueDefinition(
      this.valueDefinition,
    );

    if (
      fieldDefinition.consolidation_rule === ConsolidationRules.manual &&
      fieldDefinition.consolidation_rule !== this.valueDefinition.consolidation_rule
    ) {
      return this.triggerConsolidationManualDialog(fieldDefinition);
    }

    this.updateField(fieldDefinition, false);
  }

  private triggerConsolidationManualDialog(fieldDefinition: ValueDefinition): void {
    this.dialogsService
      .open<ConsolidationManualDialogComponent>(ConsolidationManualDialogComponent, {
        data: {
          size: DialogSize.small,
        },
      })
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter(
          (dialogResult: DialogResult<ConsolidationManualDialogResults>) => dialogResult.status === Status.SUCCESS,
        ),
      )
      .subscribe((dialogResult: DialogResult<ConsolidationManualDialogResults>) => {
        this.updateField(fieldDefinition, dialogResult.data?.reset_consolidated_values || false);
      });
  }

  public isConsolidationEnabled(sourceConfiguration: SOURCE_CONFIGURATION): boolean {
    return isDBConsolidationEnabled(sourceConfiguration);
  }

  public updateField(valueDefinition: ValueDefinition, reset_consolidated_values: boolean): void {
    const canChangeType = this.fieldsThatCanChangeType.includes(valueDefinition.type);
    this.setUpdating(true, valueDefinition);
    const payload = this.metricStructureService.getFieldPayload(valueDefinition, true, canChangeType);
    this.sendFieldUpdate(valueDefinition, { ...payload, reset_consolidated_values });
  }

  public addField() {
    let selectedItem: ValueDefinition = JSON.parse(JSON.stringify(this.valueDefinition)) as ValueDefinition;

    const get_and_create_vd = () => {
      const valueDefGroup = this.metric?.value_definition_groups?.find(
        (x: ValueDefinitionGroup) => x.id === selectedItem.value_definition_group_id,
      ) as ValueDefinitionGroup;
      selectedItem = this.metricStructureFieldPropertiesFormService.toValueDefinition(selectedItem);
      this.createField(valueDefGroup.id, selectedItem, selectedItem.position ? selectedItem.position - 1 : 0);
    };

    if (selectedItem.type == ValueDefinitionType.calculated) {
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            primaryBtn: this.translateService.instant('Add'),
            warningMsg: this.translateService.instant(
              'If this metric has been marked as complete in an open fiscal year, the status will be set back to in progress as a result of adding this new calculated field.',
            ),
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.CONFIRMED),
          tap(() => {
            get_and_create_vd();
          }),
        )
        .subscribe();
    } else {
      get_and_create_vd();
    }
  }

  private createField(valueDefinitionGroupId: string, valueDefinition: ValueDefinition, itemIndex: number): void {
    this.setUpdating(true, valueDefinition);
    const payload = this.metricStructureService.getFieldPayload(valueDefinition);
    this.metricsService
      .createField(this.metric!.id, valueDefinitionGroupId, payload)
      .pipe(
        tap((result) => {
          const valueDefinitions = result.data.value_definition_groups?.find(
            (vdg) => vdg.id === valueDefinitionGroupId,
          )?.value_definitions;
          if (valueDefinitions) {
            this.metricStructureService.updateSelectedItem(valueDefinitions[itemIndex]);
          }
          this.updateMetric(result.data);
          this.metricStructureService.setIsCreatingField(false);
          this.metricStructureStore.updateMetric(result.data);
          this.metricStructureStore.updateFieldsVisibilityEffect();
        }),
        finalize(() => this.setUpdating(false, valueDefinition)),
      )
      .subscribe();
  }

  public closeProperties(): void {
    this.closePanel.emit();
  }

  public selectTipDisplayOption(item: ActionItem): void {
    this.metricStructureFieldPropertiesFormService.tipDisplayOption = item;
  }

  public selectNumberType(numberType: string): void {
    this.metricStructureFieldPropertiesFormService.setNumberTypeValidation(numberType);
  }

  public toggleMaxFiles(isMultipleFiles: boolean): void {
    this.metricStructureFieldPropertiesFormService.setIsMultipleFiles(isMultipleFiles);
  }

  public updateCustomChoiceAnswers(customChoiceAnswers: ActionItem[]): void {
    this.metricStructureFieldPropertiesFormService.customChoiceAnswers = customChoiceAnswers;
  }

  getTipDisplayOptions(): ActionItem[] {
    return this.metricStructureService.tipDisplayOptions;
  }

  public getConsolidationTriggerOptions(): ActionItem[] {
    return this.consolidationTriggerOptions;
  }

  get valueDefinitionDisplayType(): ValueDefinitionDisplayType {
    return ValueDefinitionUtils.getValueDefinitionFormat(this.valueDefinition).type;
  }

  public setConsolidationOptions(consolidationRule: ConsolidationRules): void {
    this.consolidationTriggerOptions = consolidationTriggerFromRule(consolidationRule);
    this.metricStructureFieldPropertiesFormService.setConsolidationTrigger(consolidationRule);
    if (consolidationRule === ConsolidationRules.manual) {
      this.metricStructureFieldPropertiesFormService.resetBypassConsolidationLevels();
      this.allLevelsSelectedMessage = undefined;
    }
    this.checkConsolidationParamsChanged();
  }

  public checkConsolidationParamsChanged(): void {
    if (this.sourceConfiguration !== SOURCE_CONFIGURATION.single_source) {
      this.areConsolidationParamsChanged =
        this.metricStructureFieldPropertiesFormService.areConsolidationParamsChanged();
    }
  }

  private setAllLevelsSelectedMessage(bypassConsolidationLevels: string[] | null | undefined): void {
    if (bypassConsolidationLevels?.length === MAXIMUM_BYPASS_DEPTH) {
      this.allLevelsSelectedMessage = this.translateService.instant(
        'Consider setting the Consolidation rule to “No calculation - allow manual entry” instead',
      );
    } else {
      this.allLevelsSelectedMessage = undefined;
    }
  }

  protected handleBypassConsolidationLevelsSelect(event: MultiSelectChangeEvent): void {
    this.checkConsolidationParamsChanged();
    this.setAllLevelsSelectedMessage(event.value as string[] | null | undefined);
  }

  public isThirdParty(): boolean {
    return this.metric?.category === MetricCategory.THIRD_PARTY;
  }

  private cleanTemporaryField(): void {
    if (this.valueDefinition.id === ValueDefinitionTemplateType.template) {
      setTimeout(() => {
        this.metricStructureService.removeTemporaryField(
          this.valueDefinition,
          this.valueDefinition.position ? this.valueDefinition.position - 1 : 0,
        );

        this.metricStructureService.setIsCreatingField(false);
      });
    }
  }

  private handleFieldDeactivation(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.FIELD,
      this.metric?.id ?? '',
      this.valueDefinition,
      this.valueDefinitionGroup,
    );
  }

  public deactivateField(): void {
    if (!this.isAdmin || (this.isAdmin && !this.metric?.reference_v2)) {
      this.handleFieldDeactivation();
      return;
    }
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Deactivate field'),
          warningMsg: this.translateService.instant(
            'Deactivated fields will sync with Platform tonight, deactivating them in customer environments. Platform users cannot activate fields deactivated in Core.',
          ),
          primaryBtn: this.translateService.instant('Deactivate'),
        },
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result?.status === Status.CONFIRMED) {
            this.handleFieldDeactivation();
            this.setUpdating(false, this.valueDefinition);
          }
        },
        error: (errorResponse: unknown) => {
          this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
        },
      });
  }

  private updateMetric(metric: Metric): void {
    metric.related_metrics = this.metric?.related_metrics;
    this.metricStructureService.updateMetric(metric);
  }

  activateField(): void {
    this.activateEntityService.activateValueDefinition(this.metric?.id ?? '', this.valueDefinition).subscribe();
  }

  private sendFieldUpdate(valueDefinition: ValueDefinition, payload: Record<string, unknown>): void {
    if (this.metric) {
      this.metricsService
        .updateField(this.metric.id, valueDefinition.value_definition_group_id, valueDefinition.id, payload)
        .pipe(finalize(() => this.setUpdating(false, valueDefinition)))
        .subscribe({
          next: (res: ApiResponse<Metric>) => {
            this.updateMetric(res.data);
            const vds = res.data.value_definition_groups?.flatMap((vdg) => vdg.value_definitions || []) || [];
            this.valueDefinition = vds.find((vd) => vd.id === valueDefinition.id) || valueDefinition;
            this.metricStructureService.updateSelectedItem(this.valueDefinition);
          },
          error: (errorResponse: unknown) => {
            try {
              this.metricStructureService.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
            } catch (_) {
              this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
            }
          },
        });
    }
  }
  private updateIsRepeatableGroup(): void {
    this.valueDefinitionGroup = this.metric?.value_definition_groups?.find(
      (valueDefinitionGroup) => valueDefinitionGroup.id == this.valueDefinition.value_definition_group_id,
    );
    this.metricStructureService.updateIsRepeatableGroup(this.valueDefinitionGroup?.repeatable ?? false);
  }
}
