import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'lib-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
})
export class PopupMenuComponent<T extends MenuItem = MenuItem> {
  @Input() icon: string = 'ellipsis-v';
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() menuItems?: T[];
  @Input() template?: TemplateRef<{ item: T }>;
  @Input() buttonTemplate?: TemplateRef<{ actionMenu: Menu }>;

  @Output() action = new EventEmitter<T>();
}
