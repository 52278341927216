<lib-dialog-title [title]="'Rename' | translate" (closeEvent)="close()"> </lib-dialog-title>

<div mat-dialog-content class="mat-dialog-content">
  <div class="form">
    @if (form) {
      <lib-text-input
        class="formfield large"
        [control]="form.controls.label"
        [label]="'Edit data point name' | translate"
        [maxCharacterCount]="60"
        [messages]="{ isUnique: 'A data point with this label already exists, choose a different label.' | translate }"
      ></lib-text-input>
    }
  </div>
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="primary" [disabled]="form?.invalid | boolean" (click)="save()">
    {{ "Save" | translate }}
  </button>

  <button lib-button class="ml-2" buttonType="cancel_with_border" (click)="close()">
    {{ "Cancel" | translate }}
  </button>
</div>
