import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import {
  ActionItem,
  ApiResponse,
  DashboardDatum,
  DashboardWidget,
  DashboardWidgetType,
  SINGLE_DATUMS_WIDGETS,
  SINGLE_SIZED_WIDGETS,
} from '../../../models';
import { DashboardWidgetUpsertForm } from './dashboard-widget-upsert.form';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardWidgetUpsertSidebarStore } from './dashboard-widget-upsert-sidebar.store';
import { combineLatestWith, map, Observable } from 'rxjs';
import {
  DashboardDatumsApiService,
  DashboardsApiService,
  DashboardWidgetsApiService,
} from '../../../services/api-services';
import { DashboardWidgetTypeToText } from '../../dashboards.translation';
import { DashboardViewerStore } from '../dashboard-viewer.store';
import { DashboardsHelper } from '../../dashboards-helper';
import { DialogsService } from '../../../dialogs';
import { TranslateService } from '../../../services/common';
import { ToastService } from '../../../components';
import { DashboardGridOptions } from '../../dashboard-grid/dashboard-grid.component';

type Data = {
  dashboardDatums: ActionItem<DashboardDatum>[];
  isLoading: boolean;
};

export interface DashboardWidgetUpsertSidebarData {
  dashboardWidget?: DashboardWidget;
  dashboardId: string;
  columnLocation?: number;
  rowLocation?: number;
  widgetType: DashboardWidgetType;
}

@Component({
  selector: 'lib-dashboard-widget-upsert-sidebar',
  templateUrl: './dashboard-widget-upsert-sidebar.component.html',
  styleUrls: ['./dashboard-widget-upsert-sidebar.component.scss'],
  providers: [provideComponentStore(DashboardWidgetUpsertSidebarStore)],
})
export class DashboardWidgetUpsertSidebarComponent implements OnInit {
  @Input({ required: true }) sidebarData!: DashboardWidgetUpsertSidebarData;
  @Input() options: DashboardGridOptions = {};

  @Output() save = new EventEmitter<DashboardWidget>();

  public readonly eDashboardWidgetType = DashboardWidgetType;
  public readonly eDashboardWidgetTypeToText: Record<string, string> = DashboardWidgetTypeToText;
  public readonly eSINGLE_DATUMS_WIDGETS = SINGLE_DATUMS_WIDGETS;

  public data$?: Observable<Data>;
  public form?: DashboardWidgetUpsertForm;
  private dashboardsHelper: DashboardsHelper;

  constructor(
    readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dashboardViewerStore: DashboardViewerStore,
    readonly dashboardsApiService: DashboardsApiService,
    private readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
    private readonly dashboardWidgetUpsertSidebarStore: DashboardWidgetUpsertSidebarStore,
    readonly dialogsService: DialogsService,
    readonly toastService: ToastService,
    readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.dashboardsHelper = new DashboardsHelper(
      dashboardsApiService,
      dashboardWidgetsApiService,
      dashboardDatumsApiService,
      dialogsService,
      toastService,
      translateService,
    );
  }

  public ngOnInit(): void {
    this.form = new DashboardWidgetUpsertForm(this.sidebarData.widgetType, this.sidebarData.dashboardWidget);

    this.data$ = this.dashboardWidgetUpsertSidebarStore.dashboardDatums$.pipe(
      combineLatestWith(this.dashboardWidgetUpsertSidebarStore.isLoading$),
      map(([dashboardDatums, isLoading]) => ({ dashboardDatums, isLoading })),
    );
  }

  public addMore(): void {
    this.dashboardsHelper.upsertDatum({ options: this.options }, this.viewContainerRef).subscribe(() => {
      this.dashboardWidgetUpsertSidebarStore.fetchDashboardDatums();
    });
  }

  public close(visibility?: boolean): void {
    if (!visibility) {
      this.dashboardViewerStore.updateSidebarDataState(undefined);
    }
  }

  public onSave(): void {
    if (!this.form) {
      return;
    }

    const request = this.form.toModel(
      this.sidebarData.widgetType,
      this.sidebarData.dashboardWidget?.column_location ?? this.sidebarData.columnLocation ?? 0,
      this.sidebarData.dashboardWidget?.row_location ?? this.sidebarData.rowLocation ?? 0,
      this.sidebarData.dashboardWidget?.width ?? (SINGLE_SIZED_WIDGETS.includes(this.sidebarData.widgetType) ? 1 : 2),
      this.sidebarData.dashboardWidget?.height ?? 1,
    );

    const obs = this.sidebarData.dashboardWidget
      ? this.dashboardWidgetsApiService.updateWidget(
          this.sidebarData.dashboardId,
          this.sidebarData.dashboardWidget.id,
          request,
        )
      : this.dashboardWidgetsApiService.createWidget(this.sidebarData.dashboardId, request);

    obs.subscribe((response: ApiResponse<DashboardWidget>) => {
      this.save.emit(response.data);
      this.close();
    });
  }
}
