import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { MentionItem } from '../../../models';
import { StyledMentionBlot } from './mention-blot';

@Component({
  selector: 'lib-mention',
  templateUrl: './mention.component.html',
  styleUrl: './mention.component.scss',
  providers: [StyledMentionBlot],
})
export class MentionComponent implements OnDestroy {
  @Input() control?: FormControl;
  @Input() userMentionItems: MentionItem[] = [];
  @Input() currentUserId: string = '';
  @Input() hint: string = '';
  @Input() menuOrientation: 'top' | 'bottom' = 'bottom';
  @Input() placeholder: string = '';
  @Input() size: 'small' | 'large' = 'large';

  @ViewChild('editor') editor?: QuillEditorComponent;

  modules = {
    mention: {
      mentionDenotationChars: ['@'],
      blotName: 'styled-mention',
      allowedChars: /^[A-Za-z\s]*$/,
      defaultMenuOrientation: this.menuOrientation,
      source: (searchTerm: string, renderList: (data: any[], searchTerm: string) => void) => {
        const matches = this.userMentionItems.filter((user) =>
          user.value.toLowerCase().startsWith(searchTerm.toLowerCase()),
        );
        const mentionItemsBySection = this.groupItemsByRole(matches);
        const renderedItems = [];
        for (const [section, items] of Object.entries(mentionItemsBySection)) {
          renderedItems.push({
            id: '',
            value: '',
            isCurrentUser: false,
            firstName: '',
            lastName: '',
            email: '',
            menuDisplayName: '',
            section,
            disabled: true,
          });
          renderedItems.push(...items);
        }
        renderList(renderedItems, searchTerm);
      },
      renderItem: (item: MentionItem) => {
        const div = document.createElement('div');
        if (item.disabled) {
          div.innerHTML = `
          <div>
            <p class="mention-role-category-header">${item.section}</p>
          </div>`;
        } else {
          div.setAttribute('class', 'user-tag-container');
          div.innerHTML = `
          <div class="initials">${item.firstName.charAt(0).toUpperCase() ?? ''}${item.lastName.charAt(0).toUpperCase() ?? ''}</div>
          <div class="details">
            <p>${item.menuDisplayName}</p>
            <p class="subtitle">${item.email}</p>
          </div>`;
        }
        return div;
      },
      positioningStrategy: 'fixed',
      dataAttributes: [
        'id',
        'value',
        'isCurrentUser',
        'firstName',
        'lastName',
        'email',
        'menuDisplayName',
        'section',
        'disabled',
      ],
    },
    toolbar: false,
  };

  private groupItemsByRole(items: MentionItem[]): { [section: string]: MentionItem[] } {
    return items.reduce(
      (mentionItemsBySection, item) => {
        if (!mentionItemsBySection[item.section]) {
          mentionItemsBySection[item.section] = [];
        }
        mentionItemsBySection[item.section].push(item);
        return mentionItemsBySection;
      },
      {} as { [section: string]: MentionItem[] },
    );
  }

  ngOnDestroy(): void {
    if (this.editor?.quillEditor) {
      this.editor.quillEditor.blur();
    }
  }
}
