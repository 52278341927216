import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardWidget, Source } from '../../models';
import { GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashboardStore } from './dashboard.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';

export interface DashboardOptions {
  initialStart?: string;
  initialEnd?: string;
  initialSource?: Source;
  initialMultiSource?: Source[];
  frequencyValueChanges?: Observable<string>;
  endValueChanges?: Observable<string>;
  startValueChanges?: Observable<string>;
  sourceValueChanges?: Observable<string | null | undefined>;
  multiSourceValueChanges?: Observable<
    string[] | Required<Pick<TreeNode<Source>, 'label' | 'key'>>[] | null | undefined
  >;
  sources?: Source[];
}

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [provideComponentStore(DashboardStore)],
})
export class DashboardComponent implements OnInit {
  @Input() isEditing: boolean = false;
  @Input({ required: true }) gridsterConfig!: GridsterConfig;
  @Input() options: DashboardOptions = {};
  @Input() widgets: DashboardWidget[] = [];

  @Output() edit: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();
  @Output() itemChange = new EventEmitter<{ item: GridsterItem; itemComponent: GridsterItemComponentInterface }>();
  @Output() remove: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();

  constructor(private readonly dashboardStore: DashboardStore) {}

  public ngOnInit(): void {
    this.dashboardStore.init(this.options);
  }
}
