import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, AttributeCheckResponse, Dashboard, LimitedUser } from '../../../models';
import { ApiService } from '../../common';
import { EMPTY, Observable } from 'rxjs';
import { HttpContext } from '@angular/common/http';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';
import { DashboardsApiService } from '../../api-services';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicDashboardsService extends DashboardsApiService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.dashboards;
  }

  public getDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`, {
      context: new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    });
  }

  public getDashboardAuthor(dashboardId: string): Observable<ApiResponse<LimitedUser>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/${dashboardId}/author`, {
      context: new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    });
  }

  public checkDashboardName(): Observable<ApiResponse<AttributeCheckResponse>> {
    return EMPTY;
  }

  public copyDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }

  public createDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }

  public deleteDashboard(): Observable<undefined> {
    return EMPTY;
  }

  public getDefaultDashboard(): Observable<ApiResponse<Dashboard | null>> {
    return EMPTY;
  }

  public listCoreDashboards(): Observable<ApiResponse<Dashboard[]>> {
    return EMPTY;
  }

  public listDashboards(): Observable<ApiResponse<Dashboard[]>> {
    return EMPTY;
  }

  public publishDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }

  public renameDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }

  public unpublishDashboard(): Observable<ApiResponse<Dashboard>> {
    return EMPTY;
  }
}
