<p-progressBar
  [value]="progress"
  [showValue]="showValue"
  [styleClass]="sourceClass"
  [ngClass]="{ 'label-zero-active': progress === 0 }"
  [mode]="mode"
>
  @if (showValue) {
    <ng-template pTemplate="value">
      <div class="progress-label">{{ progress }}%</div>
    </ng-template>
  }
</p-progressBar>
