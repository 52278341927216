import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import {
  ActionItem,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  ResourceType,
  SOURCE_CONFIGURATION,
  DEFAULT_SOURCE_CONFIGURATION,
} from '../../models';
import { TranslateService, FilterService } from '../../services/common';
import { ButtonProps, SearchableOptionProps } from '../models';

interface SearchFilterOption {
  optionType: FilterType;
  filterOption: SearchableOptionProps;
  buttonOption: ButtonProps;
  multiSelect: boolean;
  prefix?: string;
}

@Component({
  selector: 'lib-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit, OnChanges {
  @Input() initialSearch?: string;
  @Input() withPadding: boolean = true;
  @Input() withSearchBar: boolean = false;
  @Input() primaryFilters: FilterBarOption[] = [];
  @Input() secondaryFilters: FilterBarOption[] = [];
  @Input() defaultSecondaryFilters: { [key: string]: string } = {};
  @Input() searchBarPlaceholder: string = this.translateService.instant('Search');
  @Input() sourceConfiguration: SOURCE_CONFIGURATION = DEFAULT_SOURCE_CONFIGURATION;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilterChanged = new EventEmitter<FilterBarSelection[]>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChanged = new EventEmitter<string>();
  @Output() searchTriggered = new EventEmitter<string>();

  FilterType = FilterType;

  hasSelectedFilters = false;
  primaryOptions: FilterBarOption[] = [];
  secondaryOptions: FilterBarOption[] = [];
  primaryFilteringOptions: SearchFilterOption[] = [];
  secondaryFilteringOptions: SearchFilterOption[] = [];
  selectedOptions: FilterBarSelection[] = [];

  constructor(
    private readonly filterService: FilterService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initialize(this.defaultSecondaryFilters);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.secondaryFilters?.previousValue?.length &&
      !isEqual(changes.secondaryFilters.previousValue, changes.secondaryFilters.currentValue)
    ) {
      this.initialize();
    }
  }

  optionSelected(selectedOption: ActionItem, filterOption: SearchFilterOption): void {
    const index = this.selectedOptions.findIndex((option) => option.id === filterOption.filterOption.id);

    if (this.selectedOptions[index].selection.some((selection) => selection.id == selectedOption.id)) {
      return;
    }

    this.selectedOptions[index].selection = [selectedOption];
    this.filterChanged();
  }

  optionsSelected(selectedOptions: ActionItem[], filterOption: SearchFilterOption): void {
    const index = this.selectedOptions.findIndex((option) => option.id === filterOption.filterOption.id);

    this.selectedOptions[index].selection = selectedOptions;

    this.filterChanged();
  }

  resetFilters(): void {
    this.primaryOptions = this.resetMultiSelectFilters(this.primaryOptions);

    const primaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.primaryOptions, true);
    const secondaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.secondaryOptions);

    this.selectedOptions = [...primaryOptions, ...secondaryOptions];

    this.filterChanged();
  }

  private initialize(defaultSecondaryFilters: { [key: string]: string } = {}): void {
    this.primaryOptions = this.primaryFilters;
    this.secondaryOptions = this.secondaryFilters;

    this.setupSelectedOptions(defaultSecondaryFilters);
    this.setupOptions();
  }

  private filterChanged() {
    this.hasSelectedFilters = this.hasSelectedOption();
    this.setupOptions();

    this.onFilterChanged.emit(this.selectedOptions);
  }

  private getOptionTitleFromId(filterId: string, optionId: string, isPrimaryFilter: boolean): string {
    const filterBarOptions = isPrimaryFilter ? this.primaryOptions : this.secondaryOptions;

    const filterBarOption = filterBarOptions.find(
      (filterBarOption: FilterBarOption) => filterBarOption.id === filterId,
    );

    if (!filterBarOption) {
      return 'All'; // Handle case where filterBarOption is not found
    }

    const option = filterBarOption.options.find((option) => option.id === optionId);

    return option?.title ?? 'All'; // Return 'All' if option is not found or title is missing
  }

  private setupSelectedOptions(defaultSecondaryFilters: { [key: string]: string } = {}) {
    const primaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.primaryOptions, true, true);
    const secondaryOptionsWithSelection = this.secondaryOptions.map((filterBarOption: FilterBarOption) => {
      if (filterBarOption.id in defaultSecondaryFilters) {
        const optionId = defaultSecondaryFilters[filterBarOption.id];
        const optionTitle: string = this.getOptionTitleFromId(filterBarOption.id, optionId, false);
        return { ...filterBarOption, selectedValue: optionTitle };
      }
      return { ...filterBarOption };
    });
    const secondaryOptions = this.mapFilterBarOptionsToFilterBarSelection(secondaryOptionsWithSelection, false, true);

    this.selectedOptions = [...primaryOptions, ...secondaryOptions];

    this.hasSelectedFilters = this.hasSelectedOption();
  }

  private setupOptions() {
    this.primaryFilteringOptions = this.mapFilterBarOptionsToSearchFilterOption(this.primaryOptions);
    this.secondaryFilteringOptions = this.mapFilterBarOptionsToSearchFilterOption(this.secondaryOptions);
  }

  private mapFilterBarOptionsToFilterBarSelection(
    filterBarOptions: FilterBarOption[],
    isPrimary: boolean = false,
    initialize: boolean = false,
  ): FilterBarSelection[] {
    return filterBarOptions.map((option) => {
      let defaultSelection = [];
      let defaultValue;
      if (option.multiSelect) {
        const defaultOptions = option.options.filter((o) => option.multiSelectDefaultValues?.includes(o.id));
        const selecteOptions = option.options.filter((o) => o.selected);
        const selectedValue = initialize ? selecteOptions || defaultOptions : defaultOptions;
        defaultValue = selectedValue;
        defaultSelection = selectedValue;
      } else {
        const selectedValue = initialize ? option.selectedValue || option.defaultValue : option.defaultValue;
        defaultValue = selectedValue;
        defaultSelection = [option.options.find((subOption) => subOption.title === selectedValue)!];
      }

      return {
        id: option.id,
        isPrimary,
        selection: defaultValue ? defaultSelection : [this.filterService.filterListDefaultValue],
      };
    });
  }

  private mapFilterBarOptionsToSearchFilterOption(filterBarOptions: FilterBarOption[]): SearchFilterOption[] {
    return filterBarOptions.map((option) => {
      const selectedOption = this.selectedOptions.filter((selectedOption) => selectedOption.id === option.id)[0];

      return {
        optionType: option.optionType,
        filterOption: {
          ...this.filterService.makeFilterOption(option, selectedOption.selection[0]),
        },
        buttonOption: this.filterService.makeButtonOption(option, selectedOption.selection[0]),
        multiSelect: option.multiSelect ?? false,
        prefix: option.prefix,
      };
    });
  }

  private hasSelectedOption(): boolean {
    return !!this.selectedOptions.find((option) => {
      const primaryFilter = this.primaryFilters.find((secondaryFilter) => secondaryFilter.id === option.id);
      const secondaryFilter = this.secondaryFilters.find((secondaryFilter) => secondaryFilter.id === option.id);
      return (
        option.selection.some((selection) => selection !== this.filterService.filterListDefaultValue) &&
        ((!option.isPrimary &&
          option.selection.some((s) => s.title !== secondaryFilter?.defaultValue && option.id !== ResourceType.sort)) ||
          (option.isPrimary &&
            primaryFilter?.multiSelect &&
            (option.selection.length !== primaryFilter.multiSelectDefaultValues?.length ||
              option.selection.some((s) => !primaryFilter.multiSelectDefaultValues?.includes(s.id)))))
      );
    });
  }

  private resetMultiSelectFilters(primaryOptions: FilterBarOption[]): FilterBarOption[] {
    return primaryOptions.map((primaryOption) => {
      const defaultMultiSelectValues = primaryOption.multiSelectDefaultValues;

      primaryOption.options = primaryOption.options.map((option) => ({
        ...option,
        selected: defaultMultiSelectValues?.includes(option.id),
      }));
      return primaryOption;
    });
  }
}
