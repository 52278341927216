<div [ngClass]="'actionable-card ' + cardSize">
  @if (displayDefaultHeader) {
    <div class="header">
      <p class="title">
        <span class="title-text" libTitleOnEllipsis [tolerance]="1">{{ item?.title }}</span>
      </p>
      @if (withExternalLink) {
        <a
          lib-button
          buttonType="link"
          role="link"
          class="external-link"
          data-testid="external-link"
          [routerLink]="externalRedirectionLink"
          [target]="externalRedirectionLinkTarget"
        >
          <mat-icon class="external-link-icon color-info-600 icon-lg" svgIcon="external-link"></mat-icon>
        </a>
      }
    </div>
  }
  <ng-content></ng-content>
</div>
