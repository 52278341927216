<!-- Context menu -->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" (closed)="activeItem = undefined">
  <ng-template matMenuContent let-item="item">
    @for (action of item.children; track action.id) {
      <ng-container *libPermission="action.permissions">
        @if (action.id === "separator") {
          <lib-divider></lib-divider>
        }
        @if (action.id !== "separator") {
          <a
            mat-menu-item
            (click)="applyAction(action, item)"
            [ngClass]="{ 'color-error': action.id === 'delete' }"
            [disabled]="action.disabled"
          >
            @if (action.icon) {
              <mat-icon class="icon-sm" [svgIcon]="action.icon"></mat-icon>
            }
            <span>{{ action.title }}</span>
          </a>
        }
      </ng-container>
    }
  </ng-template>
</mat-menu>

<div
  [class]="'panel-search layout-' + layout + ' presentation-' + presentation + ' grid-' + grid"
  [ngClass]="{ loaded: loaded, 'item-selected': selectedItemID }"
>
  <div class="panel-collection">
    <div class="collection collection-dummies">
      @if (dummySkeletonType === eDummySkeletonType.list) {
        <lib-skeleton-list
          [presentation]="dummySkeletonPresentationType"
          [numberOfRows]="dummyNumber"
          data-testid="skeletonList"
        ></lib-skeleton-list>
      } @else if (dummySkeletonType === eDummySkeletonType.item) {
        <lib-skeleton [presentation]="dummySkeletonPresentationType" data-testid="skeletonComponent"></lib-skeleton>
      } @else if (dummySkeletonType === eDummySkeletonType.page) {
        <lib-skeleton-page data-testid="skeletonPage"></lib-skeleton-page>
      } @else {
        <lib-skeleton-list [numberOfRows]="12" data-testid="skeletonList"></lib-skeleton-list>
      }
    </div>
    <div
      class="h-100 w-100"
      [ngClass]="{ 'collection collection-items': itemCollection?.items?.length }"
      cdkDropList
      cdkDropListSortingDisabled
      cdkDropListConnectedTo="itemList"
      [cdkDropListData]="itemCollection?.items"
    >
      @for (item of itemCollection?.items; track item.id) {
        <div
          [attr.title]="
            selectedItemIDs.indexOf(item.id) >= 0 || disableItems ? ('This item is already selected' | translate) : null
          "
          cdkDrag
          class="card-container"
          [ngClass]="{
            active: activeItem === item || selectedItem === item,
            selected: selectedItemIDs.indexOf(item.id) >= 0 || selectedItem === item || disableItems,
          }"
          (mouseover)="onMouseOver(item)"
          (mouseout)="onMouseOut()"
          (contextmenu)="onContextMenu($event, item)"
          [cdkDragDisabled]="selectedItemIDs.indexOf(item.id) >= 0 || disableItems"
        >
          <lib-card
            [item]="item"
            [presentation]="presentation"
            [mode]="mode"
            [canClickItem]="canClickItem"
            (action)="selectItem($event)"
          >
          </lib-card>
          @if (showActions && item.children) {
            <button class="context-menu" data-testid="search-context-menu" (click)="onContextMenu($event, item)">
              <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
            </button>
          }
        </div>
      }
      @if (itemCollection?.items?.length === 0) {
        <div cdkDragDisabled class="h-100 collection-empty">
          @if (noData) {
            <div class="h-100">
              <ng-content></ng-content>
            </div>
          }
          @if (!noData) {
            <div class="h-100">
              <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
            </div>
          }
        </div>
      }
      <div #spinner class="spinner">
        <lib-spinner [diameter]="20"></lib-spinner>
      </div>
      <div class="grid-end"></div>
    </div>
  </div>
  @if (mode === "nav") {
    <div class="panel-item">
      <router-outlet></router-outlet>
    </div>
  }
</div>
