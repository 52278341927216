import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationErrors } from '@angular/forms';

import { filter, Observable, Subscription } from 'rxjs';

import {
  ActionItem,
  ConfirmationDialogConfig,
  ConsolidationRules,
  ConsolidationTriggers,
  DialogResult,
  MetricCategory,
  MetricTableColumn,
  MetricTableColumnDefinitionUpsert,
  MetricTableDefinition,
  ResourceType,
  Status,
  Unit,
  ValueDefinitionType,
  DialogSize,
  UpdateMetricTableColumnDefinitionPayload,
  consolidationTriggerFromRule,
  ConsolidationRuleOptions,
  SOURCE_CONFIGURATION,
  isDBConsolidationEnabled,
  MAXIMUM_BYPASS_DEPTH,
  Metric,
} from '../../../../../models';
import { ColumnSelection } from '../../../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../../../dialogs';
import { MetricStructurePropertiesValidators } from '../../validators/metric-structure-properties-validators';
import { SearchService } from '../../../../../search';
import { TableInputColumnDialogForm, TableInputColumnDialogFormResult } from './table-input-column-dialog-form';
import { TranslateService } from '../../../../../services/common';
import { TrimMethod } from '../../../../../directives/trim-whitespace/trim-whitespace.model';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { ObservableUtils } from '../../../../../classes';
import {
  ConsolidationManualDialogComponent,
  ConsolidationManualDialogResults,
} from '../../../consolidation-manual-dialog/consolidation-manual-dialog.component';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { FeatureFlagService } from '../../../../../feature-flag';
import { ConsolidationUtils } from '../../../../../classes/ConsolidationUtils/consolidation-utils';

export interface TableInputColumnDialogConfig {
  columns: MetricTableColumnDefinitionUpsert[];
  metricCategory: MetricCategory;
  metricTableDefinition: MetricTableDefinition;
  metricStructureService: MetricStructureStateService;
  column?: MetricTableColumn;
  sourceConfiguration: SOURCE_CONFIGURATION;
  metric: Metric;
}

@Component({
  selector: 'lib-table-input-column-dialog',
  templateUrl: './table-input-column-dialog.component.html',
  styleUrls: ['./table-input-column-dialog.component.scss'],
})
export class TableInputColumnDialogComponent implements OnInit, OnDestroy {
  public consolidationRuleOptions: ActionItem[] = [];
  public consolidationTriggerOptions: ActionItem[] = [];

  readonly eValueDefinitionType = ValueDefinitionType;
  readonly eTrimMethod: typeof TrimMethod = TrimMethod;
  readonly columnSelection = ColumnSelection;
  readonly numericDecimalOptions = Array(11)
    .fill(null)
    .map((_, i) => ({ id: `${i}`, title: `${i}` })) as ActionItem[];
  readonly eConsolidationRules: typeof ConsolidationRules = ConsolidationRules;
  readonly calculationChangedMsg: string = this.translateService.instant(
    'Changes to the configuration rules may result in completed metrics being set back to in progress should their values be modified.',
  );

  readonly numberMinMaxErrorMsgs: ValidationErrors = {
    minMax: this.translateService.instant('Min cannot be greater than max'),
    maxMin: this.translateService.instant('Max cannot be less than min'),
  };

  sourceConfiguration: SOURCE_CONFIGURATION;
  isMetricUpdating$: Observable<boolean | undefined>;
  tableColumnDefinitionForm: TableInputColumnDialogForm;
  metricTableHasValues?: boolean = false;
  unitFamilies: ActionItem<string>[];
  allUnits: ActionItem<Unit>[] = [];
  unitDefaults: ActionItem<Unit>[] = [];
  isEditing: boolean = false;
  metricCategory: MetricCategory;
  allLevelsSelectedMessage: string | undefined = undefined;
  areConsolidationParamsChanged: boolean = false;
  public consolidationBypassLevelOptions: ActionItem[] = [...Array(MAXIMUM_BYPASS_DEPTH).keys()]
    .map((i) => i + 1)
    .map((i) => ({ id: i.toString(), title: i.toString() }));

  metricTableDeactivationEnabled: boolean = false;

  private unitFamilySubscription?: Subscription;
  readonly eSourceConfiguration: typeof SOURCE_CONFIGURATION = SOURCE_CONFIGURATION;

  constructor(
    private readonly dialogsService: DialogsService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<TableInputColumnDialogComponent>,
    private searchService: SearchService,
    private featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) private data: TableInputColumnDialogConfig,
  ) {
    this.metricTableDeactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_metric_table_deactivation_enabled',
    ]);
    this.sourceConfiguration = data.sourceConfiguration;
    this.consolidationRuleOptions = ConsolidationRuleOptions;
    this.metricTableHasValues = data.metricTableDefinition.has_values;
    this.isEditing = data.column != null;
    this.metricCategory = data.metricCategory;
    this.tableColumnDefinitionForm = new TableInputColumnDialogForm(
      data.columns,
      data.column,
      data.metricTableDefinition,
      data.metric,
      data.metricStructureService.isAdmin,
      this.metricTableDeactivationEnabled,
    );
    this.tableColumnDefinitionForm.setValidators(MetricStructurePropertiesValidators.isMaxBiggerThanMin);

    this.unitFamilies = this._getUnitsFamily();
    this._getUnitsData();
    this.setConsolidationOptions(this.tableColumnDefinitionForm.controls.consolidationRule.value);
    this.isMetricUpdating$ = this.data.metricStructureService.isMetricUpdating$;
    this.setAllLevelsSelectedMessage(this.tableColumnDefinitionForm.controls.bypassConsolidationLevels.value);
  }

  public ngOnInit(): void {
    this.setConsolidationParametersChanged();
    this.unitFamilySubscription = this.tableColumnDefinitionForm.controls.family.valueChanges.subscribe({
      next: (family: string | null) => {
        this.tableColumnDefinitionForm.controls.unitDefault.reset();
        this.onUnitFamilyChange(family);
      },
    });
  }

  public ngOnDestroy(): void {
    this.unitFamilySubscription?.unsubscribe();
  }

  get dialogTitle(): string {
    return this.translateService.instant(this.isEditing ? 'Manage input column' : 'Add input column');
  }

  setConsolidationParametersChanged(): void {
    const result = this.tableColumnDefinitionForm.toModel();
    this.areConsolidationParamsChanged =
      result.consolidation_rule !== this.data.column?.consolidation_rule ||
      result.consolidation_trigger !== this.data.column?.consolidation_trigger ||
      ConsolidationUtils.areBypassLevelsChanged(
        result.bypass_consolidation_levels,
        this.data.column?.bypass_consolidation_levels,
      );
  }

  consolidationRuleChanged(result: TableInputColumnDialogFormResult): boolean {
    return result.consolidation_rule !== this.data.column?.consolidation_rule;
  }

  save(): void {
    const result = this.tableColumnDefinitionForm.toModel();

    if (
      this.data.column?.id &&
      this.consolidationRuleChanged(result) &&
      this.metricTableHasValues &&
      result.consolidation_rule === ConsolidationRules.manual
    ) {
      return this.updateMetricColumnManualTableDialog(this.data.column.id, result);
    }

    if (this.data.column?.id) {
      return this.updateMetricColumn(this.data.column.id, result);
    }

    return this.data.metricStructureService.createMetricTableColumnDefinition(
      this.data.metricTableDefinition,
      result,
      this.closeSuccess,
    );
  }

  private updateMetricColumnManualTableDialog(columnId: string, result: TableInputColumnDialogFormResult): void {
    this.dialogsService
      .open<ConsolidationManualDialogComponent>(ConsolidationManualDialogComponent, {
        data: {
          size: DialogSize.small,
        },
      })
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter(
          (dialogResult: DialogResult<ConsolidationManualDialogResults>) => dialogResult.status === Status.SUCCESS,
        ),
      )
      .subscribe((dialogResult: DialogResult<ConsolidationManualDialogResults>) => {
        this.updateMetricColumn(columnId, result, dialogResult.data?.reset_consolidated_values || false);
      });
  }

  updateMetricColumn(
    columnId: string,
    result: TableInputColumnDialogFormResult,
    reset_consolidated_values: boolean = false,
  ): void {
    const payload: UpdateMetricTableColumnDefinitionPayload = { ...result, reset_consolidated_values };
    payload.consolidation_trigger =
      result.consolidation_rule === ConsolidationRules.manual ? null : result.consolidation_trigger;
    this.data.metricStructureService.updateMetricTableColumnDefinition(
      this.data.metricTableDefinition,
      columnId,
      payload,
      this.closeSuccess,
    );
  }

  closeDialog(): void {
    if (!this.tableColumnDefinitionForm.dirty) {
      this.dialogRef.close({ status: Status.CANCEL });
      return;
    }

    const dialogRef = this.dialogsService.open<ConfirmationDialogComponent, ConfirmationDialogConfig>(
      ConfirmationDialogComponent,
      {
        data: {
          primaryBtn: this.translateService.instant('Continue'),
          title: this.translateService.instant('Cancel action'),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to cancel this action? You will lose your changes.',
          ),
        },
      },
    );

    dialogRef
      .afterClosed()
      .pipe(filter((result?: DialogResult) => result?.status === Status.CONFIRMED))
      .subscribe(() => {
        this.dialogRef.close({ status: Status.CANCEL });
      });
  }

  public onUnitFamilyChange(family?: string | null): void {
    const unitFamily = family || this.tableColumnDefinitionForm.controls.family.value;
    this.unitDefaults = this.allUnits.filter((units: ActionItem<Unit>) => units.item?.family === unitFamily);
  }

  public getConsolidationTriggerOptions(): ActionItem[] {
    return this.consolidationTriggerOptions;
  }

  public setConsolidationOptions(consolidationRule: ConsolidationRules): void {
    this.consolidationTriggerOptions = consolidationTriggerFromRule(consolidationRule);
    this.setConsolidationTrigger(this.consolidationTriggerOptions.length > 0);
    if (consolidationRule === ConsolidationRules.manual) {
      this.tableColumnDefinitionForm.get('bypassConsolidationLevels')?.setValue(null);
      this.allLevelsSelectedMessage = undefined;
    }
    this.setConsolidationParametersChanged();
  }

  private setAllLevelsSelectedMessage(bypassConsolidationLevels: string[] | null): void {
    if (bypassConsolidationLevels?.length === MAXIMUM_BYPASS_DEPTH) {
      this.allLevelsSelectedMessage = this.translateService.instant(
        'Consider setting the Consolidation rule to “No calculation - allow manual entry” instead',
      );
    } else {
      this.allLevelsSelectedMessage = undefined;
    }
  }

  public setConsolidationTriggerOptions(consolidationRule: ConsolidationRules): void {
    this.consolidationTriggerOptions = consolidationTriggerFromRule(consolidationRule);
    this.setConsolidationTrigger(this.consolidationTriggerOptions.length > 0);
  }

  protected handleBypassConsolidationLevelsSelect(event: MultiSelectChangeEvent): void {
    this.setAllLevelsSelectedMessage(event.value as string[] | null);
    this.setConsolidationParametersChanged();
  }

  public isConsolidationEnabled(sourceConfiguration: SOURCE_CONFIGURATION): boolean {
    return isDBConsolidationEnabled(sourceConfiguration);
  }

  public isThirdParty(): boolean {
    return this.metricCategory === MetricCategory.THIRD_PARTY;
  }

  private _getUnitsFamily(): ActionItem<string>[] {
    this.searchService.searchResources(ResourceType.unit, 'families').subscribe((units) => {
      this.unitFamilies = units
        .filter((family: ActionItem<Unit>) => family.item != null)
        .map((family: ActionItem<string>) => {
          family.title = family.item ?? '';
          family.id = family.item ?? '';
          return family;
        });
    });
    return this.unitFamilies;
  }

  private _getUnitsData() {
    this.searchService.searchResources<Unit>(ResourceType.unit).subscribe((res) => {
      const allUnits = res.map((unit: ActionItem<Unit>) => {
        unit.title = `${unit.item?.label ?? ''}${unit.item?.symbol ? ` (${unit.item.symbol})` : ''}`;
        unit.id = unit.item?.code || '';
        return unit;
      });
      this.allUnits = allUnits;

      this.onUnitFamilyChange(this.tableColumnDefinitionForm.controls.family.value);
    });
  }

  private setConsolidationTrigger(triggerValue: boolean): void {
    if (triggerValue) {
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.enable();
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.value ||
        this.tableColumnDefinitionForm
          .get('consolidationTrigger')
          ?.setValue(ConsolidationTriggers.update_when_one_value);
    } else {
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.setValue(null);
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.disable();
    }
  }

  private closeSuccess = () => {
    this.dialogRef.close({ status: Status.SUCCESS });
  };
}
