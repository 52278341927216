import { Observable } from 'rxjs';

import {
  ApiResponse,
  DashboardPlotUpdateRequest,
  DashboardWidget,
  DashboardWidgetUpsertRequest,
  DashboardWidgetValuesRequest,
  Value,
} from '../../../models';

export abstract class DashboardWidgetsApiService {
  public abstract createWidget(
    dashboardId: string,
    request: DashboardWidgetUpsertRequest,
  ): Observable<ApiResponse<DashboardWidget>>;

  public abstract deleteWidget(dashboardId: string, dashboardWidgetId: string): Observable<undefined>;

  public abstract getWidgetValues(
    dashboardId: string,
    dashboardWidgetId: string,
    request: DashboardWidgetValuesRequest,
  ): Observable<ApiResponse<Value[]>>;

  public abstract updateDashboardPlot(
    dashboardId: string,
    dashboardPlotRequest: DashboardPlotUpdateRequest,
  ): Observable<ApiResponse<DashboardWidget[]>>;

  public abstract updateWidget(
    dashboardId: string,
    dashboardWidgetId: string,
    request: DashboardWidgetUpsertRequest,
  ): Observable<ApiResponse<DashboardWidget>>;
}
