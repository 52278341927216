@if (isRowReorderable) {
  <th class="reorder-column-size"></th>
}

@if (enableCheckboxSelection) {
  <th class="action-column-size">
    @if (selectAllOption) {
      <p-tableHeaderCheckbox />
    }
  </th>
}

@if (isExpandable) {
  <th class="action-column-size">
    @if (expandAllOption) {
      <button
        type="button"
        pButton
        pRipple
        class="p-button-text p-button-rounded p-button-plain"
        [ngClass]="{ 'expanded-button': areAllRowsExpanded, 'collapsed-button': !areAllRowsExpanded }"
        [icon]="areAllRowsExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        (click)="onToggleExpandAll()"
        pTooltip="{{ areAllRowsExpanded ? 'Collapse All' : ('Expand All' | translate) }}"
        data-testid="expandAllIcon"
      ></button>
    }
  </th>
}

@for (column of columns; track column; let last = $last) {
  @if (!column.isSortable) {
    <th
      pResizableColumn
      [ngStyle]="{
        width: column.width ?? 'unset',
        'max-width': column.width ?? 'unset',
        'min-width': column.minWidth ?? 'unset',
      }"
      class="wrapping-value"
      [ngClass]="{ 'separator-cell': column.isSeparator }"
    >
      <ng-container
        *ngTemplateOutlet="column.headerTemplate || defaultHeader; context: { $implicit: column.name }"
      ></ng-container>
      <ng-template #defaultHeader>{{ column.noHeader ? "" : column.name }}</ng-template>
      @if (column.columnFilter || column.enableFilter) {
        <p-columnFilter
          [field]="$any(column.dataKey)"
          [matchMode]="column.columnFilter?.matchMode ?? 'contains'"
          display="menu"
          [showMatchModes]="column.columnFilter?.showMatchModes ?? false"
          [showOperator]="column.columnFilter?.showOperator ?? false"
          [showAddButton]="column.columnFilter?.showAddButton ?? false"
          [hideOnClear]="column.columnFilter?.hideOnClear ?? true"
          [showApplyButton]="column.columnFilter?.showApplyButton ?? true"
        >
          @if (column.columnFilter && column.columnFilter.type === "select") {
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                [ngModel]="value"
                [options]="column.columnFilter.options"
                (onChange)="filter($event.value)"
                optionLabel="title"
                [placeholder]="'Select' | translate"
                [filter]="true"
                filterBy="title"
              >
              </p-dropdown>
            </ng-template>
          }
        </p-columnFilter>
      }
      @if (
        horizontalPaginationConfig && horizontalPaginationConfig.pageSize < horizontalPaginationConfig.total && last
      ) {
        <div
          class="horizontal-pagination"
          [ngClass]="{ 'horizontal-pagination-loading': horizontalPaginationConfig.isLoading }"
        >
          @if (!horizontalPaginationConfig.isLoading) {
            <p-button
              icon="pi pi-arrow-left"
              data-testid="prev-horizontal-page"
              [rounded]="true"
              [text]="true"
              severity="secondary"
              [disabled]="horizontalPaginationConfig.currentIndex <= 0"
              (click)="horizontalPageChange(-1)"
            ></p-button>
            <p-button
              icon="pi pi-arrow-right"
              data-testid="next-horizontal-page"
              [rounded]="true"
              [text]="true"
              [disabled]="
                horizontalPaginationConfig.currentIndex + horizontalPaginationConfig.pageSize >=
                horizontalPaginationConfig.total
              "
              severity="secondary"
              (click)="horizontalPageChange(1)"
            >
            </p-button>
          } @else {
            <lib-spinner data-testid="loading-horizontal-page" [diameter]="15"></lib-spinner>
          }
        </div>
      }
    </th>
  } @else {
    <th
      pResizableColumn
      [pSortableColumn]="$any(column.dataKey)"
      [ngStyle]="{ width: column.width ?? 'unset', 'max-width': column.width ?? 'unset' }"
    >
      {{ column.name }}
      <p-sortIcon [field]="$any(column.dataKey)"></p-sortIcon>
    </th>
  }
}

@if (actionMenuItems?.length) {
  <th class="action-column-size"></th>
}
