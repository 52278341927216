@if (valueDefinition | isDeactivated) {
  <span class="status-chip">{{ "Inactive" | translate }}</span>
}

@if (documentWithIcon$ | async; as documentWithIcon) {
  <a class="card" data-testid="doc-card" [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }">
    <div class="card-content align-center">
      <div class="pr-2 h-100">
        <div class="caption2 color-grey-800 line-clamp">
          @if (documentWithIcon.document?.doc?.category) {
            <span> {{ documentWithIcon.document?.doc?.category }}</span>
          }
          @if (valueDefinition?.id === eValueDefinitionTemplateType.template) {
            <span> {{ "Category" | translate }}</span>
          }
        </div>
        <div class="mat-body-1 color-primary-900 text-clamp text-clamp-3 mt-1">
          {{ valueDefinition | documentTitle: documentWithIcon.document?.name }}
        </div>
      </div>
      <div
        data-testid="document-icon"
        class="file-icon ml-1"
        [ngClass]="'file-' + (documentWithIcon.icon || 'no-fill')"
      >
        <mat-icon [svgIcon]="documentWithIcon.icon"></mat-icon>
      </div>
    </div>
  </a>
}
