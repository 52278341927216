@if (form) {
  @if (form.controls.isEditing.value) {
    <lib-switch
      class="formfield mb-3"
      [control]="form.controls.active"
      [switchLabel]="'Active' | translate"
    ></lib-switch>
  }
  <lib-select-input
    class="formfield"
    [label]="'Category' | translate"
    [control]="form.controls.categoryId"
    [options]="optionListCategories"
  ></lib-select-input>
  @if (withTypeField) {
    <lib-select-input
      class="formfield"
      [label]="'Type' | translate"
      [control]="form.controls.type"
      [options]="optionListTypes"
    ></lib-select-input>
  }
  @if (withPublicField) {
    <lib-switch
      class="formfield mb-3"
      [control]="form.controls.public"
      [switchLabel]="'Public' | translate"
    ></lib-switch>
  }
  <lib-text-input
    class="formfield"
    [control]="form.controls.name"
    [label]="'Name' | translate"
    [messages]="{ isUnique: 'Name must be unique.' | translate }"
  ></lib-text-input>
  <lib-text-area-input
    class="formfield mb-2"
    [control]="form.controls.description"
    [label]="'Description' | translate"
  ></lib-text-area-input>
  <lib-text-area-input
    class="formfield"
    [control]="form.controls.explanationLabel"
    [label]="'Explanation text field' | translate"
    [placeholder]="'Please explain' | translate"
  ></lib-text-area-input>
}
