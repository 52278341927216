import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FocusedFieldAction, IndicatorCommentDetails } from '../../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[lib-field-comment-counter]',
  templateUrl: './field-comment-counter.component.html',
  styleUrls: ['./field-comment-counter.component.scss'],
})
export class FieldCommentCounterComponent implements OnChanges {
  @Input() unresolved_regular_comments_and_replies?: number = 0;
  @Input() unresolved_rejection_comments_and_replies?: number = 0;
  @Input() fieldComments?: IndicatorCommentDetails[] = [];
  @Input() currentUserId?: string;

  @Output() actionSelected: EventEmitter<FocusedFieldAction> = new EventEmitter<FocusedFieldAction>();

  isCurrentUserTagged: boolean = false;
  readonly eFocusedFieldAction = FocusedFieldAction;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldComments && this.currentUserId) {
      this.isCurrentUserTagged = Boolean(
        this.fieldComments?.flatMap((comment) => comment.tagged_user_ids).includes(this.currentUserId),
      );
    }
  }

  public handleFocusedFieldAction(clickEvent: MouseEvent, focusedFieldAction: FocusedFieldAction): void {
    clickEvent.stopPropagation();
    this.actionSelected.emit(focusedFieldAction);
  }
}
