import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';

import { ActionItem, ApiResponse, DashboardDatum, ResourceType } from '../../../models';
import { DashboardDatumsApiService } from '../../../services/api-services';
import { ActionItemUtils } from '../../../classes';
import { EMPTY, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';

export interface DashboardWidgetUpsertSidebarState {
  dashboardDatums: ActionItem<DashboardDatum>[];
  isLoading: boolean;
}

@Injectable()
export class DashboardWidgetUpsertSidebarStore
  extends ComponentStore<DashboardWidgetUpsertSidebarState>
  implements OnStoreInit
{
  private static readonly DEFAULT_STATE: DashboardWidgetUpsertSidebarState = {
    dashboardDatums: [],
    isLoading: false,
  };

  public readonly dashboardDatums$ = this.select((state) => state.dashboardDatums);
  public readonly isLoading$ = this.select((state) => state.isLoading);

  constructor(private readonly dashboardDatumsApiService: DashboardDatumsApiService) {
    super(DashboardWidgetUpsertSidebarStore.DEFAULT_STATE);
  }

  public ngrxOnStoreInit(): void {
    this.fetchDashboardDatums();
  }

  public readonly fetchDashboardDatums = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.dashboardDatumsApiService.listDashboardDatums()),
      tapResponse(
        (res: ApiResponse<DashboardDatum[]>) => {
          this.patchState({
            dashboardDatums: ActionItemUtils.responseToActionItem(res, ResourceType.dashboard_datum),
            isLoading: false,
          });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );
}
