import { Component, AfterViewInit, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActionItem, ValueDefinitionSize } from '../../../models';
import { MultiSelect, MultiSelectChangeEvent } from 'primeng/multiselect';
import { ValidationMessageService } from '../../../services/common';

let nextId = 0;

@Component({
  selector: 'lib-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, AfterViewInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control?: FormControl;
  @Input() options: ActionItem[] = [];
  @Input() withSelectAllOption: boolean = false;
  @Input() maxSelectedLabels: number = 3;
  @Input() optionLabel?: string = 'title';
  @Input() optionValue?: string = 'id';
  @Input() optionDisabled?: string = 'deactivated';
  @Input() group: boolean = false;
  @Input() optionGroupLabel?: string;
  @Input() optionGroupChildren: string = '';
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize: number = 35;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() autofocus: boolean = false;
  @Input() showOnFocus: boolean = false;
  @Input() messages: ValidationErrors = {};
  @Output() selectChange = new EventEmitter<MultiSelectChangeEvent>();

  @ViewChild('multiselect') multiselect!: MultiSelect;

  required: boolean = false;
  readonly _labelId = `multi-select-input-${nextId++}`;
  inputWidth?: string;

  public errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };

    if (this.isActionItem(this.options[0])) {
      this.optionLabel = 'title';
      this.optionValue = 'id';
      this.optionDisabled = 'deactivated';

      if (this.group) {
        this.optionGroupLabel = 'title';
        this.optionGroupChildren = 'children';
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.multiselect) {
        this.inputWidth = `${this.multiselect.el.nativeElement.offsetWidth}px`;
      }
    });
  }

  private isActionItem(obj: any): obj is ActionItem {
    return typeof obj === 'object' && obj !== null && typeof obj.id === 'string' && typeof obj.title === 'string';
  }

  protected handleSelectChange(event: MultiSelectChangeEvent) {
    this.selectChange.emit(event);
  }
}
