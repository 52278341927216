@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [labelPosition]="labelPosition"
    [dataTestId]="'dropdown-input'"
    [class]="'p-field'"
  >
    <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
    <p-dropdown
      [ariaLabelledBy]="_labelId"
      [options]="options"
      [formControl]="control"
      [id]="_labelId"
      [filter]="filter"
      [filterBy]="filterBy"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [optionDisabled]="optionDisabled"
      [group]="group"
      [optionGroupLabel]="optionGroupLabel"
      [optionGroupChildren]="optionGroupChildren"
      [autoDisplayFirst]="autoDisplayFirst"
      [showClear]="showClear"
      [tooltip]="tooltip"
      [required]="required"
      [appendTo]="'body'"
      tooltipPosition="bottom"
      [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
      [panelStyle]="{ width: inputWidth }"
      [placeholder]="placeholder"
      #focusElement
      #dropdownSelect
      libFocusState
    >
      <ng-template pTemplate="item" let-item>
        @if (item[optionLabel || "title"]; as title) {
          <span [pTooltip]="title" tooltipPosition="bottom">{{ title }}</span>
        }
      </ng-template>
    </p-dropdown>
    <div class="subscript-wrapper">
      @if ((hint && control.valid) || control.untouched) {
        <p class="message">
          {{ hint }}
        </p>
      }
      @if (control.dirty || control.touched) {
        @for (error of control.errors | errorKeys; track error) {
          <p class="p-error message">
            {{ errorMessages[error] }}
          </p>
        }
      }
    </div>
  </div>
}
