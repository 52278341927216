@if (data$ | async; as data) {
  <div class="mt-2">
    <lib-filter-bar
      [withSearchBar]="true"
      [searchBarPlaceholder]="'Search' | translate"
      (onSearchChanged)="onSearchChange($event)"
    ></lib-filter-bar>
    <div class="ph-5">
      @if (data.total) {
        <lib-table-grid
          [columns]="dashboardDatumColumns"
          [values]="data.dashboardDatums"
          [isCustomSort]="false"
          [isLoading]="data.isLoading"
          [isPaginable]="true"
          [isResizable]="false"
          [totalItemCount]="data.total"
          [actionMenuItems]="[
            { label: 'Edit' | translate, icon: 'pencil', onClick: onEditDashboardDatum },
            { label: 'Rename' | translate, icon: 'file-edit', onClick: onRenameDashboardDatum },
            { label: 'Delete' | translate, icon: 'trash', onClick: onDeleteDashboardDatum },
          ]"
          (pageChanged)="onPageChange($event)"
        >
        </lib-table-grid>
      } @else {
        @if (!data.isLoading) {
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        }
      }
    </div>
  </div>
}
