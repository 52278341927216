import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { combineLatestWith, filter, switchMap, tap } from 'rxjs/operators';
import { map, Observable, of } from 'rxjs';
import { provideComponentStore } from '@ngrx/component-store';
import { OptionListsStore } from './option-lists.store';
import {
  ActionItem,
  ConfirmationDialogConfig,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  OptionList,
  OptionListCategory,
  OptionListType,
  Permission,
  Presentation,
  Status,
  TableColumn,
  TableActionMenuItemEvent,
  TablePageEvent,
  TableSortEvent,
  TagType,
  EmptyResults,
  RowSelectionEvent,
} from '../models';
import { TranslateService } from '../services/common';
import { OptionListStatusTranslations, OptionListTypeTranslations } from '../translations';
import keyBy from 'lodash/keyBy';
import { OptionListForm } from './option-list-form/option-list-form';
import { OptionListsApiService } from '../services/api-services';
import { ConfirmationDialogComponent, DialogsService } from '../dialogs';

interface Data {
  isLoading: boolean;
  optionListCategories: Record<string, ActionItem<OptionListCategory>>;
  optionLists: OptionList[];
  tableFilters: FilterBarOption[];
  total: number;
}

@Component({
  selector: 'lib-option-lists',
  templateUrl: './option-lists.component.html',
  styleUrls: ['./option-lists.component.scss'],
  providers: [provideComponentStore(OptionListsStore)],
})
export class OptionListsComponent implements OnInit, AfterContentInit {
  @ViewChild('categoryCell', { static: true }) categoryCell?: TemplateRef<{ element: string }>;
  @ViewChild('defaultCell', { static: true }) defaultCell?: TemplateRef<{ element: boolean }>;
  @ViewChild('statusCell', { static: true }) statusCell?: TemplateRef<{ element: boolean }>;
  @ViewChild('typeCell', { static: true }) typeCell?: TemplateRef<{ element: OptionListType }>;

  @Input({ required: true }) managePermission!: Permission;
  @Input({ required: true }) categoryKey!: string;
  @Input() withDefaultColumn: boolean = false;
  @Input() withPublicField: boolean = false;
  @Input() withTypeColumn: boolean = false;

  @Output() viewOptionList: EventEmitter<RowSelectionEvent<OptionList>> = new EventEmitter<
    RowSelectionEvent<OptionList>
  >();

  public readonly eTagType = TagType;
  public readonly ePresentation = Presentation;
  public readonly eOptionListTypeTranslations: Record<string, string> = OptionListTypeTranslations;
  public readonly emptyResults: EmptyResults = { title: this.translateService.instant('No result found') };

  public data$?: Observable<Data>;
  public form?: OptionListForm;
  public isCategoryDefaultSidebarVisible: boolean = false;
  public isSidebarVisible: boolean = false;
  public optionListCategories: Record<string, ActionItem<OptionListCategory>> = {};
  public optionListTableColumns: TableColumn<OptionList>[] = [];

  public optionListActionMenu: TableActionMenuItemEvent<OptionList>[] = [
    {
      label: this.translateService.instant('View'),
      icon: 'eye',
      onClick: (optionList: OptionList) => this.viewOptionList.emit({ data: optionList }),
    },
  ];

  constructor(
    private readonly dialogsService: DialogsService,
    private readonly optionListsApiService: OptionListsApiService,
    private readonly optionListsStore: OptionListsStore,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.optionListsStore.initialize(this.withDefaultColumn);
    this.optionListActionMenu.push(
      {
        label: this.translateService.instant('Activate'),
        icon: 'check-circle',
        showCondition: (optionList: OptionList) => !optionList.active,
        onClick: (optionList: OptionList) => this.toggleOptionListStatus(optionList),
        permissions: [this.managePermission],
      },
      {
        label: this.translateService.instant('Deactivate'),
        icon: 'circle',
        showCondition: (optionList: OptionList) => optionList.active,
        onClick: (optionList: OptionList) => this.toggleOptionListStatus(optionList),
        permissions: [this.managePermission],
      },
      {
        isSeparator: true,
        permissions: [this.managePermission],
      },
      {
        label: this.translateService.instant('Delete'),
        icon: 'trash',
        onClick: (optionList: OptionList) => this.deleteOptionList(optionList),
        permissions: [this.managePermission],
      },
    );
    this.data$ = this.optionListsStore.optionLists$.pipe(
      combineLatestWith(
        this.optionListsStore.filterOptionsState$.pipe(
          tap((filterOptionsState) => {
            this.optionListCategories = keyBy(filterOptionsState.optionListCategories, 'id');
            this.form = new OptionListForm(this.optionListsApiService, this.withTypeColumn);
          }),
        ),
        this.optionListsStore.isLoading$,
        this.optionListsStore.total$,
      ),
      map(([optionLists, filterOptionsState, isLoading, total]) => ({
        isLoading,
        optionListCategories: filterOptionsState.optionListCategories || {},
        optionLists,
        tableFilters: this.getFilterOptions(filterOptionsState.optionListCategories),
        total,
      })),
    );
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public toggleOptionListStatus(optionList: OptionList): void {
    let obs = this.optionListsApiService.activateOptionList(optionList.id);
    let primaryBtn = this.translateService.instant('Activate');
    let title = this.translateService.instant('Activate option list');
    let warningMsg = this.translateService.instant('Are you sure you want to activate the Option List?');

    if (optionList.active) {
      obs = this.optionListsApiService.deactivateOptionList(optionList.id);
      primaryBtn = this.translateService.instant('Deactivate');
      title = this.translateService.instant('Deactivate option list');
      warningMsg = this.translateService.instant('Are you sure you want to deactivate the Option List?');
    }

    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: { primaryBtn, title, warningMsg },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === Status.CONFIRMED),
        switchMap(() => obs),
      )
      .subscribe(() => {
        this.optionListsStore.fetchOptionLists();
      });
  }

  public deleteOptionList(optionList: OptionList): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete option list'),
          warningMsg: this.translateService.instant('Are you sure you want to delete this Option List?'),
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === Status.CONFIRMED),
        switchMap(() => this.optionListsApiService.deleteOptionList(optionList.id)),
      )
      .subscribe(() => {
        this.optionListsStore.fetchOptionLists();
      });
  }

  public onPageChange = (event: TablePageEvent): void => {
    this.optionListsStore.updatePaginationState({ currentPage: event.currentPage, pageSize: event.pageSize });
  };

  public onSearchChange(searchQuery?: string): void {
    this.optionListsStore.updateSearchQuery(searchQuery);
  }

  public onSortChange(event: TableSortEvent): void {
    this.optionListsStore.updateFiltersState({ orderBy: event.field, orderByDirection: event.direction });
  }

  public onFilterChange(filters: FilterBarSelection[]): void {
    this.optionListsStore.updateFilters(filters);
  }

  public save(): void {
    const payload = this.form?.toModel();

    if (payload) {
      this.optionListsApiService.addOptionList(payload).subscribe((res) => {
        this.form?.reset();
        this.setSidebarVisible(false);
        this.viewOptionList.emit({ data: res.data });
      });
    }
  }

  public setCategoryDefaultSidebarVisible(isCategoryDefaultSidebarVisible = false): void {
    if (isCategoryDefaultSidebarVisible !== this.isCategoryDefaultSidebarVisible) {
      this.isCategoryDefaultSidebarVisible = isCategoryDefaultSidebarVisible;

      if (!isCategoryDefaultSidebarVisible) {
        this.optionListsStore.fetchOptionLists();
      }
    }
  }

  public setSidebarVisible(isSidebarVisible = false): void {
    if (this.form && isSidebarVisible !== this.isSidebarVisible) {
      let obs = of(true);

      if (this.form.touched && !isSidebarVisible) {
        obs = this.dialogsService
          .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
            data: {
              primaryBtn: this.translateService.instant('Discard'),
              title: this.translateService.instant('Discard changes'),
              warningMsg: this.translateService.instant('Are you sure you want to discard your changes?'),
            },
          })
          .afterClosed()
          .pipe(map((result) => result?.status === Status.CONFIRMED));
      }

      obs.subscribe((confirmed) => {
        if (confirmed) {
          this.form?.reset();
          this.isSidebarVisible = isSidebarVisible;
        }
      });
    }
  }

  private getFilterOptions(optionListCategories?: ActionItem<OptionListCategory>[]): FilterBarOption[] {
    if (!optionListCategories) {
      return [];
    }

    const typeFilter = {
      title: this.translateService.instant('Type'),
      id: 'type',
      optionType: FilterType.list,
      displayAll: true,
      options: this.translateService.listResources(OptionListTypeTranslations),
    };

    return [
      {
        title: this.translateService.instant('Category'),
        id: 'category',
        optionType: FilterType.searchList,
        displayAll: true,
        options: optionListCategories,
      },
      ...(this.withTypeColumn ? [typeFilter] : []),
      {
        title: this.translateService.instant('Status'),
        id: 'active',
        optionType: FilterType.list,
        displayAll: true,
        options: this.translateService.listResources(OptionListStatusTranslations),
      },
    ];
  }

  private setupColumns(): void {
    const typeColumn = {
      name: this.translateService.instant('Type'),
      dataKey: 'type',
      cellTemplate: this.typeCell,
      isSortable: true,
      width: '9rem',
    };

    const defaultColumn = {
      name: this.translateService.instant('Default'),
      dataKey: 'is_category_default',
      cellTemplate: this.defaultCell,
      isSortable: true,
      width: '9rem',
    };

    this.optionListTableColumns = [
      {
        name: this.translateService.instant('Name'),
        dataKey: 'name',
        isSortable: true,
        width: '40rem',
      },
      ...(this.withTypeColumn ? [typeColumn] : []),
      {
        name: this.translateService.instant('Category'),
        dataKey: this.categoryKey,
        cellTemplate: this.categoryCell,
        isSortable: true,
        width: '15rem',
      },
      ...(this.withDefaultColumn ? [defaultColumn] : []),
      {
        name: this.translateService.instant('Description'),
        dataKey: 'description',
        width: '40rem',
      },
      {
        name: this.translateService.instant('Status'),
        dataKey: 'active',
        cellTemplate: this.statusCell,
        isSortable: true,
        width: '9rem',
      },
    ];
  }
}
