import { Entity } from '../entities';

export enum FrameworkBasedReportStatus {
  draft = 'draft',
  completed = 'completed',
}

export interface FrameworkBasedReport extends Entity {
  taxonomy_id: string;
  business_unit_id: string;
  frequency_code: string;
  status: FrameworkBasedReportStatus;
}

export interface CreateFrameworkBasedReportRequest {
  name: string;
  taxonomy_id: string;
  business_unit_id: string;
  frequency_code: string;
}
