<div
  data-testid="drop-area"
  class="formfield"
  [ngClass]="{
    error: valueFormControl.errors?.length,
    disabled: disabled,
  }"
  (drop)="dropFile($event)"
  (dragover)="preventEventDefault($event)"
>
  <lib-form-field-label
    [label]="valueFormControl | fileLabel: uploading"
    [control]="valueFormControl"
  ></lib-form-field-label>
  <a
    class="card card-dashed align-center p-0 justify-center"
    tabindex="1"
    data-testid="file-drop-card"
    [ngClass]="{ disabled: disabled }"
  >
    @if (!uploading) {
      <div class="mat-body-2 color-grey-800">
        {{ "Drag files to import, or" | translate }}
        @if (!customBrowseDialog) {
          <button lib-button buttonType="browse">
            {{ "browse" | translate
            }}<input type="file" data-testid="fileInput" (change)="selectFileFromBrowser($event)" />
          </button>
        } @else {
          <button lib-button buttonType="browse" (click)="launchCustomBrowseDialog()">
            {{ "browse" | translate }}
          </button>
        }
      </div>
    } @else {
      <div class="fx-row align-center">
        <div class="mr-4 mat-body-2 color-grey-800">{{ "Uploading file. Please wait..." | translate }}</div>
        <div class="spinner">
          <lib-spinner ariaLabel="upload-spinner" [diameter]="20"></lib-spinner>
        </div>
      </div>
    }
  </a>
  @if (valueFormControl.untouched || valueFormControl.valid) {
    <mat-hint>
      <div class="fx-row justify-space-between">
        <div>{{ hint }}</div>
        <div>{{ "150MB" | translate }}</div>
      </div>
    </mat-hint>
  }
  @if (valueFormControl.touched && valueFormControl.invalid) {
    @for (error of valueFormControl.errors | errorKeys; track error) {
      <mat-error> {{ messages?.[error] }}</mat-error>
    }
  }
</div>
