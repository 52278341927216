<p-stepper [activeStep]="selectedIndex" (activeStepChange)="selectionChange($event)">
  @for (step of steps; track i; let i = $index) {
    @if (step) {
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick">
          <div
            role="tab"
            class="custom-header"
            [ngClass]="{ 'custom-header-disabled': step.disabled }"
            [attr.selected]="selectedIndex === i"
            (click)="onClick.emit()"
          >
            <span class="p-stepper-number">{{ i + 1 }}</span>
            @if (step.customLabel) {
              <ng-container *ngTemplateOutlet="step.customLabel"></ng-container>
            } @else {
              <span class="p-stepper-title">{{ step.title }}</span>
            }
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <ng-container *ngTemplateOutlet="step.content"></ng-container>
        </ng-template>
      </p-stepperPanel>
    }
  }
</p-stepper>
