import { Component, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardDatumsSidebarStore } from './dashboard-datums-sidebar.store';
import { filter, map, switchMap } from 'rxjs';
import { DashboardDatum, DialogResult, DialogSize, Status, ToastStatus } from '../../models';
import { TranslateService } from '../../services/common';
import { DialogsService } from '../../dialogs';
import { DashboardDatumsApiService } from '../../services/api-services/dashboard-datums-api-service/dashboard-datums-api.service';
import { ToastService } from '../../components';
import { DashboardGridOptions } from '../dashboard-grid/dashboard-grid.component';
import {
  RenameDashboardDatumDialogComponent,
  RenameDashboardDatumDialogData,
  RenameDashboardDatumDialogResults,
} from './rename-dashboard-datum-dialog/rename-dashboard-datum-dialog.component';
import { ObservableUtils } from '../../classes';
import { DashboardsHelper } from '../dashboards-helper';
import { DashboardsApiService, DashboardWidgetsApiService } from '../../services/api-services';

@Component({
  selector: 'lib-dashboard-datums-sidebar',
  templateUrl: './dashboard-datums-sidebar.component.html',
  styleUrls: ['./dashboard-datums-sidebar.component.scss'],
  providers: [provideComponentStore(DashboardDatumsSidebarStore)],
})
export class DashboardDatumsSidebarComponent {
  @Input() options: DashboardGridOptions = {};
  @Input({ required: true }) visible: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  private dashboardsHelper: DashboardsHelper;

  constructor(
    private readonly toastService: ToastService,
    private readonly dashboardDatumsApiService: DashboardDatumsApiService,
    readonly dashboardsApiService: DashboardsApiService,
    readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
    private readonly dashboardDatumsSidebarStore: DashboardDatumsSidebarStore,
    private readonly dialogsService: DialogsService,
    private readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.dashboardsHelper = new DashboardsHelper(
      dashboardsApiService,
      dashboardWidgetsApiService,
      dashboardDatumsApiService,
      dialogsService,
      toastService,
      translateService,
    );
  }

  public renameDatum(dashboardDatum: DashboardDatum): void {
    this.dialogsService
      .open<
        RenameDashboardDatumDialogComponent,
        RenameDashboardDatumDialogData,
        DialogResult<RenameDashboardDatumDialogResults>
      >(RenameDashboardDatumDialogComponent, {
        data: { dashboardDatum, size: DialogSize.small },
        viewContainerRef: this.viewContainerRef,
      })
      .afterClosed()
      .pipe(
        filter((dialogResult) => dialogResult?.status === Status.SUCCESS),
        map((dialogResult) => dialogResult?.data?.payload),
        ObservableUtils.filterNullish(),
        switchMap((payload) => this.dashboardDatumsApiService.editDashboardDatum(dashboardDatum.id, payload)),
      )
      .subscribe(() => {
        this.toastService.open(ToastStatus.SUCCESS, this.translateService.instant('Changes saved'));
        this.dashboardDatumsSidebarStore.fetchDashboardDatums();
      });
  }

  public upsertDatum(dashboardDatum?: DashboardDatum): void {
    this.dashboardsHelper
      .upsertDatum({ options: this.options, dashboardDatum }, this.viewContainerRef)
      .subscribe(() => {
        this.dashboardDatumsSidebarStore.fetchDashboardDatums();
      });
  }
}
