import { Component, Inject, OnInit } from '@angular/core';
import { DashboardDatum, DialogResult, Status, UpsertDashboardDatumRequest } from '../../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RenameDashboardDatumForm } from './rename-dashboard-datum.form';
import { DashboardDatumsApiService } from '../../../services/api-services';

export interface RenameDashboardDatumDialogResults {
  payload: UpsertDashboardDatumRequest;
}

export interface RenameDashboardDatumDialogData {
  dashboardDatum: DashboardDatum;
}

@Component({
  selector: 'lib-rename-dashboard-datum-dialog',
  templateUrl: './rename-dashboard-datum-dialog.component.html',
  styleUrls: ['./rename-dashboard-datum-dialog.component.scss'],
})
export class RenameDashboardDatumDialogComponent implements OnInit {
  public form?: RenameDashboardDatumForm;

  constructor(
    private readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dialogRef: MatDialogRef<
      RenameDashboardDatumDialogComponent,
      DialogResult<RenameDashboardDatumDialogResults>
    >,
    @Inject(MAT_DIALOG_DATA) public data: RenameDashboardDatumDialogData,
  ) {}

  public ngOnInit(): void {
    this.form = new RenameDashboardDatumForm(this.dashboardDatumsApiService, this.data.dashboardDatum);
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public save(): void {
    if (!this.form) {
      return;
    }

    this.dialogRef.close({
      status: Status.SUCCESS,
      data: { payload: this.form.toModel(this.data.dashboardDatum.value_definition_id) },
    });
  }
}
