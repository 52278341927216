<div class="bkg-grey-50">
  <div class="column-row">
    <div class="column-number column-header">
      <div class="mat-body-1 color-grey-900">{{ "#" | translate }}</div>
    </div>
    <div class="column-content column-header">
      <div class="mat-body-2 color-grey-900">{{ columnTypeLabel }}</div>
    </div>
  </div>

  @for (columnForm of columnFormsList; track columnForm; let index = $index) {
    <div
      class="column-row column-row-hoverable p-0 bkg-white"
      role="row"
      [attr.aria-label]="(isContext ? 'Context' : 'Input') + ' Column'"
      [formGroup]="columnForm"
    >
      <div class="column-number">
        <div class="mat-body-2 color-grey-900">{{ index + 1 }}</div>
      </div>
      <div class="column-content">
        <div class="mat-body-2 color-grey-900">
          {{ columnForm.value.column }}
        </div>
      </div>
      @if (isContext) {
        <div class="column-content">
          <div
            class="mat-body-2 color-grey-900 fx-row justify-space-between w-100"
            [matMenuTriggerFor]="contextOptionsMenu"
            data-testid="context-column-values"
          >
            @if (columnForm.value.values.length) {
              <p class="context-options">{{ columnForm.value.values.join(", ") }}</p>
            } @else {
              <div class="fx-row">
                <p>{{ "Select" | translate }}</p>
                <p class="color-error">*</p>
              </div>
            }
            <mat-icon svgIcon="arrow-head-down" class="icon-md mr-2 color-grey-800 options-arrow"></mat-icon>
          </div>
          <mat-menu #contextOptionsMenu="matMenu" class="ph-4">
            <p class="color-grey-800 mat-body-1">Context Options</p>
            <lib-multi-checkbox-input
              [options]="columnForm.value.column | getContextColumnOptions: metricTableContextColumns"
              [control]="getContextValueControl(columnForm)"
            ></lib-multi-checkbox-input>
          </mat-menu>
        </div>
      }
      <div class="column-actions">
        <button
          class="btn btn-option btn-delete-column"
          (click)="removeColumn.emit(index)"
          aria-label="delete row"
          [attr.data-testid]="'delete-' + columnForm.value.column"
        >
          <mat-icon svgIcon="trash" class="icon-md"></mat-icon>
        </button>
      </div>
    </div>
  }
</div>
