<div class="table-paginator">
  <ng-content select="footer"><div></div></ng-content>

  <p-paginator
    [first]="firstPageItemRelativeIndex"
    [rows]="_pageSize"
    [totalRecords]="_totalItemCount ?? 0"
    [rowsPerPageOptions]="withPaginationAlternatives ? PAGINATION_ROWS_PER_PAGE : undefined"
    (onPageChange)="changePage($event)"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="
      (showCurrentPageReportTemplateText ? 'Showing {first} - {last} of {total} entries' : '')
        | translate: paginationTranslationParams
    "
    [showPageLinks]="true"
    [showFirstLastIcon]="true"
    [dropdownAppendTo]="'body'"
  >
  </p-paginator>
</div>
