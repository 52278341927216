@if (control || uncontrolled) {
  <div [class]="'formfield ' + size" [ngClass]="{ disabled: control?.disabled }">
    @if (control) {
      <lib-input-container [label]="label" [control]="control" [hint]="hint" [inputId]="id" [bigPaddingField]="true">
        <div class="toggle-container">
          <p-inputSwitch
            [inputId]="id"
            [formControl]="control"
            [pTooltip]="label"
            (onChange)="switchChange.emit($event.checked)"
          />
          <label [for]="id" [ngClass]="{ 'disabled-switch': control.disabled }">{{ switchLabel }}</label>
        </div>
      </lib-input-container>
    } @else {
      <lib-input-container [label]="label" [hint]="hint" [inputId]="id" [bigPaddingField]="true">
        <div class="toggle-container" ngProjectAs="no-control">
          <p-inputSwitch
            [inputId]="id"
            [pTooltip]="label"
            [(ngModel)]="checked"
            [disabled]="disabled"
            (onChange)="switchChange.emit($event.checked)"
          />
          <label [for]="id" [ngClass]="{ 'disabled-switch': disabled }">{{ switchLabel }}</label>
        </div>
      </lib-input-container>
    }
  </div>
}
