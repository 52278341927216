import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, of, switchMap } from 'rxjs';
import {
  BarChartConfig,
  ChartColumnPlotOptionStackingType,
  ColumnChartConfig,
  DashboardDatum,
  DashboardWidget,
  DashboardWidgetType,
  Source,
  Value,
} from '../../../models';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatestWith, filter, map, tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { DashboardStore } from '../dashboard.store';
import range from 'lodash/range';
import keyBy from 'lodash/keyBy';
import { ChartConfig, ChartType, PieChartConfig } from '../../../models';
import { DashboardWidgetsApiService } from '../../../services/api-services/dashboard-widgets-api-service/dashboard-widgets-api.service';

export interface DashboardChartState {
  isLoading: boolean;
  chart: ChartConfig;
  dashboardWidget?: DashboardWidget;
}

@Injectable()
export class DashboardChartStore extends ComponentStore<DashboardChartState> {
  private static readonly DEFAULT_STATE: DashboardChartState = { isLoading: true, chart: {} };

  public readonly chart$ = this.select((state) => state.chart);
  public readonly isLoading$ = this.select((state) => state.isLoading);

  public dashboardWidget!: DashboardWidget;
  public dashboardDatums: Record<string, DashboardDatum> = {};
  private changes: [number, number, number, number, number] = [0, 0, 0, 0, 0];

  constructor(
    private readonly dashboardStore: DashboardStore,
    private readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
  ) {
    super(DashboardChartStore.DEFAULT_STATE);
  }

  public init(dashboardWidget: DashboardWidget): void {
    this.dashboardWidget = dashboardWidget;
    this.dashboardDatums = keyBy(dashboardWidget.dashboard_datums, 'value_definition_id');
    this.fetchValues();
  }

  private readonly updateChart = this.updater(
    (state: DashboardChartState, chart: ChartConfig): DashboardChartState => ({ ...state, chart }),
  );

  public readonly fetchValues = this.effect((trigger$) =>
    trigger$.pipe(
      filter(() => Boolean(this.dashboardWidget)),
      combineLatestWith(
        this.dashboardStore.source$.pipe(
          map<Source | undefined, [Source | undefined, number]>((s) => [s, this.changes[0] + 1]),
        ),
        this.dashboardStore.frequencyCode$.pipe(map<string, [string, number]>((fy) => [fy, this.changes[1] + 1])),
        this.dashboardStore.startFrequencyCode$.pipe(map<string, [string, number]>((fy) => [fy, this.changes[2] + 1])),
        this.dashboardStore.endFrequencyCode$.pipe(map<string, [string, number]>((fy) => [fy, this.changes[2] + 1])),
        this.dashboardStore.sources$.pipe(map<Source[], [Source[], number]>((s) => [s, this.changes[3] + 1])),
      ),
      tap(
        ([
          _,
          [_source, sourceChange],
          [_fc, fcChange],
          [_sfc, sfcChange],
          [_efc, efcChange],
          [_sources, sourcesChange],
        ]) => {
          if (this.hasChanges(sourceChange, fcChange, sfcChange, efcChange, sourcesChange)) {
            this.patchState({ isLoading: true });
          }

          this.changes[0] = sourceChange;
          this.changes[1] = fcChange;
          this.changes[2] = sfcChange;
          this.changes[3] = efcChange;
          this.changes[4] = sourcesChange;
        },
      ),
      map(([_, [source], [frequencyCode], [startFrequencyCode], [endFrequencyCode], [sources]]) =>
        source
          ? this.getSourcesAndFrequencyCodes(source, frequencyCode, startFrequencyCode, endFrequencyCode, sources)
          : [[], []],
      ),
      switchMap(([sources, frequencyCodes]) =>
        forkJoin([
          this.dashboardWidgetsApiService.getWidgetValues(this.dashboardWidget.dashboard_id, this.dashboardWidget.id, {
            business_unit_ids: sources.map((s) => s.id),
            frequency_codes: frequencyCodes,
          }),
          of(sources),
          of(frequencyCodes),
        ]),
      ),
      tapResponse(
        ([apiResponse, sources, frequencyCodes]) => {
          this.updateChart(this.formatData(apiResponse.data, sources, frequencyCodes));
          this.patchState({ isLoading: false });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );

  private formatData(values: Value[], sources: Source[], frequencyCodes: string[]): ChartConfig {
    let chartConfig: ChartConfig;

    if (!values.length && !sources.length && !frequencyCodes.length) {
      return { title: { text: this.dashboardWidget.label } };
    }

    switch (this.dashboardWidget.widget_type) {
      case DashboardWidgetType.BAR_CHART_WIDGET:
        chartConfig = this.formatBarChartData(values, sources, frequencyCodes);
        break;
      case DashboardWidgetType.STACKED_BAR_CHART_WIDGET:
        chartConfig = this.formatStackedBarChartData(values, sources, frequencyCodes);
        break;
      case DashboardWidgetType.LINE_CHART_WIDGET:
        chartConfig = this.formatLineChartData(values, sources, frequencyCodes);
        break;
      case DashboardWidgetType.DONUT_CHART_WIDGET:
        chartConfig = this.formatDonutChartData(values, sources, frequencyCodes);
        break;
      case DashboardWidgetType.DATA_POINT_WIDGET:
        chartConfig = this.formatDataPointChartData(values, sources, frequencyCodes);
        break;
      case DashboardWidgetType.PIE_CHART_WIDGET:
      default:
        chartConfig = this.formatPieChartData(values, sources, frequencyCodes);
        break;
    }

    if (!values.length) {
      chartConfig.series = [];
    }

    return chartConfig;
  }

  private formatBarChartData(
    values: Value[],
    sources: Source[],
    frequencyCodes: string[],
    chartType: ChartType.bar | ChartType.column = ChartType.bar,
  ): ChartConfig {
    const barChart = this.fetchBarChartData(values, sources, frequencyCodes, chartType);

    return {
      title: { text: this.dashboardWidget.label },
      subtitle: {
        text: `<b>Datum</b>: <span>${this.dashboardWidget.dashboard_datums[0].label}</span> - <b>Fiscal years:</b> <span>${frequencyCodes[0]} - ${frequencyCodes[frequencyCodes.length - 1]}</span>`,
      },
      series: Object.values(barChart),
      plotOptions: { series: { pointStart: Number(frequencyCodes[0]) } },
      xAxis: { categories: frequencyCodes },
    };
  }

  private formatStackedBarChartData(values: Value[], sources: Source[], frequencyCodes: string[]): ChartConfig {
    const stackedBarChart = this.formatBarChartData(values, sources, frequencyCodes, ChartType.column);
    return {
      ...stackedBarChart,
      plotOptions: { ...stackedBarChart.plotOptions, column: { stacking: ChartColumnPlotOptionStackingType.normal } },
    };
  }

  private formatLineChartData(values: Value[], sources: Source[], frequencyCodes: string[]): ChartConfig {
    const valueDefinitionIds = this.dashboardWidget.dashboard_datums.map((d) => d.value_definition_id);
    const valuesDict = values.reduce(
      (acc: Record<string, Value>, v) =>
        Object.assign(acc, { [`${v.value_group_set_frequency_code}-${v.value_definition_id}`]: v }),
      {},
    );
    const lineChart = valueDefinitionIds.reduce(
      (acc: Record<string, { name: string; data: number[]; type: ChartType.line }>, valueDefinitionId) => {
        frequencyCodes.forEach((fc) => {
          const v = valuesDict[`${fc}-${valueDefinitionId}`];

          if (!acc[valueDefinitionId]) {
            acc[valueDefinitionId] = { name: '', data: [], type: ChartType.line };
          }

          if (!acc[valueDefinitionId].name) {
            acc[valueDefinitionId].name = this.dashboardDatums[v?.value_definition_id || '']?.label;
          }

          acc[valueDefinitionId].data.push(Number(v?.value ?? 0));
        });

        return acc;
      },
      {},
    );

    return {
      title: { text: this.dashboardWidget.label },
      subtitle: {
        text: `<b>Source</b>: <span>${sources[0].name}</span> - <b>Fiscal years:</b> <span>${frequencyCodes[0]} - ${frequencyCodes[frequencyCodes.length - 1]}</span>`,
      },
      series: Object.values(lineChart),
      plotOptions: { series: { pointStart: Number(frequencyCodes[0]) } },
      xAxis: { tickInterval: 1 },
    };
  }

  private formatDonutChartData(
    values: Value[],
    sources: Source[],
    frequencyCodes: string[],
  ): ChartConfig<PieChartConfig> {
    const pieChartConfig = this.formatPieChartData(values, sources, frequencyCodes);
    const pieChartSeries = pieChartConfig.series?.[0];
    return {
      ...pieChartConfig,
      series: pieChartSeries ? [{ ...pieChartSeries, size: '100%', innerSize: '80%' }] : [],
      legend: { enabled: false },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderRadius: 8,
          dataLabels: [
            { enabled: true, distance: 20, format: '{point.name}' },
            { enabled: true, distance: -15, format: '{point.percentage:.0f}%', style: { fontSize: '0.9em' } },
          ],
        },
      },
    };
  }

  private formatPieChartData(
    values: Value[],
    sources: Source[],
    frequencyCodes: string[],
  ): ChartConfig<PieChartConfig> {
    const valuesByVdId = keyBy(values, 'value_definition_id');
    return {
      ...this.formatDataPointChartData(values, sources, frequencyCodes),
      series: [
        {
          type: ChartType.pie,
          data: this.dashboardWidget.dashboard_datums.map((datum) => ({
            name: datum.label,
            y: Number(valuesByVdId[datum.value_definition_id]?.value ?? 0),
          })),
        },
      ],
    };
  }

  private formatDataPointChartData(
    values: Value[],
    sources: Source[],
    frequencyCodes: string[],
  ): ChartConfig<PieChartConfig> {
    return {
      title: { text: this.dashboardWidget.label },
      subtitle: {
        text: `<b>Source</b>: <span>${sources[0].name}</span> - <b>Fiscal year:</b> <span>${frequencyCodes[0]}</span>`,
      },
      series: [{ type: ChartType.pie, data: [Number(values[0]?.value ?? 0)] }],
    };
  }

  private hasChanges(
    sourceChange: number,
    frequencyCodeChange: number,
    startFrequencyCodeChange: number,
    endFrequencyCodeChange: number,
    sourcesChange: number,
  ): boolean {
    const hasSourceChange = sourceChange !== this.changes[0];
    const hasFrequencyCodeChange = frequencyCodeChange !== this.changes[1];
    const hasStartFrequencyCodeChange = startFrequencyCodeChange !== this.changes[2];
    const hasEndFrequencyCodeChange = endFrequencyCodeChange !== this.changes[3];
    const hasSourcesChange = sourcesChange !== this.changes[4];

    switch (this.dashboardWidget.widget_type) {
      case DashboardWidgetType.BAR_CHART_WIDGET:
      case DashboardWidgetType.STACKED_BAR_CHART_WIDGET:
        return hasSourcesChange || hasStartFrequencyCodeChange || hasEndFrequencyCodeChange;
      case DashboardWidgetType.LINE_CHART_WIDGET:
        return hasSourceChange || hasStartFrequencyCodeChange || hasEndFrequencyCodeChange;
      case DashboardWidgetType.DATA_POINT_WIDGET:
      case DashboardWidgetType.DONUT_CHART_WIDGET:
      case DashboardWidgetType.PIE_CHART_WIDGET:
      default:
        return hasSourceChange || hasFrequencyCodeChange;
    }
  }

  private getSourcesAndFrequencyCodes(
    source: Source,
    frequencyCode: string,
    startFrequencyCode: string,
    endFrequencyCode: string,
    sources: Source[],
  ): [Source[], string[]] {
    const frequencyCodes = range(Number(startFrequencyCode), Number(endFrequencyCode) + 1).map(String);

    switch (this.dashboardWidget.widget_type) {
      case DashboardWidgetType.BAR_CHART_WIDGET:
      case DashboardWidgetType.STACKED_BAR_CHART_WIDGET:
        return [sources.length ? sources : [source], frequencyCodes];
      case DashboardWidgetType.LINE_CHART_WIDGET:
        return [[source], frequencyCodes];
      case DashboardWidgetType.DATA_POINT_WIDGET:
      case DashboardWidgetType.DONUT_CHART_WIDGET:
      case DashboardWidgetType.PIE_CHART_WIDGET:
      default:
        return [[source], [frequencyCode]];
    }
  }

  private fetchBarChartData<T extends ChartType.bar | ChartType.column>(
    values: Value[],
    sources: Source[],
    frequencyCodes: string[],
    chartType: T,
  ): Record<string, ColumnChartConfig | BarChartConfig> {
    const valuesDict = values.reduce(
      (acc: Record<string, Value>, v) =>
        Object.assign(acc, { [`${v.value_group_set_frequency_code}-${v.value_group_set_business_unit_id}`]: v }),
      {},
    );

    return sources.reduce((acc: Record<string, ColumnChartConfig | BarChartConfig>, source) => {
      frequencyCodes.forEach((fc) => {
        const v = valuesDict[`${fc}-${source.id}`];
        acc[source.id] = acc[source.id] || { name: source.name, data: [], type: chartType };
        acc[source.id].data.push(Number(v?.value ?? 0));
      });

      return acc;
    }, {});
  }
}
