export enum ProgressBarClassColours {
  GREY_PROGRESS = 'grey-progress',
  BLUE_PROGRESS = 'blue-progress',
  GREEN_PROGRESS = 'green-progress',
}

export enum ProgressBarThickness {
  THIN = 'thin',
}

export enum ProgressBarMode {
  INDETERMINATE = 'indeterminate',
  DETERMINATE = 'determinate',
}
