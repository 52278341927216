import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toastIcon',
})
export class ToastIconPipe implements PipeTransform {
  transform(severity: string): string {
    switch (severity) {
      case 'error':
        return 'pi-times-circle';
      case 'warn':
        return 'pi-exclamation-triangle';
      case 'success':
        return 'pi-check';
      case 'info':
      default:
        return 'pi-info-circle';
    }
  }
}
