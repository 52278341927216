import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  SimpleChanges,
  OnDestroy,
  OnInit,
  OnChanges,
} from '@angular/core';

import { AuthService, EventsService } from '../../services/common';
import { SearchOptions, Collection, ActionItem, PresentationV2, DummySkeletonType } from '../../models';
import { BaseSearchItemsDirective } from '../base-search-items.directive';
import { SearchService } from '../services/search.service';
import { Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss'],
})
export class SearchItemsComponent extends BaseSearchItemsDirective implements OnInit, OnDestroy, OnChanges {
  disableItems: boolean = false;
  @ViewChild('spinner') spinner!: ElementRef<HTMLElement>;
  scrollObserver: IntersectionObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      this.loadMore();
    }
  });

  readonly eDummySkeletonType = DummySkeletonType;
  readonly ePresentationV2 = PresentationV2;
  itemCollection?: Collection<any>;
  selectedItemIDs: string[] = [];
  canClickItem: boolean = true;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    renderer: Renderer2,
    eventsService: EventsService,
    private searchService: SearchService,
    private authService: AuthService,
  ) {
    super(renderer, eventsService);
  }

  ngOnInit(): void {
    this.eventsService
      .getDisableSelection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((disabled: string) => {
        setTimeout(() => {
          this.disableItems = disabled === 'true';
        });
      });

    this.eventsService
      .getSelectedItem()
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedItemID) => {
        setTimeout(() => {
          this.selectedItemID = selectedItemID;
        });
        if (this.itemCollection) {
          const selectedItem: ActionItem | undefined = this.itemCollection.items.find(
            (item) => item.id === selectedItemID,
          );
          if (selectedItem) {
            const index = this.itemCollection.items.indexOf(selectedItem);
            let previousItemID: string = '';
            let nextItemID: string = '';
            if (index > 0) {
              previousItemID = this.itemCollection.items[index - 1].id;
            }
            if (index < this.itemCollection.items.length - 1) {
              nextItemID = this.itemCollection.items[index + 1].id;
            }
            this.eventsService.setPreviousItem(previousItemID);
            this.eventsService.setNextItem(nextItemID);
          }
        }
      });
    this.eventsService
      .getSelectedItems()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items: string[]) => {
        this.selectedItemIDs = items;
      });
    this.refresh.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.search();
      }
    });

    this.authService.getUserInfo().subscribe((user) => {
      this.canClickItem =
        !!user &&
        (this.clickItemPermissions.length === 0 || this.clickItemPermissions.some((p) => user.permissions.includes(p)));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // prevent search for unintended events
    // emit changes is triggered twice on search bar on keywords control enter, once with an Event object
    if (changes.searchOptions && typeof changes.searchOptions.currentValue === 'string') {
      // show spinner when search options changed and a new search starts
      // on initial search start spinner is not ready for the show method, but it will be rendered
      if (!changes.searchOptions.isFirstChange()) {
        this.showSpinner();
      }
      this.search();
    }
  }

  ngOnDestroy(): void {
    this.eventsService.setPreviousItem('');
    this.eventsService.setNextItem('');
    this.destroy$.next();
  }

  search(): void {
    if (this.searchOptions) {
      const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
      this.noData = !Object.keys(searchOptions.filters).length && !searchOptions.query.keywords;
      this.loaded = false;
      this.searchService
        .search(searchOptions, this.cache)
        .pipe(take(1))
        .subscribe((result) => {
          this.itemCollection = result;
          if (!this.itemCollection.items.length || this.itemCollection.items.length >= this.itemCollection.count) {
            this.hideSpinner();
          } else {
            // wait 0.5s for the dummies to fade out
            // spinner element is not available for observer before items become visible
            setTimeout(() => {
              this.scrollObserver.observe(this.spinner.nativeElement);
            }, 500);
          }
          this.loaded = true;
        });
    }
  }

  loadMore(): void {
    const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
    searchOptions.from = this.itemCollection!.items.length;
    this.searchService.search(searchOptions, this.cache).subscribe((result) => {
      this.itemCollection!.items = this.itemCollection!.items.concat(result.items);
      if (this.itemCollection!.items.length >= this.itemCollection!.count) {
        this.scrollObserver.disconnect();
        this.hideSpinner();
      }
    });
  }

  showSpinner(): void {
    setTimeout(() => {
      this.renderer.removeClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.renderer.addClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }
}
