import { Component } from '@angular/core';
import { ToastMessage, ToastPosition } from '../../models';

@Component({
  selector: 'lib-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  public eToastPosition = ToastPosition;

  public clickToast(message: ToastMessage): void {
    if (message.data?.callbackAction) {
      message.data.callbackAction();
    }
  }
}
