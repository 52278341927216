<mat-menu #menu="matMenu">
  @if (actions.action_list?.length) {
    <ng-template matMenuContent>
      @for (action of actions.action_list; track action) {
        <ng-container *libPermission="action.permissions">
          <ng-container *libFeatureFlag="{ featureFlags: action.featureFlag }">
            @if (action.id === "separator") {
              <lib-divider></lib-divider>
            }
            @if (action.id !== "separator") {
              <a
                mat-menu-item
                [ngClass]="{ 'color-error': action.id === 'delete' }"
                (click)="setAction(action)"
                [disabled]="action.disabled"
              >
                <mat-icon [svgIcon]="action.icon!" class="icon-sm"></mat-icon>
                <span>{{ action.title }}</span>
              </a>
            }
          </ng-container>
        </ng-container>
      }
    </ng-template>
  }
</mat-menu>

<mat-menu #dropDownMenu="matMenu">
  @if (actions.drop_down?.children) {
    <ng-template matMenuContent>
      @for (action of actions.drop_down?.children; track action) {
        <a mat-menu-item (click)="setAction(action)">
          @if (action.icon) {
            <mat-icon class="icon-sm" [svgIcon]="action.icon"></mat-icon>
          }
          <span>{{ action.title }}</span>
        </a>
      }
    </ng-template>
  }
</mat-menu>

<div class="toolbar" [ngClass]="class">
  <header data-testid="page-header">
    <div class="toolbar-title">
      <a class="open-menu" title="{{ 'Open menu' | translate }}" (click)="openMenu()">
        <mat-icon svgIcon="menu"></mat-icon>
      </a>
      @if (withNavigation) {
        <a
          class="parent-link"
          title="{{ 'Back' | translate }}"
          role="button"
          aria-label="backArrowNav"
          [routerLink]="parentLink"
          [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
        >
          <mat-icon svgIcon="arrow-full-left"></mat-icon>
        </a>
      }

      <ng-content select="[slot=title]"></ng-content>

      @if (title) {
        <div
          class="title"
          [ngClass]="{ editable: editable, 'no-tabs': !tabs.length }"
          (click)="edit()"
          [libViewerAction]="viewerAction.preventMouseAction"
        >
          @if (!titleEditing) {
            <div class="title-text" title="" [matTooltip]="title.length > 30 ? title : ''">{{ title }}</div>
            <mat-icon class="title-icon icon-md" svgIcon="edit"></mat-icon>
          }
          @if (titleEditing) {
            <input
              type="text"
              data-testid="text-input"
              title=""
              #titleInput
              [(ngModel)]="currentTitle"
              (keydown)="keyDown($event)"
            />
            <div class="title-actions">
              <a class="action-close" data-testid="cancel-edit" (click)="cancel($event)">
                <mat-icon svgIcon="close"></mat-icon>
              </a>
              <a class="action-save" (click)="save($event)">
                <mat-icon svgIcon="check"></mat-icon>
                {{ "Save" | translate }}
              </a>
            </div>
          }
        </div>
      }

      <lib-page-header-subtitles [subtitles]="subtitles" [useChips]="useChipsSubtitles"></lib-page-header-subtitles>
    </div>
    <div class="toolbar-actions">
      @if (progress) {
        <div class="fx-row justify-space-between align-center" style="width: 26rem">
          <lib-progress-bar [progress]="progress.percent || 0" style="width: 20rem"></lib-progress-bar>
          <!-- TODO: Make progress bar not fixed width? -->
          <div class="mat-body color-grey-200 ml-2">
            {{ "{current} of {total}" | translate: { current: progress.current, total: progress.total } }}
          </div>
        </div>
      }
      @if (actions.main_actions?.length) {
        @for (action of actions.main_actions; track action) {
          <a [title]="action.title" (click)="setAction(action)" [ngClass]="{ disabled: action.disabled }">
            <mat-icon [svgIcon]="action.icon!"></mat-icon>
          </a>
        }
      }
      @if (actions.action_list?.length) {
        <a
          title="{{ 'More' | translate }}"
          [matMenuTriggerFor]="menu"
          aria-label="header context menu"
          role="button"
          [ngClass]="{ active: menuOpened, disabled: actions.disabled }"
        >
          <mat-icon svgIcon="more"></mat-icon>
        </a>
      }
      @if (!disableHelp) {
        <a routerLink="/app/help" title="{{ 'Help' | translate }}">
          <mat-icon svgIcon="help"></mat-icon>
        </a>
      }
      <ng-content select="[slot=action-button]"></ng-content>
      @for (button of actions.buttons; track button) {
        <ng-container *libPermission="button.permissions">
          <a
            *libFeatureFlag="{ featureFlags: button.featureFlag }"
            lib-button
            buttonType="success"
            aria-label="headerActionButton"
            role="button"
            (click)="setAction(button)"
            [disabled]="!!button.disabled"
            [label]="button.title"
            [libViewerAction]="button.viewerAction"
          ></a>
        </ng-container>
      }
      @if (actions.drop_down) {
        <a
          class="btn btn-dropdown fx-row align-center {{ actions.drop_down.text || 'bkg-white' }}"
          [title]="actions.drop_down.title"
          [matMenuTriggerFor]="dropDownMenu"
        >
          <span class="mr-1">{{ actions.drop_down.item?.title }}</span>
          <mat-icon svgIcon="arrow-head-down"></mat-icon>
        </a>
      }
    </div>
  </header>
  @if (tabs.length) {
    <nav>
      @for (tab of tabs; track tab) {
        <ng-container *libPermission="tab.permissions">
          <a
            role="tab"
            [routerLink]="tab.id"
            routerLinkActive="active"
            [ngClass]="{ disabled: tab.disabled }"
            [title]="tab.title"
            *libFeatureFlag="{ featureFlags: tab.featureFlag }"
          >
            {{ tab.title }}
          </a>
        </ng-container>
      }
    </nav>
  }
</div>
