import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { ConfirmationDialogComponent, DialogsService } from '../dialogs';
import {
  ApiResponse,
  ConfirmationDialogConfig,
  ConfirmedItem,
  Dashboard,
  DashboardDatum,
  DashboardStatus,
  DashboardUpsertRequest,
  DashboardWidget,
  DialogResult,
  DialogSize,
  Status,
  ToastStatus,
} from '../models';
import { TranslateService } from '../services/common';
import { DashboardDatumsApiService, DashboardsApiService, DashboardWidgetsApiService } from '../services/api-services';
import { ToastService } from '../components/toast';
import {
  DashboardUpsertDialogComponent,
  DashboardUpsertDialogData,
  DashboardUpsertDialogResults,
} from './dashboard-grid/dashboard-upsert-dialog/dashboard-upsert-dialog.component';
import { ObservableUtils } from '../classes';
import { DashboardsHelperTranslations } from './dashboards.translation';
import {
  UpsertDashboardDatumDialogComponent,
  UpsertDashboardDatumDialogData,
  UpsertDashboardDatumDialogResults,
} from './dashboard-datums-sidebar/upsert-dashboard-datum-dialog/upsert-dashboard-datum-dialog.component';
import { ViewContainerRef } from '@angular/core';

export class DashboardsHelper {
  constructor(
    private readonly dashboardsApiService: DashboardsApiService,
    private readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
    private readonly dashboardDatumsApiService: DashboardDatumsApiService,
    private readonly dialogsService: DialogsService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
  ) {}

  public copyDashboard(dashboard: Dashboard, viewContainerRef: ViewContainerRef): Observable<ApiResponse<Dashboard>> {
    return this.openUpsertDashboardDialog(
      {
        dashboard,
        cta: this.translateService.instant(DashboardsHelperTranslations.copy),
        copy: true,
      },
      viewContainerRef,
    ).pipe(
      switchMap((request) => this.dashboardsApiService.copyDashboard(dashboard.id, request)),
      tap((res: ApiResponse<Dashboard>) => {
        this.toastService.open(
          ToastStatus.SUCCESS,
          this.translateService.instant(DashboardsHelperTranslations.created, { name: res.data.name }),
        );
      }),
    );
  }

  public createDashboard(viewContainerRef: ViewContainerRef): Observable<ApiResponse<Dashboard>> {
    return this.openUpsertDashboardDialog(
      {
        cta: this.translateService.instant(DashboardsHelperTranslations.create),
      },
      viewContainerRef,
    ).pipe(switchMap((request) => this.dashboardsApiService.createDashboard({ ...request, v2: true })));
  }

  public deleteDashboard(dashboard: Dashboard): Observable<undefined> {
    return this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant(DashboardsHelperTranslations.deleteDashboard),
          warningMsg: this.translateService.instant(
            dashboard.status === DashboardStatus.PUBLISHED
              ? DashboardsHelperTranslations.publishedWarning
              : DashboardsHelperTranslations.deleteDashboardWarning,
          ),
        },
      })
      .afterClosed()
      .pipe(
        filter((dialogResult?: DialogResult<ConfirmedItem>) => dialogResult?.status === Status.CONFIRMED),
        switchMap(() => this.dashboardsApiService.deleteDashboard(dashboard.id)),
        tap(() => {
          this.toastService.open(
            ToastStatus.SUCCESS,
            this.translateService.instant(DashboardsHelperTranslations.deletedDashboard),
          );
        }),
      );
  }

  public deleteDashboardWidget(dashboardWidget: DashboardWidget): Observable<undefined> {
    return this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant(DashboardsHelperTranslations.removeWidget),
          warningMsg: this.translateService.instant(DashboardsHelperTranslations.deleteWidgetWarning),
          primaryBtn: this.translateService.instant(DashboardsHelperTranslations.yes),
          status: Status.SUCCESS,
        },
      })
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter((dialogResult: DialogResult) => dialogResult.status === Status.CONFIRMED),
        switchMap(() => this.dashboardWidgetsApiService.deleteWidget(dashboardWidget.dashboard_id, dashboardWidget.id)),
        tap(() => {
          this.toastService.open(
            ToastStatus.SUCCESS,
            this.translateService.instant(DashboardsHelperTranslations.deletedWidget),
          );
        }),
      );
  }

  public editDashboard(dashboard: Dashboard, viewContainerRef: ViewContainerRef): Observable<ApiResponse<Dashboard>> {
    return this.openUpsertDashboardDialog(
      {
        dashboard,
        cta: this.translateService.instant(DashboardsHelperTranslations.rename),
      },
      viewContainerRef,
    ).pipe(
      switchMap((request) => this.dashboardsApiService.renameDashboard(dashboard.id, request)),
      tap(() => {
        this.toastService.open(
          ToastStatus.SUCCESS,
          this.translateService.instant(DashboardsHelperTranslations.renamedDashboard),
        );
      }),
    );
  }

  public publishDashboard(dashboard: Dashboard): Observable<ApiResponse<Dashboard>> {
    return this.dashboardsApiService.publishDashboard(dashboard.id).pipe(
      tap(() => {
        this.toastService.open(
          ToastStatus.SUCCESS,
          this.translateService.instant(DashboardsHelperTranslations.publishedDashboard),
        );
      }),
    );
  }

  public unpublishDashboard(dashboard: Dashboard): Observable<ApiResponse<Dashboard>> {
    return this.dashboardsApiService.unpublishDashboard(dashboard.id).pipe(
      tap(() => {
        this.toastService.open(
          ToastStatus.SUCCESS,
          this.translateService.instant(DashboardsHelperTranslations.unpublishedDashboard),
        );
      }),
    );
  }

  public upsertDatum(
    data: UpsertDashboardDatumDialogData,
    viewContainerRef: ViewContainerRef,
  ): Observable<DashboardDatum> {
    return this.dialogsService
      .open<
        UpsertDashboardDatumDialogComponent,
        UpsertDashboardDatumDialogData,
        DialogResult<UpsertDashboardDatumDialogResults>
      >(UpsertDashboardDatumDialogComponent, { data: { ...data, size: DialogSize.large }, viewContainerRef })
      .afterClosed()
      .pipe(
        filter((dialogResult) => dialogResult?.status === Status.SUCCESS),
        map((dialogResult) => dialogResult?.data?.payload),
        ObservableUtils.filterNullish(),
        switchMap((payload) =>
          data.dashboardDatum
            ? this.dashboardDatumsApiService.editDashboardDatum(data.dashboardDatum.id, payload)
            : this.dashboardDatumsApiService.createDashboardDatum(payload),
        ),
        map((res) => res.data),
        tap(() => {
          this.toastService.open(
            ToastStatus.SUCCESS,
            this.translateService.instant(
              data.dashboardDatum
                ? DashboardsHelperTranslations.changesSaved
                : DashboardsHelperTranslations.createdDatum,
            ),
          );
        }),
      );
  }

  private openUpsertDashboardDialog(
    data: DashboardUpsertDialogData,
    viewContainerRef: ViewContainerRef,
  ): Observable<DashboardUpsertRequest> {
    return this.dialogsService
      .open<DashboardUpsertDialogComponent, DashboardUpsertDialogData>(DashboardUpsertDialogComponent, {
        data,
        viewContainerRef,
      })
      .afterClosed()
      .pipe(
        filter((dialogResult?: DialogResult<DashboardUpsertDialogResults>) => dialogResult?.status === Status.SUCCESS),
        map((dialogResult?: DialogResult<DashboardUpsertDialogResults>) => dialogResult?.data?.request),
        ObservableUtils.filterNullish(),
      );
  }
}
