import { CheckPublicIndicatorValuesUpdated, IContextSettings, Value, ValueGroup } from '../../models';

export enum EmbedderFontColors {
  deleted = '98a2b3',
}

export enum EmbedderHighlightColors {
  deleted = 'eaecf0',
  none = '',
  standard = 'bbeddf',
  updated = 'fbcc8e',
}

export enum EmbedderValueField {
  explanation = 'explanation',
  page = 'page',
  url = 'url',
  value = 'value',
}

export type ExcelEmbeddedValues = Record<string, string>;

export interface FormatOptions {
  plainNumber?: boolean;
}

export interface EmbedderInsertOptions {
  complement?: string;
  controlId?: string;
  highlightColor?: EmbedderHighlightColors;
}

export interface EmbedderValue {
  id: string;
  group?: Value[];
  indicatorId: string;
  metricId: string;
  table?: ValueGroup[];
  tableTotals?: ValueGroup;
  value?: Value;
}

export interface EmbedderValueId {
  embedderValue: EmbedderValue;
  field: EmbedderValueField;
  fieldId?: string;
  fiscalYear: string;
  formattedValue: string | string[];
  indicatorId: string;
  metricId: string;
  sourceId: string;
  tableId?: string | null;
  valueDefinitionId?: string;
}

export interface UpdateEmbedderValuesOptions {
  changes?: CheckPublicIndicatorValuesUpdated[];
  context?: Partial<IContextSettings>;
  updatedValues?: boolean;
}
