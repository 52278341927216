import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { takeUntil } from 'rxjs';
import { ActionItem, FiscalYear, Source } from '../../models';
import { DashboardOptions } from './dashboard.component';
import { TreeNode } from 'primeng/api';

export interface FilterOptionsState {
  fiscalYears: ActionItem<FiscalYear>[];
  sources: ActionItem<Source>[];
}

export interface DashboardState {
  source?: Source;
  sources: Source[];
  frequencyCode: string;
  endFrequencyCode: string;
  startFrequencyCode: string;
}

@Injectable()
export class DashboardStore extends ComponentStore<DashboardState> {
  private static readonly DEFAULT_STATE: DashboardState = {
    frequencyCode: '',
    endFrequencyCode: '',
    startFrequencyCode: '',
    sources: [],
  };

  public readonly frequencyCode$ = this.select((state) => state.frequencyCode);
  public readonly endFrequencyCode$ = this.select((state) => state.endFrequencyCode);
  public readonly startFrequencyCode$ = this.select((state) => state.startFrequencyCode);
  public readonly source$ = this.select((state) => state.source);
  public readonly sources$ = this.select((state) => state.sources);

  constructor() {
    super(DashboardStore.DEFAULT_STATE);
  }

  public init(options: DashboardOptions): void {
    options.frequencyValueChanges?.pipe(takeUntil(this.destroy$)).subscribe({
      next: (frequencyCode) => {
        this.updateFrequencyCodeState(frequencyCode);
      },
    });
    options.startValueChanges?.pipe(takeUntil(this.destroy$)).subscribe({
      next: (frequencyCode) => {
        this.updateStartFrequencyCodeState(frequencyCode);
      },
    });
    options.endValueChanges?.pipe(takeUntil(this.destroy$)).subscribe({
      next: (frequencyCode) => {
        this.updateEndFrequencyCodeState(frequencyCode);
      },
    });
    options.sourceValueChanges?.pipe(takeUntil(this.destroy$)).subscribe({
      next: (sourceId?: string | null) => {
        this.updateSourceState(options.sources?.find((s) => s.id === sourceId));
      },
    });
    options.multiSourceValueChanges?.pipe(takeUntil(this.destroy$)).subscribe({
      next: (sourceIds?: string[] | Required<Pick<TreeNode<Source>, 'label' | 'key'>>[] | null) => {
        const ids = sourceIds?.map((id) => (typeof id === 'string' ? id : id.key)) || [];
        this.updateSourcesState(options.sources?.filter((s) => ids.includes(s.id)) || []);
      },
    });

    this.patchState({
      frequencyCode: options.initialEnd,
      endFrequencyCode: options.initialEnd,
      startFrequencyCode: options.initialStart,
      source: options.initialSource,
      sources: options.initialMultiSource,
    });
  }

  private readonly updateSourceState = this.updater(
    (state: DashboardState, source?: Source): DashboardState => ({ ...state, source }),
  );

  private readonly updateSourcesState = this.updater(
    (state: DashboardState, sources: Source[]): DashboardState => ({ ...state, sources }),
  );

  private readonly updateFrequencyCodeState = this.updater(
    (state: DashboardState, frequencyCode: string): DashboardState => ({ ...state, frequencyCode }),
  );

  private readonly updateEndFrequencyCodeState = this.updater(
    (state: DashboardState, endFrequencyCode: string): DashboardState => ({ ...state, endFrequencyCode }),
  );

  private readonly updateStartFrequencyCodeState = this.updater(
    (state: DashboardState, startFrequencyCode: string): DashboardState => ({ ...state, startFrequencyCode }),
  );
}
