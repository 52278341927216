import { NgModule } from '@angular/core';

import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { ChartsModule } from '../charts';
import { CommonModule, NgForOf } from '@angular/common';
import { LayoutModule } from '../layout';
import { DashboardDummyLoaderComponent } from './dashboard-dummy-loader/dashboard-dummy-loader.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { DashboardGridComponent } from './dashboard-grid/dashboard-grid.component';
import { TablesModule } from '../tables';
import { ComponentsModule } from '../components';
import { SearchModule } from '../search';
import { DashboardUpsertDialogComponent } from './dashboard-grid/dashboard-upsert-dialog/dashboard-upsert-dialog.component';
import { DialogsModule } from '../dialogs';
import { DirectivesModule } from '../directives';
import { DashboardDatumsSidebarComponent } from './dashboard-datums-sidebar/dashboard-datums-sidebar.component';
import { DashboardDatumsComponent } from './dashboard-datums-sidebar/dashboard-datums/dashboard-datums.component';
import { UpsertDashboardDatumDialogComponent } from './dashboard-datums-sidebar/upsert-dashboard-datum-dialog/upsert-dashboard-datum-dialog.component';
import { MetricSearchModule } from '../metric-search';
import { SelectMetricValueDefinitionModule } from '../select-metric-value-definition';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardChartComponent } from './dashboard/dashboard-chart/dashboard-chart.component';
import { DashboardViewerComponent } from './dashboard-viewer/dashboard-viewer.component';
import { DashboardHasAvailableSpacePipe } from './pipes/dashboard-has-available-space.pipe';
import { DashboardWidgetUpsertSidebarComponent } from './dashboard-viewer/dashboard-widget-upsert-sidebar/dashboard-widget-upsert-sidebar.component';
import { RenameDashboardDatumDialogComponent } from './dashboard-datums-sidebar/rename-dashboard-datum-dialog/rename-dashboard-datum-dialog.component';

@NgModule({
  declarations: [
    DashboardDummyLoaderComponent,
    DashboardViewComponent,
    // V2
    DashboardGridComponent,
    DashboardUpsertDialogComponent,
    DashboardDatumsSidebarComponent,
    DashboardDatumsComponent,
    RenameDashboardDatumDialogComponent,
    UpsertDashboardDatumDialogComponent,
    DashboardComponent,
    DashboardChartComponent,
    DashboardViewerComponent,
    DashboardHasAvailableSpacePipe,
    DashboardWidgetUpsertSidebarComponent,
  ],
  imports: [
    MaterialModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    ChartsModule,
    GridsterModule,
    NgForOf,
    LayoutModule,
    CommonModule,
    ComponentsModule,
    DialogsModule,
    TablesModule,
    SearchModule,
    DirectivesModule,
    MetricSearchModule,
    SelectMetricValueDefinitionModule,
  ],
  exports: [
    DashboardDummyLoaderComponent,
    DashboardViewComponent,
    // V2
    DashboardGridComponent,
    DashboardViewerComponent,
  ],
})
export class DashboardsModule {}
