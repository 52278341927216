<lib-dialog-title
  [title]="(data.dashboardDatum ? 'Edit data point' : 'Add data point') | translate"
  (closeEvent)="close()"
>
</lib-dialog-title>

<div mat-dialog-content class="mat-dialog-content">
  <lib-stepper
    (selectedIndexChange)="onSelectedIndexChange($event)"
    [selectedIndex]="selectedIndex"
    [steps]="[
      { content: step1, customLabel: step1CustomLabel },
      { content: step2, customLabel: step2CustomLabel, disabled: selectedIndicator === undefined },
      { content: step3, title: 'Edit Data Point' | translate, disabled: selectedValueDefinition === undefined },
    ]"
  ></lib-stepper>
</div>

<div mat-dialog-actions>
  @if (selectedIndex !== eStepperSteps.SELECT_METRIC_STEP) {
    <button lib-button buttonType="cancel_with_border" class="ml-3" (click)="back()">
      {{ "Back" | translate: { index: selectedIndex } }}
    </button>
  }

  <button lib-button class="ml-auto" buttonType="cancel_with_border" (click)="close()">
    {{ "Cancel" | translate }}
  </button>

  @if (selectedIndex !== eStepperSteps.CONFIGURE_DATUM_STEP) {
    <button
      lib-button
      buttonType="primary"
      class="ml-3"
      [disabled]="
        (selectedIndex === eStepperSteps.SELECT_METRIC_STEP && !selectedIndicator) ||
        (selectedIndex === eStepperSteps.SELECT_VALUE_DEFINITION_STEP && !selectedValueDefinition)
      "
      (click)="next()"
    >
      {{ "Next" | translate }}
    </button>
  }

  @if (selectedIndex === eStepperSteps.CONFIGURE_DATUM_STEP) {
    <button
      lib-button
      buttonType="primary"
      class="ml-3"
      [disabled]="selectedIndex === eStepperSteps.CONFIGURE_DATUM_STEP && form?.invalid | boolean"
      (click)="next()"
    >
      {{ (data.dashboardDatum ? "Save" : "Add") | translate }}
    </button>
  }
</div>

<ng-template #step1>
  <div class="table-wrapper">
    <lib-metric-search
      [filters]="data.options.filters || {}"
      [withCategoryFilter]="data.options.withCategoryFilter | boolean"
      [withMetricGroupFilter]="data.options.withMetricGroupFilter | boolean"
      [withStandardCodeFilter]="data.options.withStandardCodeFilter | boolean"
      [withTagFilter]="data.options.withTagFilter | boolean"
      [withInfiniteScroll]="false"
      [withRelatedToColumn]="false"
      [isCheckable]="false"
      [showRowSelection]="true"
      [withAISearch]="data.options.withAISearch | boolean"
      (indicatorClick)="onSelectMetric($event)"
    ></lib-metric-search>
  </div>
</ng-template>

<ng-template #step1CustomLabel>
  <div class="custom-label">
    <div>{{ "Select Metric" | translate }}</div>
    @if (selectedIndicator) {
      <div class="custom-label-subtitle pt-1">{{ selectedIndicator.code }}</div>
    }
  </div>
</ng-template>

<ng-template #step2CustomLabel>
  <div class="custom-label">
    <div>{{ "Select Data Point" | translate }}</div>
    @if (selectedValueDefinition) {
      <div libEllipsifyMe [maxCharCount]="20" class="custom-label-subtitle pt-1">
        {{ selectedValueDefinition.label }}
      </div>
    }
  </div>
</ng-template>

<ng-template #step2>
  <div class="table-wrapper">
    @if (selectedIndicator?.metric_id || selectedIndicator?.id; as metricId) {
      <lib-select-metric-value-definition
        [metricId]="metricId"
        [supportedFieldTypes]="supportedValueDefinitionFieldType"
        (valueDefinitionSelected)="onSelectValueDefinition($event)"
      ></lib-select-metric-value-definition>
    }
  </div>
</ng-template>

<ng-template #step3>
  <div class="form">
    @if (form) {
      <lib-text-input
        class="formfield medium"
        [control]="form.controls.label"
        [label]="'Edit data point name' | translate"
        [maxCharacterCount]="60"
        [messages]="{ isUnique: 'A data point with this label already exists, choose a different label.' | translate }"
      ></lib-text-input>
    }
  </div>
</ng-template>
