import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardGridStore } from './dashboard-grid.store';
import {
  ApiResponse,
  Dashboard,
  DashboardPublicVisibility,
  DashboardStatus,
  EmptyResults,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  LimitedUser,
  Permission,
  SearchOptionFilters,
  RowSelectionEvent,
  TableActionMenuItemEvent,
  TableColumn,
  TablePageEvent,
} from '../../models';
import { FilterService, TranslateService } from '../../services/common';
import { combineLatestWith, map, Observable, tap } from 'rxjs';
import {
  DashboardPublicVisibilityToIcon,
  DashboardPublicVisibilityToText,
  DashboardStatusToText,
} from '../dashboards.translation';
import { DialogsService } from '../../dialogs';
import {
  AuthApiService,
  DashboardDatumsApiService,
  DashboardsApiService,
  DashboardWidgetsApiService,
} from '../../services/api-services';
import { ToastService } from '../../components/toast';
import { DashboardsHelper } from '../dashboards-helper';

export interface DashboardGridOptions {
  filters?: SearchOptionFilters;
  withAISearch?: boolean;
  withDefaultForColumn?: boolean;
  withCategoryFilter?: boolean;
  withMetricGroupFilter?: boolean;
  withStatusAction?: boolean;
  withShareAction?: boolean;
  withStandardCodeFilter?: boolean;
  withStatusColumn?: boolean;
  withTagFilter?: boolean;
  withVisibilityColumn?: boolean;
}

interface Data {
  datumsSidebarVisible: boolean;
  dashboards: Dashboard[];
  isLoading: boolean;
  tableFilters: FilterBarOption[];
  total: number;
}

@Component({
  selector: 'lib-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.scss'],
  providers: [provideComponentStore(DashboardGridStore)],
})
export class DashboardGridComponent implements OnInit, AfterContentInit {
  @ViewChild('defaultForCell', { static: true }) defaultForCell?: TemplateRef<{ $implicit: string }>;
  @ViewChild('ownerCell', { static: true }) ownerCell?: TemplateRef<{ $implicit: string }>;
  @ViewChild('statusCell', { static: true }) statusCell?: TemplateRef<{ $implicit: DashboardStatus }>;
  @ViewChild('visibilityCell', { static: true }) visibilityCell?: TemplateRef<{ $implicit: DashboardPublicVisibility }>;

  @Input() options: DashboardGridOptions = {};

  @Output() share = new EventEmitter<Dashboard>();
  @Output() view = new EventEmitter<RowSelectionEvent<Dashboard>>();

  public readonly eDashboardStatusToText: Record<string, string> = DashboardStatusToText;
  public readonly eDashboardPublicVisibilityToText: Record<string, string> = DashboardPublicVisibilityToText;
  public readonly eDashboardPublicVisibilityToIcon: Record<string, string> = DashboardPublicVisibilityToIcon;
  public readonly ePermission = Permission;
  public readonly emptyResults: EmptyResults = { title: this.translateService.instant('No result found') };

  public actionMenuItems: TableActionMenuItemEvent<Dashboard>[] = [];
  public columns: TableColumn<Dashboard>[] = [];
  public data$?: Observable<Data>;
  public defaultDashboard: Dashboard | null = null;
  public users: LimitedUser[] = [];

  private dashboardsHelper: DashboardsHelper;

  constructor(
    private readonly authApiService: AuthApiService,
    public readonly dashboardGridStore: DashboardGridStore,
    public readonly dashboardWidgetsApiService: DashboardWidgetsApiService,
    readonly dashboardsApiService: DashboardsApiService,
    readonly dashboardDatumsApiService: DashboardDatumsApiService,
    readonly dialogsService: DialogsService,
    private readonly filterService: FilterService,
    readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.dashboardsHelper = new DashboardsHelper(
      dashboardsApiService,
      dashboardWidgetsApiService,
      dashboardDatumsApiService,
      dialogsService,
      toastService,
      translateService,
    );
  }

  public ngOnInit(): void {
    this.setupActionMenuItems();

    this.data$ = this.dashboardGridStore.filterOptionsState$.pipe(
      combineLatestWith(
        this.dashboardGridStore.defaultDashboard$,
        this.dashboardGridStore.dashboards$,
        this.dashboardGridStore.isLoading$,
        this.dashboardGridStore.total$,
        this.dashboardGridStore.datumsSidebarVisible$,
      ),
      tap(([filterOptionsState, defaultDashboard]) => {
        this.users = filterOptionsState.users;
        this.defaultDashboard = defaultDashboard;
      }),
      map(([_filterOptionsState, _defaultDashboard, dashboards, isLoading, total, datumsSidebarVisible]) => ({
        datumsSidebarVisible,
        dashboards,
        isLoading,
        tableFilters: this.setupTableFilters(),
        total,
      })),
    );

    this.dashboardGridStore.initialize(this.options);
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public onCopyDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.copyDashboard(dashboard, this.viewContainerRef).subscribe(() => {
      this.dashboardGridStore.fetchDashboards();
    });
  };

  public onCreateDashboard(): void {
    this.dashboardsHelper.createDashboard(this.viewContainerRef).subscribe((res: ApiResponse<Dashboard>) => {
      this.view.emit({ data: res.data });
    });
  }

  public onDeleteDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.deleteDashboard(dashboard).subscribe(() => {
      this.dashboardGridStore.fetchDashboards();
    });
  };

  public onEditDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.editDashboard(dashboard, this.viewContainerRef).subscribe(() => {
      this.dashboardGridStore.fetchDashboards();
    });
  };

  public onFilterChange(selections: FilterBarSelection[]): void {
    this.dashboardGridStore.updateFilterState(this.filterService.sanitizedFilterOptions(selections, true));
  }

  public onPageChange = (event: TablePageEvent): void => {
    this.dashboardGridStore.updatePaginationState({ currentPage: event.currentPage, pageSize: event.pageSize });
  };

  public onPublishDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.publishDashboard(dashboard).subscribe(() => {
      this.dashboardGridStore.fetchDashboards();
    });
  };

  public onSearchQueryChange(searchQuery: string): void {
    this.dashboardGridStore.updateFilterStateSearchQuery(searchQuery);
  }

  public onShareDashboard = (dashboard: Dashboard): void => {
    this.share.emit(dashboard);
  };

  public onUnpublishDashboard = (dashboard: Dashboard): void => {
    this.dashboardsHelper.unpublishDashboard(dashboard).subscribe(() => {
      this.dashboardGridStore.fetchDashboards();
    });
  };

  public onViewDashboard(dashboardRow: RowSelectionEvent<Dashboard>): void {
    if (this.isAuthorizedToManageDashboard(dashboardRow.data)) {
      this.view.emit(dashboardRow);
    }
  }

  public setDatumsSidebarVisibility(visibility: boolean): void {
    this.dashboardGridStore.updateDatumsSidebarVisibleState(visibility);
  }

  public showConditionDeleteDashboard = (dashboard: Dashboard): boolean =>
    dashboard.id !== this.defaultDashboard?.id && this.isAuthorizedToManageDashboard(dashboard);

  private isAuthorizedToManageOwnDashboard = (dashboard: Dashboard): boolean =>
    dashboard.created_by === this.authApiService.user?.user_id;

  private isAuthorizedToManageDashboard = (dashboard: Dashboard): boolean =>
    dashboard.visibility !== DashboardPublicVisibility.RESTRICTED || this.isAuthorizedToManageOwnDashboard(dashboard);

  private setupActionMenuItems(): void {
    this.actionMenuItems = [
      {
        label: this.translateService.instant('Rename'),
        icon: 'pencil',
        onClick: this.onEditDashboard,
        showCondition: this.isAuthorizedToManageDashboard,
        permissions: [Permission.DASHBOARDS_MANAGE],
      },
      {
        label: this.translateService.instant('Delete'),
        icon: 'trash',
        onClick: this.onDeleteDashboard,
        showCondition: this.showConditionDeleteDashboard,
        permissions: [Permission.DASHBOARDS_MANAGE],
      },
      {
        label: this.translateService.instant('Copy'),
        icon: 'copy',
        onClick: this.onCopyDashboard,
        permissions: [Permission.DASHBOARDS_MANAGE],
      },
    ];

    if (this.options.withShareAction) {
      this.actionMenuItems.push({
        label: this.translateService.instant('Share'),
        icon: 'share-alt',
        onClick: this.onShareDashboard,
        showCondition: this.isAuthorizedToManageOwnDashboard,
        permissions: [Permission.DASHBOARDS_MANAGE],
      });
    }

    if (this.options.withStatusAction) {
      this.actionMenuItems.push({
        label: this.translateService.instant('Publish'),
        icon: 'verified',
        onClick: this.onPublishDashboard,
        showCondition: this.showConditionStatusDashboard(DashboardStatus.DRAFT),
        permissions: [Permission.DASHBOARDS_MANAGE],
      });

      this.actionMenuItems.push({
        label: this.translateService.instant('Unpublish'),
        icon: 'stop-circle',
        onClick: this.onUnpublishDashboard,
        showCondition: this.showConditionStatusDashboard(DashboardStatus.PUBLISHED),
        permissions: [Permission.DASHBOARDS_MANAGE],
      });
    }
  }

  private setupColumns(): void {
    this.columns = [
      { name: this.translateService.instant('Dashboard Name'), dataKey: 'name', width: '100rem' },
      { name: this.translateService.instant('Owner'), dataKey: 'created_by', cellTemplate: this.ownerCell },
    ];

    if (this.options.withDefaultForColumn) {
      this.columns.push({
        name: this.translateService.instant('Default For'),
        dataKey: 'id',
        cellTemplate: this.defaultForCell,
      });
    }

    if (this.options.withVisibilityColumn) {
      this.columns.push({
        name: this.translateService.instant('Visibility'),
        dataKey: 'visibility',
        cellTemplate: this.visibilityCell,
      });
    }

    if (this.options.withStatusColumn) {
      this.columns.push({
        name: this.translateService.instant('Status'),
        dataKey: 'status',
        cellTemplate: this.statusCell,
      });
    }
  }

  private setupTableFilters(): FilterBarOption[] {
    const tableFilters: FilterBarOption[] = [];
    const user = this.authApiService.user;

    if (!user) {
      return tableFilters;
    }

    tableFilters.push({
      icon: 'client',
      title: this.translateService.instant('Owner'),
      id: 'owner',
      optionType: FilterType.list,
      displayAll: true,
      options: [{ id: user.user_id, title: 'Me' }],
    });

    if (this.options.withStatusColumn) {
      tableFilters.push({
        icon: 'show',
        title: this.translateService.instant('Status'),
        id: 'status',
        optionType: FilterType.list,
        displayAll: true,
        options: Object.values(DashboardStatus).map((status) => ({
          id: status,
          title: this.translateService.instant(DashboardStatusToText[status]),
        })),
      });
    }

    if (this.options.withVisibilityColumn) {
      tableFilters.push({
        icon: 'show',
        title: this.translateService.instant('Visibility'),
        id: 'visibility',
        optionType: FilterType.list,
        displayAll: true,
        options: Object.values(DashboardPublicVisibility).map((visibility) => ({
          id: visibility,
          title: this.translateService.instant(DashboardPublicVisibilityToText[visibility]),
        })),
      });
    }

    return tableFilters;
  }

  public showConditionStatusDashboard =
    (status: DashboardStatus): ((dashboard: Dashboard) => boolean) =>
    (dashboard: Dashboard): boolean =>
      dashboard.status === status && this.isAuthorizedToManageDashboard(dashboard);
}
