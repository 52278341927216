import { TemplateRef, Type } from '@angular/core';
import { DataTableExpandedRowProperty } from '../data-table';
import { Permission } from './index';
import { FeatureFlagKeys } from '../feature-flag';

export type TableSortDirection = 'asc' | 'desc';

export interface TableGridExpandedRowConfig {
  component: Type<unknown>;
  componentProperties: DataTableExpandedRowProperty[];
}

export interface TableRowOrderEvent {
  previousIndex: number;
  newIndex: number;
}

export interface TableSortEvent {
  field: string;
  direction: TableSortDirection;
}

export interface TablePageEvent {
  currentPage: number;
  pageSize: number;
}

export interface PaginationState extends TablePageEvent {}

export interface TableActionMenuItemEvent<T> {
  label?: string;
  icon?: string;
  showCondition?: (rowData: T) => boolean;
  onClick?: (rowData: T) => void;
  featureFlag?: FeatureFlagKeys | FeatureFlagKeys[];
  featureFlagHiddenWhenEnabled?: boolean;
  permissions?: Permission[];
  isSeparator?: boolean;
  disabled?: boolean;
}

export interface TableHorizontalPaginatorConfiguration {
  currentIndex: number;
  isLoading?: boolean;
  pageSize: number;
  total: number;
}

export interface TableColumnFilter {
  columnName: string;
  value: string;
  matchMode?: string;
  operator?: string;
}

export interface TableRowCheckedEvent<T> {
  rowItem: T;
  isChecked: boolean;
}

export interface TreeTableColumn<T> {
  dataKey: keyof T | string;
  name?: string;
  cellTemplate?: TemplateRef<unknown>;
}

export interface TreeTableDataNode<T> {
  id: string;
  selected?: boolean;
  expanded?: boolean;
  data: T;
  disabled?: boolean;
  children_mutually_exclusive?: boolean;
  children?: TreeTableDataNode<T>[];
  parent?: TreeTableDataNode<T>;
}

export interface TreeTableEvent<T> {
  originalEvent: MouseEvent;
  node: TreeTableDataNode<T>;
}
export enum RowSelectedTarget {
  NewTab = 'newTab',
  NewWindow = 'newWindow',
}

export interface RowSelectionEvent<T> {
  data: T;
  target?: RowSelectedTarget;
}
