@if (data$ | async; as data) {
  @if (!data.isLoading) {
    @if (isEditing) {
      <lib-popup-menu
        class="popup-menu"
        [icon]="'bars'"
        [menuItems]="[
          { id: eAction.remove, icon: 'trash', label: 'Remove' | translate },
          { id: eAction.edit, icon: 'pencil', label: 'Edit' | translate },
        ]"
        (action)="onMenuClick($event.id)"
      ></lib-popup-menu>
    }

    @if (!data.chart.series?.length) {
      <ng-container *ngTemplateOutlet="custom; context: { $implicit: 'No data' | translate }"> </ng-container>
    } @else if ([eDashboardWidgetType.DATA_POINT_WIDGET].includes(dashboardWidget.widget_type)) {
      <ng-container *ngTemplateOutlet="custom; context: { $implicit: data.chart.series?.[0]?.data?.[0] }">
      </ng-container>
    } @else {
      <lib-chart
        [chartOptions]="
          data.chart
            | objectAssign: { colors: eDEFAULT_COLOURS, navigation: { buttonOptions: { enabled: !isEditing } } }
        "
      ></lib-chart>
    }
  } @else {
    <lib-spinner></lib-spinner>
  }

  <ng-template #custom let-value>
    <div class="custom-chart p-1">
      <div class="custom-chart-title" [innerHTML]="data.chart.title?.text"></div>
      <div class="custom-chart-subtitle mt-1" [innerHTML]="data.chart.subtitle?.text"></div>
      <p class="custom-chart-value mt-5">{{ value }}</p>
    </div>
  </ng-template>
}
