import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardUpsertForm } from './dashboard-upsert.form';
import { Dashboard, DashboardUpsertRequest, DialogResult, Status } from '../../../models';
import { DashboardsApiService } from '../../../services/api-services';
import { provideComponentStore } from '@ngrx/component-store';
import { DashboardUpsertDialogStore } from './dashboard-upsert-dialog.store';
import { map, Observable } from 'rxjs';

export interface DashboardUpsertDialogData {
  cta: string;
  copy?: boolean;
  dashboard?: Dashboard;
}

export interface DashboardUpsertDialogResults {
  request: DashboardUpsertRequest;
}

type Data = {
  dashboards: Dashboard[];
};

@Component({
  selector: 'lib-dashboard-upsert-dialog',
  templateUrl: './dashboard-upsert-dialog.component.html',
  styleUrls: ['./dashboard-upsert-dialog.component.scss'],
  providers: [provideComponentStore(DashboardUpsertDialogStore)],
})
export class DashboardUpsertDialogComponent implements OnInit {
  public data$?: Observable<Data>;
  public form?: DashboardUpsertForm;

  constructor(
    private readonly dashboardsApiService: DashboardsApiService,
    private readonly dashboardUpsertDialogStore: DashboardUpsertDialogStore,
    private readonly dialogRef: MatDialogRef<
      DashboardUpsertDialogComponent,
      DialogResult<DashboardUpsertDialogResults>
    >,
    @Inject(MAT_DIALOG_DATA) public data: DashboardUpsertDialogData,
  ) {}

  public ngOnInit(): void {
    this.data$ = this.dashboardUpsertDialogStore.dashboards$.pipe(map((dashboards) => ({ dashboards })));
    this.form = new DashboardUpsertForm(this.dashboardsApiService, this.data.dashboard, this.data.copy);

    if (this.data.copy) {
      this.form.markAsDirty();
    }
  }

  public save(): void {
    if (!this.form) {
      return;
    }

    this.dialogRef.close({ status: Status.SUCCESS, data: { request: this.form.toModel() } });
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
