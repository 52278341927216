<p-toast [position]="eToastPosition.TOP_CENTER" [key]="eToastPosition.TOP_CENTER" data-testid="toast-top-center">
  <ng-template let-message pTemplate="message">
    <ng-container [ngTemplateOutlet]="toastMessage" [ngTemplateOutletContext]="{ $implicit: message }"> </ng-container>
  </ng-template>
</p-toast>

<p-toast [position]="eToastPosition.TOP_RIGHT" [key]="eToastPosition.TOP_RIGHT" data-testid="toast-top-right">
  <ng-template let-message pTemplate="message">
    <ng-container [ngTemplateOutlet]="toastMessage" [ngTemplateOutletContext]="{ $implicit: message }"> </ng-container>
  </ng-template>
</p-toast>

<ng-template #toastMessage let-message>
  <i class="pi p-toast-message-icon" [ngClass]="message.severity | toastIcon"></i>

  <div class="p-toast-message-text toast-message">
    @if (message.summary) {
      <div class="p-toast-summary">
        {{ message.summary | titlecase }}
      </div>
    }

    @if (message.detail) {
      <div class="p-toast-detail">
        {{ message.detail }}
      </div>
    }

    @if (message.data?.actionLabel) {
      @if (message.data.routerLink) {
        <lib-link
          class="toast-link"
          [text]="message.data.actionLabel"
          [routerLink]="message.data.routerLink"
        ></lib-link>
      } @else {
        <button class="toast-link link toast-button" (click)="clickToast(message)">
          {{ message.data.actionLabel }}
        </button>
      }
    }
  </div>
</ng-template>
